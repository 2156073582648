import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import './AcceptTerms.css';
import queryString from 'query-string';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { apiGet, apiPut } from '../../services/apiService';

class AcceptTerms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            approveTask: [],
            userName: null,
            userEmail: null,
            acceptance: null,
            sysId: null,
            btnDisable: false,
            modalState: null,
            isOpen: false,
            requestType: null
        };
    }

    componentDidMount() {
        this.getTask();
    }

    getTask = async () => {
        const query = queryString.parse(this.props.location.search)
        let url = config.azRedirectUri + '/api/TeamsAcceptance?taskid=' + query.taskid;
        await apiGet(url)
            .then(response => {
                let currentDate = new Date();
                let approveTask = response.data.user;
                let userName = approveTask.name;
                let userEmail = approveTask.email;
                let expireDate = new Date(approveTask.expireDate);
                let sysId = query.taskid;
                let requestType = response.data.requestType;
                this.setState({ approveTask, userName, userEmail, sysId, requestType });

                if (expireDate > currentDate) {
                    if (approveTask.acceptAgreement > 0) {
                        this.setState({ btnDisable: true, isOpen: true, modalState: 'Completed' });
                    }
                } else {
                    this.setState({ modalState: 'Expired', isOpen: true });
                }
            })
            .catch((error) => {
                console.log(typeof error, JSON.stringify(error).includes('400'))
                if (JSON.stringify(error).includes('400')) {
                    <Redirect to='/error' />;
                }
                this.setState({ modalState: 'Error', isOpen: true });
            })
    }

    handleInput = async (e) => {
        this.setState({ btnDisable: true });
        const url = config.azRedirectUri + '/api/TeamsAcceptance';
        let task = {
            'email': this.state.userEmail,
            'SysId': this.state.sysId,
            'acceptagreement': e.target.value === 'Accept' ? 1 : 0
        };
        await apiPut(url, task)
            .then((response) => {
                this.setState({ modalState: task.acceptagreement === 1 ? 'Accept' : 'Declined', isOpen: true });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ modalState: 'Error', isOpen: true });
            })
    }

    renderModal() {
        let modalHeader = null;
        let modalBody = null;
        let body2 = null;
        switch (this.state.modalState) {
            case 'Accept':
                modalHeader = 'TEAMS Terms of Use Completed';
                modalBody = 'You have accepted the terms of use agreement.  By accepting the agreement you will now be added as a member of the Protiviti TEAMS site.';
                body2 = 'Access is not immediate.  It will take a minimum of 5 minutes and up to 24 hours for your access to be activated.  Although access usually takes less than one hour.';
                break;
            case 'Expired':
                modalHeader = 'Team Access Expired';
                modalBody = 'The time limit to accept terms to TEAMS has expired.  If you still require access to TEAMS, please have the requestor submit a new request.';
                break;
            case 'Declined':
                modalHeader = 'TEAMS Terms of Use Completed';
                modalBody = 'You have rejected the terms of use agreement.  As a result you will NOT be added as a member of the Protiviti TEAMS site.  If this was done in error contact a member of your Protiviti engagement team.';
                break;
            case 'Completed':
                modalHeader = 'Team Access Completed';
                modalBody = 'You have already responded to this request.';
                break;
            case 'Error':
                modalHeader = 'Team Access Error';
                modalBody = 'There was an error processing your request.  Please try again.  If the problem persists, please contact iShare Support.';
                break;
        }
        return (
            <MDBContainer>
                {this.state.isOpen ? <div className='modalBackdrop'></div> : null}
                <MDBModal isOpen={this.state.isOpen} backdrop={false}>
                    <MDBModalHeader>{modalHeader}</MDBModalHeader>
                    <MDBModalBody>
                        {modalBody}
                        <br /><br />
                        {body2}
                        <div className="padding-top-20">
                            Press OK to close this window and go to the Guest Resources page.
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/GuestResource'><button className='btn btn-primary updateButton'>Ok</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }

    render() {
        return (
            <div className='div-wrapper'>
                {this.state.requestType === "MF" &&
                    <div>
                        <p>Please acknowledge that you understand that access to this TEAMS site may allow you access to business proprietary, confidential and/or competition sensitive information of Protiviti and its clients, contractors and/or vendors. You agree to keep all information confidential and protect it from unauthorized disclosure. You agree to comply with the confidentiality obligations contained in your Member Firm agreement with Protiviti, any subcontract or statement of work applicable to your access to this TEAMS site and/or any confidentiality requirements of the applicable client. You agree to maintain and use information solely within the TEAMS site and that you will not print, download, copy or duplicate any information outside of this TEAMS site without appropriate approval from the site owner.</p>
                        <p>If you agree, please click the Accept button below.  If you do not agree, please click the Decline button below.</p>
                    </div>
                }

                {this.state.requestType === "Client" &&
                    <div>
                        <p>Please acknowledge that you understand that access to this TEAMS site may allow you access to business proprietary, confidential and/or competition sensitive information. Information contained in this TEAMS site is intended to be solely used and maintained within the TEAMS site.  You acknowledge that information contained in this TEAMS site may be in draft form, incomplete or subject to modification or revision at any time. Protiviti does not warrant the accuracy or completeness of any information contained herein and all deliverables or work product are subject to the terms and conditions of the services agreement entered into by you and Protiviti.</p>
                        <p>To access and use the TEAMS site, you will be required to provide us with your personal information to create and maintain your user account. You hereby warrant that the information that you provide to us is accurate, truthful, and complete. You also hereby warrant that, at all times that you access and use the TEAMS site, you are authorized to use the TEAMS site by the Protiviti client whose information you have access to, and that if that authorization ends, you will immediately stop using the Team site and inform Protiviti to terminate your access.  You are responsible for maintaining the confidentiality of your password and log-in credentials and are solely and fully responsible for all activities that occur under your user account and/or password. If you know or suspect that someone else may be using your password or account, or you suspect any other breach of security of violation of use, you agree to immediately notify Protiviti. By providing your name and email, you hereby affirmatively consent to Protiviti’s use of the same to contact you regarding your account and use of the TEAMS site.  Any information that we collect from you is subject to our <a href="https://www.protiviti.com/US-en/privacy-policy" target="_blank">Privacy Policy</a>, which is incorporated herein by reference.</p>
                        <p>I acknowledge and agree to the Terms of Use and consent to the processing of my personal information for the purposes described above.</p>
                    </div>
                }

                {this.state.requestType &&
                    <form>
                        <span className='padding-left-20'><button disabled={this.state.btnDisable} type="button" onClick={e => this.handleInput(e, "value")} value="Accept" className="btn btn-primary AcceptButton">Accept</button></span>
                        <span className='padding-left-20'><button disabled={this.state.btnDisable} type="button" onClick={e => this.handleInput(e, "value")} value="Reject" className="btn btn-danger AcceptButton">Reject</button></span>
                    </form>
                }

                <br />
                <p>If you have any questions, please reach out to <a href='mailto:iSharesupport@protiviti.com'>Technical Support</a>.</p>
                {this.renderModal()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default withRouter(connect(mapStateToProps, {})(AcceptTerms));