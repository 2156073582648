const hostname = window && window.location && window.location.hostname;

var backendHost = `https://${hostname}`;
if (hostname === 'localhost') {
    backendHost = 'http://localhost:64186';
}

const config = {
    azRedirectUri: backendHost,
    iShareMis: 'https://ishare.protiviti.com',
    configure: 'https://misap.protiviti.com/configure',
    myLearning: 'https://roberthalf.csod.com/samldefault.aspx?ouid=3&returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253d389',
    legalApprover: [
        {
            'email': 'yunhoo01@roberthalf.com',
            'key': '',
            'region' : '',
            'name': 'Yun Ho',
            'loginAddress': 'yunhoo01@roberthalf.com',
        },
        {
            'email': 'mikdaw01@roberthalf.com',
            'key': '',
            'region': '',
            'name': 'Mike Dawson',
            'loginAddress': 'mikdaw01@roberthalf.com',
        },
        {
            'email': 'ashhar01@protiviti.com',
            'key': '',
            'region': '',
            'name': 'Ashley Hartley',
            'loginAddress': 'ashhar01@protiviti.com',
        },
        {
            'email': 'athdel01@roberthalf.com',
            'key': '',
            'region': '',
            'name': 'Athena Dela Rosa',
            'loginAddress': 'athdel01@roberthalf.com',
        },
        {
            'email': 'wiltho04@roberthalf.com',
            'key': '',
            'region': '',
            'name': 'William Thompson',
            'loginAddress': 'wiltho04@roberthalf.com',
        },
        {
            'email': 'kanvij01@protiviti.com',
            'key': '',
            'region': '',
            'name': 'Kanika Vij',
            'loginAddress': 'kanvij01@protiviti.com',
        }
        ],
    teamsExpireDaysLength: 183,
    contractorRegions: ["Asia-Pacific", "Europe", "North America"],
}

module.exports = config;