import React, { Component } from 'react';
import './AssignTeamsHelp.css';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUser, fetchCompany } from '../../actions';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import config from '../../config';
import { getUsername } from '../../services/authService';
import { apiGet, apiPost } from '../../services/apiService';

class HelpPage extends Component {

    constructor(props) {
        const counter = 0
        super(props)
        this.state = {
            firstName: null,
            lastName: null,
            regionSelected: null,
            officeSelected: null,
            offices: [],
            allOffices: [],
            regions: [],
            localAdministrators: [],
            modal: false,
            errorMessage: null,
            rowError: new Array(10).fill(false),
            firstNames: [],
            lastNames: [],
            emails: [],
            guestUsers: [
                {
                    email: null,
                    firstName: null,
                    lastName: null
                }
            ],
            counter: counter,
            addAnother: [
                {
                    id: counter
                }
            ],
            limitModal: true,
            isOpen: false,
            sendResponse: null,
            sentModal: false,
            buttonDisabled: false
        }
    }

    async componentDidMount() {
        let currentUser = await getUsername();
        this.props.getCurrentUser(currentUser.username);
        this.fetchData();
    }

    fetchData = async () => {
        await this.fetchRegion();
        await this.fetchOffices();
    }

    fetchRegion = () => {
        const url = '/api/Country';
        apiGet(url)
            .then((response) => {
                this.setState({
                    regions: response.data.map(x => x.region).filter((value, index, self) => {
                        return self.indexOf(value) === index && !config.contractorRegions.includes(value);
                    })
                })
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    fetchOffices = () => {
        const url = config.azRedirectUri + '/api/ProOffice/GetAll';
        apiGet(url)
            .then((response) => {
                const data = response.data.filter(office => { return office.isDeleted === false });
                console.log('office ' + JSON.stringify(data))
                this.setState({ allOffices: data })
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    fetchEmailWhitelist = async () => {
        let url = '/api/EMailWhitelist';
        return await apiGet(url)
            .then((response) => {
                let whiteList = response.data;
                let emails = this.assembleEmails(response.data);
                this.setState({ emailWhitelist: emails }, () => this.assembleImportData());
            })
            .catch((error) => {
                console.error(error);
            })
    }

    fetchLocalAdministrators = (key) => {
        const url = config.azRedirectUri + '/api/Delegates/' + key;
        apiGet(url)
            .then((response) => {
                this.setState({ localAdministrators: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    findDuplicates = () => {
        let results = [];
        this.setState({ buttonDisabled: true });
        for (let i = 0; i < this.state.emails.length; i++) {
            for (let j = 0; j < this.state.emails.length; j++) {
                if (j === i)
                    continue;
                else {
                    if (this.state.emails[i] === this.state.emails[j])
                        results.push(i)
                }
            }
        }
        if (results.length === 0) {
            this.sendMail()
        }
        else {
            this.setState({ buttonDisabled: false });
            let rowErr = this.state.rowError;
            for (let i = 0; i < results.length; i++) {

                rowErr[results[i]] = true;
                this.setState({ rowError: rowErr, errorMessage: 'Duplicate emails must either be changed or deleted' });
                setTimeout(() => {
                    this.setState({ errorMessage: null, rowError: new Array(10).fill(false) });
                }, 5000);
            }
        }
    }

    sendMail = async () => {
        if (this.state.rowError.every(row => row === false)) {
            let firstName = this.props.userReducer.currentUser.firstName || this.state.firstName
            let lastName = this.props.userReducer.currentUser.lastName || this.state.lastName
            let guests = []
            let rowErrors = 0
            //console.log('first: ' + firstName + ' last: ' + lastName + ' firstName Length: ' + this.state.firstNames.length + ' lastName length: ' + this.state.lastNames.length + ' guestUsers length: ' + this.state.firstNames.length + ' errorMessage: ' + this.state.errorMessage);
            if (firstName && lastName
                && (this.state.emails.length > 0 || (!this.state.firstNames.length > 0 && this.state.lastNames.length > 0))
                && this.state.addAnother.length <= this.state.guestUsers.length
                && this.state.officeSelected
                && this.state.regionSelected) {
                this.setState({ rowError: new Array(10).fill(false) });
                for (let i = 0; i < this.state.guestUsers.length; i++) {
                    if (typeof (this.state.emails[i]) !== 'undefined' || ((typeof(this.state.firstNames[i]) !== 'undefined' && this.state.firstNames[i] !== "") && (typeof (this.state.lastNames[i]) !== 'undefined' && this.state.lastNames[i] !== ""))) {
                        let guest = {
                            email: this.state.emails[i],
                            firstName: this.state.firstNames[i],
                            lastName: this.state.lastNames[i]
                        }
                        guests.push(guest)
                    } else {
                        rowErrors++;
                        let rowErr = this.state.rowError;
                        let guest = {
                            email: this.state.emails[i],
                            firstName: this.state.firstNames[i],
                            lastName: this.state.lastNames[i]
                        }
                        guests.push(guest)
                        rowErr[i] = true;
                        this.setState({ rowError: rowErr, errorMessage: 'Email OR First and Last Name must be completed or row must be deleted.' });
                        setTimeout(() => {
                            this.setState({ errorMessage: null, rowError: new Array(10).fill(false) });
                        }, 5000);
                    }
                }
                this.setState({ guestUsers: guests });
                if (rowErrors === 0 && guests.length > 0) {
                    let account = {
                        firstName: this.props.userReducer.currentUser.firstName ? this.props.userReducer.currentUser.firstName : this.state.firstName,
                        lastName: this.props.userReducer.currentUser.lastName ? this.props.userReducer.currentUser.lastName : this.state.lastName,
                        region: this.state.regionSelected,
                        office: this.state.officeSelected.name,
                        guests: guests,
                        administrators: this.state.localAdministrators.map((x) => { return x.emailAddress })
                    }
                    const url = config.azRedirectUri + '/api/GuestHelp';
                    await apiPost(url, account)
                        .then((response) => {
                            if (response.data) {
                                this.toggleSentModal()
                            }
                        })
                }
            }
            else if (this.state.addAnother.length > 1 || (this.state.emails.length === 0 || (this.state.firstNames.length === 0 || this.state.lastNames.length > 0))) {
                for (let i = this.state.addAnother.length; i > this.state.emails.length - 1; i--) {
                    rowErrors++
                    let rowErr = this.state.rowError;
                    rowErr[i] = true;
                    this.setState({ rowError: rowErr, errorMessage: 'Email OR First and Last Name must be completed.' });
                    setTimeout(() => {
                        this.setState({ errorMessage: null, rowError: new Array(10).fill(false) });
                    }, 5000);
                }
            }
            else {
                this.setState({ errorMessage: '* Marked fields are required.' })
                setTimeout(() => {
                    this.setState({ errorMessage: null });
                }, 5000);
            }
        }
        this.setState({ buttonDisabled: false });
    }
    incrementAnother = () => {
        let nextId = this.state.counter + 1

        let newAdd = [...this.state.addAnother, { id: nextId }]
        let newUser = [...this.state.guestUsers, { email: null, firstName: null, lastName: null }]
        this.setState({ addAnother: newAdd, guestUsers: newUser, counter: nextId })
    }
    handleDelete = (i, index) => {
        let requestEmails = this.state.emails
        let requestFirstNames = this.state.firstNames
        let requestLastNames = this.state.lastNames
        let requestGuestUsers = this.state.guestUsers
        if (this.state.addAnother.length > 1) {

            let newAdd = this.state.addAnother.filter(x => {
                return x.id !== i
            })
            requestEmails.splice(index, 1)
            requestFirstNames.splice(index, 1)
            requestLastNames.splice(index, 1)
            requestGuestUsers.splice(index, 1)
            this.setState({
                emails: requestEmails,
                firstNames: requestFirstNames,
                lastNames: requestLastNames,
                guestUsers: requestGuestUsers,
                addAnother: newAdd
            })
        }
        else {
            requestEmails[index] = ''
            requestFirstNames[index] = ''
            requestLastNames[index] = ''
            this.setState({
                emails: requestEmails,
                firstNames: requestFirstNames,
                lastNames: requestLastNames
            })
        }
    }
    onRegionChange = (selectedOption) => {
        let allOffice = this.state.allOffices;
        // console.log('All offices: ' + JSON.stringify(allOffice))
        allOffice = allOffice.filter(x => { return x.region === selectedOption });
        this.setState({ regionSelected: selectedOption, officeSelected: null, localAdministrators: null, offices: allOffice.filter(x => { return x.region === selectedOption }) });

    }
    onOfficeChange = async (selectedOption) => {
        await this.setState({ officeSelected: selectedOption })
        this.fetchLocalAdministrators(selectedOption.key)
    }
    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    toggleLimitModal = () => {
        this.setState({
            limitModal: !this.state.limitModal
        })
    }
    toggleOpen = () => { this.setState({ isOpen: !this.state.isOpen }) };
    toggleSentModal = () => { this.setState({ sentModal: !this.state.sentModal }) }

    renderCancelModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Cancel</MDBModalHeader>
                    <MDBModalBody>
                        {this.cancelModal()}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' onClick={this.onCancel}>Yes</button>
                        <button className='btn btn-primary updateButton' onClick={this.toggleModal}>No</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    renderSentModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.sentModal} toggle={this.toggleSentModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Email Sent</MDBModalHeader>
                    <MDBModalBody>
                        Your request for Guest Access Accounts has been Sent Successfully!
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' type="button" onClick={this.onDone}>Done</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )

    }

    onDone() {
        window.location.reload(true);
    }
    onCancel() {
        window.close()
    }
    onFirstNameChange = (event) => {
        this.setState({ firstName: event.target.value })

    }
    onLastNameChange = (event) => {
        this.setState({ lastName: event.target.value })

    }
    onFirstNamesChange = (event, index) => {
        let newFirstName = this.state.firstNames;
        let newGuest = this.state.guestUsers;
        newFirstName[index] = event.target.value;
        newGuest[index].firstName = event.target.value;
        this.setState({ firstNames: newFirstName, guestUsers: newGuest });
    }
    onLastNamesChange = (event, index) => {
        let newLastName = this.state.lastNames;
        let newGuest = this.state.guestUsers;
        newLastName[index] = event.target.value;
        newGuest[index].lastName = event.target.value;
        this.setState({ lastNames: newLastName, guestUsers: newGuest });
    }
    onEmailChange = (event, index) => {
        let newEmail = this.state.emails;
        let newGuest = this.state.guestUsers;
        newEmail[index] = event.target.value;
        newGuest[index].email = event.target.value;
        let rowErr = this.state.rowError;
        rowErr[index] = !this.validateEmail(event.target.value);
        this.setState({ email: newEmail, guestUsers: newGuest, rowError: rowErr });
        if (rowErr.every(x => x === false)) {
            this.setState({ errorMessage: null });
        }
        else {
            this.setState({ errorMessage: 'Please Enter a Valid Email Address.' });
        }
    }

    validateEmail(email) {
        if (email === '') {
            return true;
        }
        let pattern = /^[\._A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase());
    }

    //isEmailwhitelist = async(guests) => {
    //    let emailWhitelist = await this.fetchEmailWhitelist();;
    //    let rowErr = this.state.rowError;
    //    console.log('Whitelist ' + JSON.stringify(emailWhitelist))
    //    for (let i = 0; i < emailWhitelist.length; i++) {
    //        emailWhitelist.push(this.props.sharedReducer.emailWhitelist[i].name);
    //    }
    //    console.log('guests ' + JSON.stringify(guests))
    //    for (let j = 0; j < guests.length; j++) {
    //        if (guests[j].email) {
    //            let accountEmail = guests[j].email.substring(guests[j].email.indexOf('@') + 1, guests[j].email.length);
    //            if (!emailWhitelist.includes(accountEmail)) {
    //                rowErr[j] = true;
    //                this.setState({ errorMessage: 'Email Domain must be in Email Whitelist.', rowError: rowErr });
    //            }
    //        }
    //    }

    //    if (!rowErr.every(x => x === false)) {
    //        setTimeout(() => {
    //            this.setState({ errorMessage: null, rowError: new Array(10).fill(false) });
    //        }, 5000);
    //        return false;
    //    }
    //    else {
    //        return true;
    //    }
    //}

    cancelModal() {
        return (

            <div>
                Are you sure you want to Cancel and lose the changes made?
            </div>

        );
    }
    maxLimitModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.limitModal} toggle={this.toggleLimitModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Limit Exceeded</MDBModalHeader>
                    <MDBModalBody>
                        You've reached a maximum of 10 users to be added.
                    </MDBModalBody>
                    <MDBModalFooter>

                        <button className='btn btn-primary updateButton' type='button' onClick={this.toggleLimitModal}>Okay</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }

    renderAnother = () => {
        return (
            this.state.addAnother.map((x, index) =>
                <div className='form-group col-md-12' key={x.id}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='rightText'><label className='teamsLabel'>Email</label></div>
                            <input
                                defaultValue={this.state.emails[index]}
                                type='text'
                                className={this.state.rowError[index] ? `form-control addUserInput0` : 'form-control'}
                                onChange={(event) => this.onEmailChange(event, index)}
                            />
                            {this.state.rowError[index] && (this.state.errorMessage === 'Please Enter a Valid Email Address.' || this.state.errorMessage === 'Highlighted rows must be completed or deleted.' || this.state.errorMessage === 'Duplicate emails must either be changed or deleted' || this.state.errorMessage === 'Email Domain must be in Email Whitelist.' || this.state.errorMessage === 'Email OR First and Last Name must be completed.' || this.state.errorMessage === 'Email OR First and Last Name must be completed or row must be deleted.') ? <div className='warningHelpText'>{this.state.errorMessage}</div> : null}

                        </div>
                        <div className='col-md-3'>
                            <div className='rightText'><label className='teamsLabel'>First Name</label></div>
                            <input
                                defaultValue={this.state.firstNames[index]}
                                type='text'
                                className={this.state.rowError[index] ? `form-control addUserInput0` : 'form-control'}
                                onChange={(event) => this.onFirstNamesChange(event, index)}
                            />
                        </div>
                        <div className='col-md-3'>
                            <div className='rightText'><label className='teamsLabel'>Last Name</label></div>
                            <input
                                defaultValue={this.state.lastNames[index]}
                                type='text'
                                className={this.state.rowError[index] ? `form-control addUserInput0` : 'form-control'}
                                onChange={(event) => this.onLastNamesChange(event, index)}
                            />
                        </div>
                        <div className='col-md-2 rowActions'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <button type='button' className={this.state.addAnother.length > 9 ? 'addRowIconDisabled' : 'addRowIcon'} onClick={this.incrementAnother} disabled={this.state.addAnother.length > 9}>   <FontAwesomeIcon icon={faPlus} size='lg' /></button>
                                </div>
                                <div className='col-md-3'>
                                    <FontAwesomeIcon icon={faTrashAlt} size='lg' onClick={(event) => this.handleDelete(x.id, index)} />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            )
        )
    }

    render() {
        //const email = this.props.userReducer.currentUser.emailAddress
        //const emailSuffix = email ? email.substring(email.lastIndexOf('@') + 1) : null
        //const checkDrop = emailSuffix === 'protiviti.com' || emailSuffix === 'roberthalf.com' ? true : false
        const another = this.renderAnother()
        const limit = this.state.addAnother.length > 9 ? this.maxLimitModal() : null
        const emails = this.state.guestUsers ? this.state.guestUsers.map((x, index) => {
            if (x !== '')
                return (<li key={index}> {x.firstName} {x.lastName} {x.email}</li>)
        }) : null
        //uncomment once complete
        //if (!checkDrop) {
        //    return <Redirect to='/' />
        //}
        return (

            <div className="teamsContainer">
                <div className="introductoryTextHeader">
                    Help, I Can't Find a Guest Account!
                </div>
                <div className="introductoryText">
                    <br />
                    There are few reasons why you may not be able to find a guest to add to your Microsoft Teams Site Request.
                    <div className="reasons">
                        <br />
                        1) You can only request to add active Member Firm employees of certain firms. Some firms have now met specific security and compliance criteria. Others are working on it and cannot be added yet. Employees of Protiviti clients may not be added at this time.<br />
                        2) If the person is a new Member Firm employee, it's possible that their Protiviti guest account has not yet been created. You can try again soon.<br />
                        3) The guest account may have expired. Guest accounts expire every 90 days and must be renewed by the local administrators. It’s possible that the account lapsed or was disabled because the person left. In this case the guest account may need to be renewed for another 90 days by the local administrator at the Member Firm. (See below for instructions.)<br />
                        4) Are you sure about the name and spelling? It is possible that the guest account was set up under a different name than the one you are familiar with.
                    </div>
                    <br />
                    To check the details or status of a member firm employee, you can send a request to the local assigned administrator. Simply provide the information requested in the form below and click the submit button. An email will be sent to the appropriate administrators who can check on the status of the people in question and ensure that they have active guest accounts. When the guest account has been activated you will be able to return to the TEAMS access request form and submit the request to add them to your Microsoft Teams site.

                </div>
                <div >
                    <hr />
                </div>
                {this.renderCancelModal()}
                {this.renderSentModal()}
                <form>
                    <div className="formsContainer">
                        <div className='form-group'>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-2 centerText'>
                                            <div style={{ marginTop: '33px' }}>  Your Name<span className="required">*</span></div>

                                        </div>
                                        <div className='col-md-4'>
                                            <div className='rightText'><label className='teamsLabel'>First</label></div>
                                            <input
                                                defaultValue={this.props.userReducer.currentUser.firstName ? this.props.userReducer.currentUser.firstName : this.state.firstName}
                                                type='text'
                                                className={this.state.errorMessage === '* Marked fields are required' && this.state.firstName === null && this.props.userReducer.currentUser.firstName === undefined ? 'warningBorder form-control' : 'form-control'}
                                                disabled={this.props.userReducer.currentUser.firstName}
                                                onChange={this.onFirstNameChange} />
                                            {this.state.errorMessage === '* Marked fields are required' && this.state.firstName === null && this.props.userReducer.currentUser.firstName === undefined ? < div className='warningHelpText'>{this.state.errorMessage}</div> : null}
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='rightText'><label className='teamsLabel'>Last</label></div>
                                            <input
                                                defaultValue={this.props.userReducer.currentUser.lastName ? this.props.userReducer.currentUser.lastName : this.state.lastName}
                                                type='text'
                                                className={this.state.errorMessage === '* Marked fields are required' && this.state.lastName === null && this.props.userReducer.currentUser.lastName === undefined ? 'warningBorder form-control' : 'form-control'}
                                                disabled={this.props.userReducer.currentUser.firstName}
                                                onChange={this.onLastNameChange}
                                            />
                                            {this.state.errorMessage === '* Marked fields are required' && this.state.lastName === null && this.props.userReducer.currentUser.lastName === undefined ? < div className='warningHelpText'>{this.state.errorMessage}</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-8 centerText'>
                                            <h4 style={{ marginTop: '33px', fontWeight: '600' }}> Name or Email Address of Guests</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <span className="required">*</span> Please provide either an email address or a complete first and last name.  Both are preferred, if possible.
                                </div>
                                {another}
                                {limit}
                                <div className='form-group col-md-10'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='rightText'>
                                                <div className='rightText'><label className='teamsLabel'>Region<span className="required">*</span></label></div>
                                            </div>
                                            <Select
                                                value={this.state.regionSelected}
                                                placeholder={this.state.regionSelected}
                                                options={this.state.regions}
                                                getOptionLabel={(option) => option}
                                                getOptionValue={(option) => option}
                                                onChange={this.onRegionChange}
                                                className={this.state.errorMessage !== null && this.state.regionSelected === null ? 'warningBorder' : null}
                                            />
                                            {this.state.errorMessage !== null && this.state.regionSelected === null ? <div className='warningHelpText'>* Marked fields are required</div> : null}
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='rightText'>
                                                <label className='teamsLabel'>Office or Country<span className="required">*</span></label>
                                            </div>
                                            <Select
                                                value={this.state.officeSelected ? this.state.officeSelected.name : null}
                                                placeholder={this.state.officeSelected ? this.state.officeSelected.name : null}
                                                options={this.state.offices}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                onChange={this.onOfficeChange}
                                                className={this.state.errorMessage !== null && this.state.officeSelected === null ? 'warningBorder' : null}
                                            />
                                            {this.state.errorMessage !== null && this.state.officeSelected === null ? <div className='warningHelpText'>* Marked fields are required</div> : null}
                                        </div>

                                        {/*        <div className='col-md-6'>*/}
                                        {/*            <div className='rightText'>*/}
                                        {/*                <div className='rightText'><label className='teamsLabel'>Office or Country<span className="required">*</span></label></div>*/}
                                        {/*            </div>*/}
                                        {/*            <Select*/}
                                        {/*                value={this.state.officeSelected ? this.state.officeSelected.name : null}*/}
                                        {/*                placeholder={this.state.officeSelected ? this.state.officeSelected.name : null}*/}
                                        {/*                options={this.state.offices}*/}
                                        {/*                getOptionLabel={(option) => option.name}*/}
                                        {/*                getOptionValue={(option) => option.name}*/}
                                        {/*                onChange={this.onOfficeChange}*/}
                                        {/*                className={this.state.errorMessage !== null && this.state.officeSelected === null ? 'warningBorder' : null}*/}
                                        {/*            />*/}
                                        {/*            {this.state.errorMessage !== null && this.state.officeSelected === null ? <div className='warningHelpText'>* Marked fields are required</div> : null}*/}

                                        {/*        </div>*/}
                                    </div>
                                </div>

                                <div className='form-group col-md-10'>
                                     <div className='row'>
                                        <div className='col-md-12'>
                                            < div className='rightText'>
                                                <div className='rightText'><label className='teamsLabel'>List of Local Administrators</label></div>
                                            </div>
                                            <Select
                                                value={this.state.localAdministrators}
                                                isMulti
                                                placeholder=''
                                                options={this.state.localAdministrators}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                getOptionLabel={(option) => option.displayName}
                                                getOptionValue={(option) => option.displayName}
                                                onChange={this.onMultiOfficeChange}
                                                isDisabled={true}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="emailOuterContainer">
                                                <div className="emailContainer">
                                                    <div className="introductoryText">
                                                        Hi. This is {this.props.userReducer.currentUser.firstName ? this.props.userReducer.currentUser.firstName : this.state.firstName} {this.props.userReducer.currentUser.lastName ? this.props.userReducer.currentUser.lastName : this.state.lastName} from Protiviti.
                                                        <br /><br />
                                                        I have a question for you about your office's iShare accounts.  I would like to submit a request for the user below to be admitted to a Robert Half Microsoft Teams site for collaboration or training.  However, either the account does not exist or the account is not active.<br /><br />
                                                        <div>
                                                            <ol>
                                                                {emails}
                                                            </ol>
                                                        </div>
                                                        I would kindly ask that you check on this account.  If this is still an active employee, please create the account if it does not exist or activate the account if it is expired, using the Self Service Account Portal.  Could you please let me know when you have activated the account?  Or if the person is no longer employed by your firm or working for Protiviti as a contractor, please let me know as well.
                                                        <br /><br />
                                                        Regards,<br />
                                                        {this.props.userReducer.currentUser.firstName ? this.props.userReducer.currentUser.firstName : this.state.firstName}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group col-md-12'>
                                    <div className='row  emailButtons'>
                                        <div className='col-md-6'>
                                            <button type="button" className="btn btn-secondary my-1" type="button" disabled={this.state.buttonDisabled} onClick={this.toggleModal}>Cancel</button>

                                        </div>
                                        <div className='col-md-6'>
                                            <button className="btn btn-primary my-1" type="button" disabled={this.state.buttonDisabled} onClick={this.findDuplicates}>Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}
export default connect(mapStateToProps, { getCurrentUser, fetchCompany })(HelpPage)