import axios from "axios";
import config from "../config";
import { getCookie } from "./cookieService";
import { getToken } from './authService';

export async function apiGet(url, headers, options) {
    [url, headers] = await prepareApiCall(url, headers);
    return axios.get(url, { headers, ...options }).catch((error) => {
        catchApiCall(error);
    });
}

export async function apiPut(url, data, headers) {
    [url, headers] = await prepareApiCall(url, headers);
    return axios.put(url, data, { headers }).catch((error) => {
        catchApiCall(error);
    });
}

export async function apiPost(url, data, headers) {
    [url, headers] = await prepareApiCall(url, headers);
    return axios.post(url, data, { headers }).catch((error) => {
        catchApiCall(error);
    });
}

export async function apiDelete(url, headers) {
    [url, headers] = await prepareApiCall(url, headers);
    return axios.delete(url, { headers }).catch((error) => {
        catchApiCall(error);
    });
}

async function prepareApiCall(url, headers) {
    if (url.startsWith("/")) {
        url = config.azRedirectUri + url;
    }
    let token = await getToken();
    let newHeaders = {
        Authorization: `Bearer ${token}`,
        "X-XSRF-TOKEN": getCookie("X-REQUEST-TOKEN"),
    };
    if (headers) {
        Object.assign(newHeaders, headers);
    } else {
        Object.assign(newHeaders, { "Content-Type": "application/json" });
    }
    return [url, newHeaders];
}

function catchApiCall(error) {
    // log it
    console.error(error);
    // let the caller handle it
    throw error;
}
