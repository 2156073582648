import React, { Component } from 'react';
import config from '../../config';
import './AssignTeams.css';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { connect } from 'react-redux';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getCurrentUser, fetchCompany } from '../../actions';
import { graphFetch } from '../../services/graphApiService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiGet, apiPost } from '../../services/apiService';

class AssignTeams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allAccounts: [],
            accountNames: [],
            checked: false,
            accept: false,
            submit: false,
            backbtn: false,
            selectedTeam: null,
            selectedTeamUrl: '',
            guestLimit: 24,
            counterLimit: 22,
            addAnother: 0,
            guestNames: [],
            guestEmails: [],
            guestOffices: [],
            guestOfficeKeys: [],
            manDirector: null,
            manDirectorName: null,
            bJustification: '',
            selectedDate: null,
            guestAccounts: [],
            guestRequest: [],
            modal: false,
            limitModal: true,
            rowError: new Array(50).fill(false),
            errorMessage: null,
            adResponse: '',
            successModal: false,
            confirmationModal: false,
            mdErrorModal: false,
            teams: [],
            managingDirectors: [],
            allManagingDirectors: [],
            emd: [],
            companies: [],
            compliantCompanies: [],
            selectedCompany: null,
            disableMD: false,
            allCompanyCheckbox: false,
            legalApprovers: [],
            selectedLegalApprover: null,
            learningApprovers: [],
            selectedLerningApprover: null,
            requestType: '0',
            showMaxDate: true,
            // existing data
            existingRequestKey: null,
            existingGuestAccounts: [],
            existingCompanies: null,
            existingMD: null,
            existingMDName: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.incrementAnother = this.incrementAnother.bind(this);
    }

    async componentDidMount() {
        if (this.state.companies.length === 0 && this.props.sharedReducer.companyOptions.length > 0) {
            this.fetchCompanies(this.allCompanyCheckbox)
        }
        await this.fetchData();
        this.getRegionalAttronies();
        this.getLandDApprovers();
        if (this.props.location.state) {
            this.setState({ existingRequestKey: this.props.location.state.key }, () => this.getExistingRequest());
        }
    }
    componentDidUpdate() {
        if (this.state.companies.length === 0 && this.props.sharedReducer.companyOptions.length > 0) {
            this.fetchCompanies(this.allCompanyCheckbox)
        }
    }

    getExistingRequest = async () => {
        let url = config.azRedirectUri + '/api/Teams/detail/' + this.state.existingRequestKey;
        await apiGet(url)
            .then(response => {
                let data = response.data;
                if (data.requestType !== null) {
                    this.setState({ requestType: data.requestType.toString() });
                }
                let nameSecCompanies = [{
                    key: data.memberfirmCompanyId,
                    name: 'Protiviti ' + data.memberFirmCompany.name,
                    managingDirectorKey: data.memberFirmCompany.managingDirectorKey,
                    isDeleted: data.memberFirmCompany.isDeleted,
                    complianceLevel: data.memberFirmCompany.complianceLevel

                }];
                this.mapExistingCompany(nameSecCompanies, data.memberFirmCompanyList);
                let guestNames = data.guestAccessUsers.map(x => {
                    return ({
                        memberFirmName: x.memberFirmName,
                        memberFirmEmail: x.memberFirmEmail,
                        office: x.office,
                        memberFirmOfficeId: x.memberFirmOfficeId
                    })
                });
                let teamId = data.teamsSiteUrl;
                teamId = teamId.substring(teamId.indexOf('=') + 1);
                teamId = teamId.substring(0, teamId.indexOf('&'));
                let teamSite = { id: teamId, displayName: data.teamsSiteName }
                this.handleTeamSite(teamSite);
                let legalApprover = { email: data.legalApprover.email, key: data.legalApprover.key, name: data.legalApprover.name, region: data.legalApprover.region };
                this.handleLegalApprover(legalApprover);
                this.setState({
                    addAnother: data.guestAccessUsers.length - 1,
                    bJustification: data.justification,
                    existingGuestAccounts: guestNames,
                    existingMD: data.mdEmail,
                    existingMDName: data.mdName
                });
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.getExistingRequest();
                }
                console.log(error);
            })
    }

    mapExistingCompany = async (e, l) => {
        if (e[0].name === 'Protiviti Multiple') {
            if (!this.state.existingCompanies) {
                //let companyList = [];
                var companies = l.split(", ");
                this.getCompanyByName(companies);
                //console.log('mapExisting ' + JSON.stringify(companyList))
                //this.handleCompanyChange(companyList);
            } else {
                e.map((c) => this.handleCompanyChange(c));
            }
        } else {
            this.handleCompanyChange(e);
        }
    }

    getCompanyByName = (name) => {
        let url = config.azRedirectUri + '/api/Company/getbyname/' + name;
        apiGet(url)
            .then(response => {
                let data = response.data;
                this.handleCompanyChange(data);
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.getCompanyByName(name);
                }
                console.log(error);
            })
    }

    mapExistingGuest = () => {
        let guests = this.state.accountNames.filter(o1 => this.state.existingGuestAccounts.some(o2 => o1.display === o2.memberFirmName));
        if (guests) {
            guests.map((guest, index) => this.handleGuest(guest, index))
        }
    }

    getLandDApprovers = () => {

        let url = config.azRedirectUri + '/api/TeamsLegal/LnDApprovers';
        apiGet(url)
            .then(response => {

                this.setState({ learningApprovers: response.data })
                //console.log(response.data)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    this.getLandDApprovers();
                }
                console.log(error);
            });

    }

    getRegionalAttronies = () => {
        let url = config.azRedirectUri + '/api/TeamsLegal/Approvers';
        apiGet(url)
            .then(response => {

                this.setState({ legalApprovers: response.data })
                //console.log(response.data)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    this.getRegionalAttornies();
                }
                console.log(error);
            });

    }

    getTeams = async () => {
        await graphFetch('https://graph.microsoft.com/v1.0/me/joinedTeams', {})
            .then((response) => response.json())
            .then((responseJson) => {
                let value = responseJson.value;
                if (value !== undefined) {
                    let sortedValue = value.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0));
                    this.setState({ teams: sortedValue });
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    updateTeams = async (teamsId) => {
        this.setState({
            disableMD: true,
            manDirector: null,
            manDirectorName: null,
            existingMD: null,
            existingMDName: null
        });
        await graphFetch('https://graph.microsoft.com/v1.0/teams/' + teamsId, {})
            .then(response => response.json())
            .then((responseJson) => {
                this.setState({
                    selectedTeam: responseJson.displayName,
                    selectedTeamUrl: responseJson.webUrl
                }, async () => {
                    await graphFetch('https://graph.microsoft.com/v1.0/groups/' + teamsId + '/members', {})
                        .then(response => response.json())
                        .then(async (members) => {
                            if (this.state.requestType === '1') {
                                this.mapLearningApprovers();
                            }
                            else {
                                await graphFetch('https://graph.microsoft.com/v1.0/groups/' + teamsId + '/owners', {})
                                    .then((response) => {
                                        response.json()
                                            .then((owners) => {
                                                let fullMD = [...members.value, ...owners.value];
                                                this.mapMds(fullMD, responseJson.internalId);
                                            });
                                    });
                            }
                        });
                });

            })
            .catch((error) => {
                console.error(error);
            })
    }

    getMds = async () => {
        const url = config.azRedirectUri + '/api/Teams/md';
        await apiGet(url)
            .then((response) => {
                this.setState({
                    allManagingDirectors: response.data,
                })
            })
            .catch((error) => {
                console.error(error);
            })
    }


    mapLearningApprovers = () => {
        let ldApprovers = this.state.learningApprovers;
        let loggedInLnD = ldApprovers.find(x => x.loginAddress.toLowerCase() === this.props.userReducer.currentUser.emailAddress.toLowerCase() || this.props.userReducer.currentUser.emailAddress.toLowerCase() === x.email.toLowerCase())
        if (loggedInLnD) {
            this.setState({
                manDirectorName: loggedInLnD.name,
                manDirector: loggedInLnD.loginAddress

            })

        }

        this.setState({
            disableMD: false,
            emd: null
        })
    }


    mapMds = async (teamsMD, internalId) => {
        let managingDirectors = this.state.allManagingDirectors;
        // no longer can get teamthreadid
        //let mappedMD = managingDirectors.filter(o1 => teamsMD.some(o2 => o1.userPrincipalName === o2.userPrincipalName));
        //let md = mappedMD.find(x => x.teamThreadId.find(y => y === internalId));
        let mappedMD = managingDirectors.filter(o1 => teamsMD.some(o2 => o1.userPrincipalName === o2.userPrincipalName));
        let md = null;
        let existingMD = this.state.existingMD;
        let existingMDName = this.state.existingMDName;
        mappedMD.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.setState({
            managingDirectors: mappedMD,
            manDirector: existingMD !== null ? existingMD : null,
            manDirectorName: existingMDName !== null ? existingMDName : null,
            emd: md ? md.name : null,
            disableMD: false
        })
    }

    findDuplicates = () => {
        this.setState({ submit: false, backbtn: true });
        let results = [];
        for (let i = 0; i < this.state.guestNames.length; i++) {
            for (let j = 0; j < this.state.guestNames.length; j++) {
                if (j === i)
                    continue;
                else {
                    if (this.state.guestNames[i] === this.state.guestNames[j])
                        results.push(i)
                }
            }
        }
        if (results.length === 0) {
            this.addAccounts()
        }
        else {
            this.setState({ submit: true, backbtn: false });
            let rowErr = this.state.rowError;
            for (let i = 0; i < results.length; i++) {

                rowErr[results[i]] = true;
                this.setState({ rowError: rowErr, errorMessage: '* Duplicate accounts must either be changed or deleted', errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false), submit: true });
                setTimeout(() => {
                    this.setState({ errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false), submit: true });
                }, 50000);
            }
        }
    }

    fetchData = async () => {
        await this.getTeams()
        this.getMds()
    }
    fetchCompanies = (e) => {
        if (this.props.sharedReducer.companyOptions) {
            let compCompanies = this.props.sharedReducer.companyOptions.filter(x => {
                return x.complianceLevel >= 2 && !x.isDeleted
            });
            let allCompanies = this.props.sharedReducer.companyOptions.filter(x => {
                return x.complianceLevel > 0 && !x.isDeleted
            });
            let secCompanies = e ? allCompanies : compCompanies;
            let nameSecCompanies = secCompanies.map(x => {
                return ({
                    key: x.key,
                    name: 'Protiviti ' + x.name,
                    managingDirectorKey: x.managingDirectorKey,
                    isDeleted: x.isDeleted,
                    complianceLevel: x.complianceLevel

                })
            })
            let nameAllCompanies = compCompanies.map(x => {
                return ({
                    key: x.key,
                    name: 'Protiviti ' + x.name,
                    managingDirectorKey: x.managingDirectorKey,
                    isDeleted: x.isDeleted,
                    complianceLevel: x.complianceLevel

                })
            })
            this.setState({ companies: nameSecCompanies, compliantCompanies: nameAllCompanies })
        }
    }

    fetchDataByUser = async (id) => {
        let url = config.azRedirectUri + '/api/Accounts/company/';
        await apiPost(url, id)
            .then(response => {
                this.setState({ allAccounts: response.data.filter(x => { return x.accountStatus === 0 }) }, () => this.mapAccounttoNames());
            })
            .catch((error) => {
                console.error(error);
            })
    }

    mapAccounttoNames() {
        let names = this.state.allAccounts.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(x => {
            return { value: x.key, display: x.firstName + ' ' + x.lastName }
        });
        if (this.state.guestNames.length > 0) {
            names = names.filter(a => !this.state.guestNames.find(b => b.value === a.value))
        }
        this.setState({
            accountNames: names
        }, () => {
            if (this.state.existingGuestAccounts) {
                this.mapExistingGuest();
            }
        })
    }

    handleChange(e) {
        if (e.target.value === 'check') {
            this.setState(
                prevState => {
                    return {
                        checked: !prevState.checked,
                        submit: !this.state.submit
                    }
                }
            )
        }
        if (e.target.id === 'allCompanyCheckbox') {
            let checked = e.target.checked ? true : false;
            this.setState(
                prevState => {
                    return {
                        allCompanyCheckbox: checked
                    }
                }
            )
            this.setState({ selectedCompany: null, guestNames: [], accountNames: [] });
            this.fetchCompanies(checked);
        }
    }

    handleOptionChange = e => {
        this.setState({ requestType: e.target.value });
        if (e.target.id === 'training' || e.target.id === 'other') {
            let checked = true;
            this.setState(
                prevState => {
                    return {
                        allCompanyCheckbox: checked
                    }
                }
            )
            this.setState({
                selectedCompany: null, guestNames: [], accountNames: [], showMaxDate: false, selectedTeam: null, selectedTeamUrl: '', manDirector: null, manDirectorName: null, disableMD: true, existingMD: null, existingMDName: null
            });
            this.fetchCompanies(checked);
        }
        if (e.target.id === 'client') {
            let checked = false;
            this.setState(
                prevState => {
                    return {
                        allCompanyCheckbox: checked
                    }
                }
            )
            this.setState({ selectedCompany: null, guestNames: [], accountNames: [], showMaxDate: true, selectedTeam: null, selectedTeamUrl: '', manDirector: null, manDirectorName: null, disableMD: true, existingMD: null, existingMDName: null });
            this.fetchCompanies(checked);
        }
    }
    confirmNoClick = () => {
        this.setState({ submit: true, backbtn: false });
        this.toggleConfirmationModal();
    }

    incrementAnother() {
        this.setState(prevState => {
            return {
                addAnother: prevState.addAnother + 1
            }
        });
    }
    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    toggleSuccessModal = () => {
        this.setState({
            successModal: !this.state.successModal
        });
    }
    toggleLimitModal = () => {
        this.setState({
            limitModal: !this.state.limitModal
        });
    }
    toggleConfirmationModal = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal
        });
    }
    toggleMDErrorModal = () => {
        this.setState({
            submit: true,
            mdErrorModal: !this.state.mdErrorModal
        });
    }

    addAccounts = () => {
        let pattern = /^[\._A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.rowError.every(row => row === false)) {
            let accounts = []
            let rowErrors = 0
            let manDir = this.state.manDirector || this.state.emd
            let isEmail = pattern.test(String(manDir).toLowerCase());
            let hasExpiredDate = true;
            if (this.state.showMaxDate) {
                if (this.state.selectedDate == null) {
                    hasExpiredDate = false;
                }
            }
            if (this.state.selectedTeam && this.state.selectedTeamUrl && isEmail && this.state.bJustification && this.state.addAnother < this.state.guestNames.length && (this.state.requestType === '1' || this.state.requestType === '2' || (this.state.requestType === '0' && this.state.selectedLegalApprover !== null)) && hasExpiredDate) {
                this.setState({ rowError: new Array(this.state.guestLimit).fill(false) });
                for (let i = 0; i < this.state.guestNames.length; i++) {
                    if (this.state.guestNames[i] && this.state.guestEmails[i] && this.state.guestOffices[i] && this.state.guestOfficeKeys[i]) {
                        let account = {
                            name: this.state.guestNames[i].display,
                            memberFirmName: this.state.guestNames[i].display,
                            memberFirmEmail: this.state.guestEmails[i],
                            memberFirmOfficeId: this.state.guestOfficeKeys[i],
                        }
                        accounts.push(account)
                    }
                    else {
                        rowErrors++;
                        let rowErr = this.state.rowError;
                        rowErr[i] = true;
                        this.setState({ rowError: rowErr, errorMessage: '* Highlighted rows must be completed or deleted.', errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false), submit: true });
                        setTimeout(() => {
                            this.setState({ errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false), submit: true });
                        }, 50000);

                    }
                }
                let companyId = null;
                let companyList = null;
                let complianceLevel = 0;
                if (this.state.selectedCompany.length > 1) {
                    companyId = '75CCF635-A9B0-4281-A52D-2FF28763639B';
                    companyList = Array.prototype.map.call(this.state.selectedCompany, function (item) { return item.name }).join(", ");
                    //complianceLevel = Math.min(...this.state.selectedCompany.map(c => c.complianceLevel))
                    complianceLevel = 0;
                } else {
                    companyId = this.state.selectedCompany[0].key;
                    companyList = this.state.selectedCompany[0].name;
                    complianceLevel = this.state.selectedCompany[0].complianceLevel
                }
                let guestRequest = {
                    name: 'Request',
                    requestType: this.state.requestType,
                    teamsSiteName: this.state.selectedTeam,
                    teamsSiteUrl: this.state.selectedTeamUrl,
                    justification: this.state.bJustification,
                    memberfirmCompanyId: companyId,
                    securityComplianceLevel: complianceLevel,
                    mdName: this.state.manDirectorName,
                    mdEmail: manDir,
                    guestAccessUsers: accounts,
                    memberfirmCompanyList: companyList,
                    expireDate: this.state.selectedDate,
                    legalApproverKey: (this.state.requestType === '0' && this.state.selectedLegalApprover !== null) ? this.state.selectedLegalApprover.key : null
                }
                if (rowErrors === 0 && accounts.length > 0) {

                    this.setState({ rowError: new Array(this.state.guestLimit).fill(false), guestAccounts: accounts, guestRequest: guestRequest });
                    this.toggleConfirmationModal()
                }
            }
            //else if (this.state.addAnother > this.state.guestNames.length - 1) {
            else if (this.state.guestNames.length > 0 && (this.state.addAnother > this.state.guestNames.length - 1)) {
                for (let i = this.state.addAnother; i > this.state.guestNames.length - 1; i--) {
                    rowErrors++;
                    let rowErr = this.state.rowError;
                    rowErr[i] = true;
                    this.setState({ rowError: rowErr, errorMessage: '* Highlighted rows must be completed or deleted.', submit: true, errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false) });
                    setTimeout(() => {
                        this.setState({ errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false) });
                    }, 50000);
                }
            }
            else {

                //if (this.state.selectedTeam === null || this.state.selectedTeamUrl === '' || this.state.manDirector === null || this.state.emd === null || this.state.bJustification === '' || (this.state.requestType === '0' && this.state.selectedLegalApprover === null)) {
                if (this.state.selectedTeam === null || this.state.selectedTeamUrl === '' || this.state.manDirector === null || this.state.emd === null || this.state.bJustification === '' && !hasExpiredDate) {
                    this.setState({ submit: false, backbtn: false, errorMessage: '* Marked fields are required.' });
                    setTimeout(() => {
                        this.setState({ errorMessage: null });
                    }, 50000);
                }
                if (!this.isEmail) {
                    this.toggleMDErrorModal();
                }
            }
        }
    }
    renderCompanyList() {
        let j = Math.ceil(this.state.compliantCompanies.length / 2)

        const firstHalf = []
        for (let i = 0; i < j; i++) {
            const name = this.state.compliantCompanies[i].name.substr(10)
            firstHalf.push(name)
        }
        const secondHalf = []
        for (let i = j; i < this.state.compliantCompanies.length; i++) {
            const name = this.state.compliantCompanies[i].name.substr(10)
            secondHalf.push(name)

        }
        const firstCol = firstHalf.map((x, index) => <li key={index}>{x}</li>)
        const secondCol = secondHalf.map((x, index) => <li key={index}>{x}</li>)

        return (
            <div className="row">
                <div className="col-md-6">
                    <ul>

                        {firstCol}
                    </ul>
                </div>
                <div className="col-md-6">
                    <ul>
                        {secondCol}
                    </ul>

                </div>
            </div>
        )
    }

    renderCancelModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Cancel</MDBModalHeader>
                    <MDBModalBody>
                        {this.cancelModal()}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' onClick={this.onCancel}>Yes</button>
                        <button className='btn btn-primary updateButton' onClick={this.toggleModal}>No</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    renderSuccessModal() {
        const header = this.state.requestType === '0' ? 'Submission Successful' : this.state.requestType === '1' ? 'Request Recieved' : 'Submission Received'
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.successModal} toggle={this.toggleSuccessModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>{header}</MDBModalHeader>
                    <MDBModalBody>
                        {this.successModal()}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/teamsrequests'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    successModal() {
        let text = '';
        switch (this.state.requestType) {
            case '0':
                switch (true) {
                    case (this.state.adResponse):
                        text = 'Please check your email for a message with instructions about the next steps. The immediate next step is legal review which needs to be initiated by you.';
                        break;
                    case (this.state.adResponse?.toUpperCase().includes("TAR")):
                        text = " This request was given automatic legal review based on an existing legal review for the same TEAMS site. Refer to: " + this.state.adResponse;
                        break;

                }
                break;
            case '1':
                text = 'Your request has been forwarded to members of the Learning & Development Team for approval.';
                break;
            default:
                text = 'Your request has been forwarded via email to the MD for approval.';
                break;
        }
        return (
            <div>{text}</div>
        )
    }
    renderConfirmationModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.confirmationModal} toggle={this.toggleConfirmationModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Confirm</MDBModalHeader>
                    <MDBModalBody>
                        Are You sure you want to add:
                        {this.confirmationModal()}
                        <br /> to {this.state.selectedTeam}?
                        {this.state.requestType !== '0' ? <div>
                            <br />Please confirm that this TEAMS site does NOT contain client data or client engagement documents.  If the site contains client data, please cancel and re-submit.
                        </div> : null}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' type='button' onClick={() => this.confirmPost()}>Confirm</button>
                        <button className='btn btn-danger updateButton' type='button' onClick={this.confirmNoClick}>Cancel</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    renderMDErrorModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.mdErrorModal} toggle={this.toggleMDErrorModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Form Error</MDBModalHeader>
                    <MDBModalBody>
                        There was an error submitting your request.  Please verify all required fields are populated.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' onClick={this.toggleMDErrorModal}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    confirmationModal() {

        return (this.state.guestAccounts.map((x, index) =>
            <div key={index}>
                {x.name + ' '}

            </div>
        )
        )
    }
    confirmPost = async (event) => {
        this.toggleConfirmationModal()

        let guestRequest = this.state.guestRequest
        const url = '/api/Teams';
        await apiPost(url, guestRequest)
            .then((response) => {
                this.setState({ adResponse: response.data, rowError: new Array(this.state.guestNames.length).fill(false), backbtn: true })
                this.toggleSuccessModal()
            })
            .catch(function (error) {
            })
    }
    onCancel() {
        window.location.reload(true);

    }

    cancelModal() {
        return (

            <div>
                Are you sure you want to Cancel and lose the changes made?
            </div>
        );
    }
    handleGuest = (selectedOption, i) => {
        let newGuestName = this.state.guestNames
        let prevSelected = this.state.guestNames[i];
        if (prevSelected !== undefined && prevSelected !== selectedOption) {
            //console.log('guestNames: ' + JSON.stringify(this.state.guestNames))
            //console.log('prevSelect: ' + JSON.stringify(prevSelected))
            this.pushGuestAccounts(prevSelected);
        }
        let newGuestOffices = this.state.guestOffices
        let newGuestEmails = this.state.guestEmails
        let newGuestOfficeKeys = this.state.guestOfficeKeys

        let selectedAllAccounts = this.state.allAccounts.filter(x => { return x.key === selectedOption.value })

        newGuestName[i] = selectedOption
        newGuestEmails[i] = selectedAllAccounts[0].emailAddress
        newGuestOffices[i] = selectedAllAccounts[0].office
        newGuestOfficeKeys[i] = selectedAllAccounts[0].officeKey
        for (var i = 0; i < this.state.accountNames.length; i++) {
            if (this.state.accountNames[i] === selectedOption) {
                this.state.accountNames.splice(i, 1);
                i--;
            }
        }
        this.setState({
            guestNames: newGuestName,
            guestEmails: newGuestEmails,
            guestOffices: newGuestOffices,
            guestOfficeKeys: newGuestOfficeKeys
        })
    }
    handleTeamSite = selectedOption => {
        this.updateTeams(selectedOption.id);
    }
    handleAppManager = selectedOption => {
        let manEmail = selectedOption.loginAddress ? selectedOption.loginAddress : selectedOption.userPrincipalName
        this.setState({ manDirector: manEmail, manDirectorName: selectedOption.name })

    }
    handleLegalApprover = selectedOption => {
        this.setState({ selectedLegalApprover: selectedOption })
    }
    handleBJustification = event => {
        this.setState({ bJustification: event.target.value })

    }
    handleExpireDate = event => {
        this.setState({ selectedDate: event })
    }
    handleCompanyChange = (selectedOption) => {
        let selectedId = [];

        let companyCount = this.state.selectedCompany === null ? 0 : this.state.selectedCompany.length;
        if (selectedOption !== null) {
            selectedOption.map((id, index) => (
                selectedId.push(id.key)));
        } else {
            this.setState({ selectedCompany: selectedOption });
        }
        if (selectedOption === null || selectedOption.length < companyCount) {
            this.setState({
                guestNames: [],
                guestOffices: [],
                guestEmails: [],
                guestOfficeKeys: [],
                accountNames: []
            });
        }
        this.setState({
            selectedCompany: selectedOption
        }, () => this.fetchDataByUser(selectedId));
    }

    handleDelete = (i) => {
        if (this.state.addAnother > 0) {
            let newGuestName = this.state.guestNames
            let newGuestOffices = this.state.guestOffices
            let newGuestOfficeKeys = this.state.guestOfficeKeys
            let newGuestEmails = this.state.guestEmails
            this.pushGuestAccounts(newGuestName[i])
            if (i > newGuestName.length) {
                this.setState(prevState => {
                    return {
                        addAnother: prevState.addAnother - 1
                    }
                })
            }
            else {
                newGuestName.splice(i, 1)
                newGuestEmails.splice(i, 1)
                newGuestOffices.splice(i, 1)
                newGuestOfficeKeys.splice(i, 1)
                this.setState(prevState => {
                    return {
                        guestNames: newGuestName,
                        guestEmails: newGuestEmails,
                        guestOffices: newGuestOffices,
                        guestOfficeKeys: newGuestOfficeKeys,
                        addAnother: prevState.addAnother - 1
                    }
                })
            }
        }
        else {
            this.pushGuestAccounts(this.state.guestNames[0])
            this.setState({
                guestNames: [],
                guestOffices: [],
                guestEmails: [],
                guestOfficeKeys: []
            })
        }
    }

    pushGuestAccounts = (g) => {
        let newList = this.state.accountNames;
        newList.push(g);
        newList.sort((a, b) => a.display.localeCompare(b.display));
        this.setState({ accountNames: newList });
    }

    maxLimitModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.limitModal} toggle={this.toggleLimitModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Limit Exceeded</MDBModalHeader>
                    <MDBModalBody>
                        You've reached a maximum of {this.state.guestLimit} users to be added.
                    </MDBModalBody>
                    <MDBModalFooter>

                        <button className='btn btn-primary updateButton' type='button' onClick={this.toggleLimitModal}>Okay</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }
    renderAddAnother() {

        return (
            [...Array(this.state.addAnother)].map((x, index) => < div key={index + 1} className='form-row' >
                <div className='form-group col-md-12'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='rightText'>
                                <label className='teamsLabel'>Name of Guest<span className="required">*</span></label>
                            </div>
                            <Select
                                isDisabled={this.state.selectedCompany === null}
                                value={this.state.guestNames[index + 1] ? this.state.guestNames[index + 1].display : 'Select...'}
                                placeholder={this.state.guestNames[index + 1] ? this.state.guestNames[index + 1].display : 'Select...'}
                                options={this.state.accountNames}
                                getOptionLabel={(option) => option.display}
                                getOptionValue={(option) => option.value}
                                onChange={(event) => this.handleGuest(event, index + 1)}
                                className={this.state.rowError[index + 1] ? `addUserInput${index + 1}` : null} />
                            {this.state.rowError[index + 1] && (this.state.errorMessage === '* Highlighted rows must be completed or deleted.' || this.state.errorMessage === '* Duplicate accounts must either be changed or deleted') ?
                                <div className='warningText' >{this.state.errorMessage}</div> : null}

                        </div>

                        <div className='col-md-4'>
                            <div className='rightText'><label className='teamsLabel'>Guest Email</label></div>
                            <div className='userContent'>{this.state.guestNames[index + 1] ? this.state.guestEmails[index + 1] : null}</div>
                        </div>
                        <div className='col-md-4'>
                            <div className='rightText'><label className='teamsLabel'>Guest Office</label></div>
                            <div className='userContent'>{this.state.guestNames[index + 1] ? this.state.guestOffices[index + 1] : null}</div>
                        </div>
                        <div className='col-md-1'>
                            <div className='delete' >
                                <FontAwesomeIcon icon={faTrashAlt} size='lg' onClick={(event) => this.handleDelete(index + 1)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            )
        )
    }

    renderLegalApprover() {
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#1e90ff' : '#fff',
                "&:hover": {
                    backgroundColor: state.isSelected ? '#1e90ff' : '#d7e4f7'
                }
            })
        }
        const formatOptionLabel = ({ name, key, region }) => (

            <div className='selectedText form-group col-md-12'>
                <div className='row'>
                    <div className='region-text'>{region}</div>
                    <div className='region-text'>{name}</div>
                </div>
            </div>
        );
        return (
            <div className='form-row'>
                <div className='form-group col-md-12'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='rightText'>
                                <label className='teamsLabel'>Attorney for Contract Review (choose your EMD's region)<span className="required">*</span></label>
                            </div>
                            <Select
                                value={this.state.selectedLegalApprover}
                                placeholder={this.state.selectedLegalApprover}
                                options={this.state.legalApprovers}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.key}
                                styles={customStyles}
                                onChange={event => { this.handleLegalApprover(event) }}
                                formatOptionLabel={formatOptionLabel}
                                className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedLegalApprover === null ? 'warningBorder' : null} />
                            {this.state.errorMessage === '* Marked fields are required.' && this.state.selectedLegalApprover === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}

                        </div>
                    </div>
                </div>

            </div>
        )

    }
    render() {
        const another = this.state.addAnother > 0 ? this.renderAddAnother() : null;
        const limit = this.state.addAnother > this.state.counterLimit ? this.maxLimitModal() : null;
        const email = this.props.userReducer.currentUser.emailAddress;
        const emailSuffix = email ? email.substring(email.lastIndexOf('@') + 1) : null;
        const checkDrop = emailSuffix === 'protiviti.com' || emailSuffix === 'roberthalf.com' ? true : false;
        let maxDate = this.state.showMaxDate ? 'Maximum date allowed is 6 months from today.' : '';
        const approver = this.state.requestType == '0' ? this.renderLegalApprover() : null;
        let days = this.state.showMaxDate ? config.teamsExpireDaysLength : 1000;
        const today = new Date();
        let inDays = new Date();
        inDays.setDate(inDays.getDate() + days);
        const directorLabel = this.state.requestType === '1' ? 'L&D Approver' : 'Approving Managing Director'
        const dirOptions = this.state.requestType === '1' ? this.state.learningApprovers : this.state.managingDirectors;
        //uncomment once complete
        if (!checkDrop) {
            return <Redirect to='/' />
        }

        return (
            <div className="teamsContainer">
                <div className="introductoryText">
                    <br />Please complete this form to request member firm access to your TEAMS site. You can submit one form for multiple users. If the users will have access to client data, you will receive instructions for requesting a legal review of the project contracts. MD approval is required for all requests. The approving MD must be a member of the TEAMS site.
                    <br /><br />
                    <strong>IMPORTANT NOTE:</strong> If the member firm will be handling sensitive data, or if they need to remove files from the Teams site to work on them, please request a Secure Azure Virtual Desktop (AVD) rather than admitting them to Teams.  You can request a secure Azure Virtual Desktop (AVD) by contacting <a href="mailto:CORPITNAProtivitiSCCS@roberthalf.com">CORP IT NA Protiviti SCCS</a>. For more information see <a href="https://ishare.protiviti.com/internal-ops-hr/member-firm" target="_blank">iShare</a>.

                </div>
                {this.renderCancelModal()}
                {this.renderSuccessModal()}
                {this.renderConfirmationModal()}
                {this.renderMDErrorModal()}
                <form>
                    <div className="formContainer">
                        <div className='form-group'>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <label>Purpose or Type of TEAMS Site<span className="required">*</span></label>
                                            <div className="custom-control custom-checkbox my-1 mr-sm-2 checkStyle">
                                                <div className="form-check">
                                                    <input type="radio" name="request-type" id="client" value='0' checked={this.state.requestType === '0'} className="form-check-input" onChange={this.handleOptionChange} /> Client Engagement Delivery TEAMS Site (Guest will have access to client data)
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" name="request-type" id="training" value='1' checked={this.state.requestType === '1'} className="form-check-input" onChange={this.handleOptionChange} /> Protiviti Training TEAMS Site (Guest will use TEAMS as part of a training program)
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" name="request-type" id="other" value='2' checked={this.state.requestType === '2'} className="form-check-input" onChange={this.handleOptionChange} /> Other TEAMS Site (Such as broad strategic collaboration or business development)
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label>End Date for Guest Access<span className="required">*</span></label><br />
                                            <DatePicker
                                                className='form-control'
                                                selected={this.state.selectedDate}
                                                onChange={this.handleExpireDate}
                                                minDate={today}
                                                maxDate={inDays}
                                                dateFormat="dd MMM yyyy"
                                                className={this.state.showMaxDate && this.state.errorMessage === '* Marked fields are required.' && this.state.selectedDate === null ? 'warningBorder form-control' : 'form-control'} />
                                            <div className="required-text">
                                                <span className="required">*</span> Required for sites containing client engagement documents.<br />
                                                {maxDate}
                                            </div>
                                            {this.state.showMaxDate && this.state.errorMessage === '* Marked fields are required.' && this.state.selectedDate === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                        </div>
                                    </div>
                                    <br />

                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='rightText'>
                                                <label className='teamsLabel'>Member Firm Company/Office<span className="required">*</span></label>
                                            </div>
                                            <Select
                                                value={this.state.selectedCompany}
                                                isMulti
                                                placeholder={this.state.selectedCompany}
                                                options={this.state.companies}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                onChange={event => { this.handleCompanyChange(event) }}
                                                className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedCompany === null ? 'warningBorder' : null}
                                            />
                                            {this.state.errorMessage === '* Marked fields are required.' && this.state.selectedCompany === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                        </div>
                                        <div className="accountActions">
                                            <Link to='/assignteamshelp' target="_blank" ><span className='buttonStyle'>Can't find your guest?</span></Link>
                                        </div>

                                    </div>
                                    <br />

                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='rightText'>
                                                <label className='teamsLabel'>Name of Guest<span className="required">*</span></label>
                                            </div>
                                            <Select
                                                isDisabled={this.state.selectedCompany === null}
                                                value={this.state.guestNames[0] ? this.state.guestNames[0].display : 'Select...'}
                                                placeholder={this.state.guestNames[0] ? this.state.guestNames[0].display : 'Select...'}
                                                options={this.state.accountNames}
                                                getOptionLabel={(option) => option.display}
                                                getOptionValue={(option) => option.value}
                                                className={this.state.rowError[0] ? `addUserInput0` : null}
                                                onChange={(event) => this.handleGuest(event, 0)} />
                                            {this.state.rowError[0] && (this.state.errorMessage === '* Highlighted rows must be completed or deleted.' || this.state.errorMessage === '* Duplicate accounts must either be changed or deleted') ?
                                                <div className='warningText' > {this.state.errorMessage}</div> : null}

                                        </div>
                                        <div className='col-md-4'>
                                            <div className='rightText'><label className='teamsLabel'>Guest Email</label></div>
                                            <div className='userContent'>{this.state.guestNames[0] ? this.state.guestEmails[0] : null}</div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='rightText'><label className='teamsLabel'>Guest Office</label></div>
                                            <div className='userContent'>{this.state.guestNames[0] ? this.state.guestOffices[0] : null}</div>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className='delete'>
                                                <FontAwesomeIcon icon={faTrashAlt} size='lg' onClick={(event) => this.handleDelete(0)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {another}
                            {limit}
                            <button type='button' className={this.state.addAnother > this.state.counterLimit ? 'addIconDisabled' : 'addIcon'} onClick={this.incrementAnother} disabled={this.state.addAnother > this.state.counterLimit}><FontAwesomeIcon icon={faPlus} size='sm' />  Add Another Guest</button>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='rightText'>
                                                <label className='teamsLabel'>Name of Microsoft Teams Site<span className="required">*</span></label>
                                            </div>
                                            <Select
                                                value={this.state.selectedTeam}
                                                placeholder={this.state.selectedTeam}
                                                options={this.state.teams}
                                                getOptionLabel={(option) => option.displayName}
                                                getOptionValue={(option) => option.displayName}
                                                onChange={event => { this.handleTeamSite(event) }}
                                                className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedTeam === null ? 'warningBorder' : null} />
                                            {this.state.errorMessage === '* Marked fields are required.' && this.state.selectedTeam === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='rightText'><label className='teamsLabel'>URL of Microsoft Teams Site</label></div>
                                            <input
                                                disabled
                                                defaultValue={this.state.selectedTeamUrl}
                                                type='text'
                                                className='form-control' />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='rightText'>
                                                <label className='teamsLabel'>{directorLabel}<span className="required">*</span></label>
                                            </div>
                                            <Select
                                                isDisabled={this.state.selectedTeam === null || this.state.emd !== null || this.state.disableMD}
                                                value={this.state.emd ? this.state.emd : this.state.manDirector}
                                                placeholder={this.state.emd ? this.state.emd : this.state.manDirectorName}
                                                options={dirOptions}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.loginAddress}
                                                onChange={event => { this.handleAppManager(event) }}
                                                className={this.state.errorMessage === '* Marked fields are required.' && this.state.manDirector === null && this.state.emd === null ? 'warningBorder' : null}
                                            />
                                            {this.state.errorMessage === '* Marked fields are required.' && this.state.manDirector === null && this.state.emd
                                                === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='rightText'><label className='teamsLabel'>MD Email</label></div>
                                            <input
                                                readOnly
                                                defaultValue={this.state.manDirector ? this.state.manDirector : ''}
                                                type='text'
                                                className='form-control' />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {approver}
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <div className='rightText'>
                                                <label className='teamsLabel'>Business Justification<span className="required">*</span></label>
                                            </div>
                                            <textarea className={this.state.errorMessage === '* Marked fields are required.' && this.state.bJustification === '' ? 'warningBorder form-control' : 'form-control'} value={this.state.bJustification} rows="3" onChange={this.handleBJustification} ></textarea>
                                            {this.state.errorMessage === '* Marked fields are required.' && this.state.bJustification === '' ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="containerOuter">
                                                <div className="termsContainer">
                                                    <div className="introductoryText">
                                                        Please acknowledge that you understand that by providing access to this TEAMS site to a Guest User you are allowing the Guest User to access all the information contained on this TEAMS site including: Protiviti, client, contractor and/or vendor information which may be of a business proprietary, confidential and/or competition sensitive nature.  Guest Users will have the capability to download files from this TEAMS site. Access should be given only on an as-needed basis to meet legitimate business and/or client needs. You are required to ensure that any Guest User permitted to access this TEAMS site understands his/her confidentiality obligations and protocols for handling client data and Protiviti’s work product, methodologies and confidential information. The Member Firm Agreement with Protiviti contains confidentiality provisions applicable to the Member Firm. However, the nature of the services being provided and/or client requirements may necessitate more stringent confidentiality protections than those that are found in the Member Firm Agreement. As a result, it is your responsibility to ensure, prior to granting access to this TEAMS site to a Member Firm guest, that you have flowed down all applicable confidentiality obligations to the Member Firm (either in the subcontract or the applicable statement of work). In addition, it is recommended that you separately communicate and inform the Member Firm guest of all applicable confidentiality obligations to ensure the obligations are understood.
                                                    </div>
                                                    <div className="custom-control custom-checkbox my-1 mr-sm-2 checkStyle">
                                                        <input type="checkbox" className="custom-control-input" value='check' id="customControlInline" checked={this.state.checked} onChange={this.handleChange} />
                                                        <label className="custom-control-label teamsLabel" htmlFor="customControlInline" >I have read and understand the risks and responsibilities</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row  actionButtons'>
                                        <div className='col-md-12'>
                                            <Link to='/teamsrequests'><button type="button" className="btn btn-primary updateButton bottom-button" disabled={this.state.backbtn}>Back To List</button></Link>
                                            <button type="button" className="btn btn-secondary my-1 bottom-button" type="button" disabled={!this.state.submit} onClick={this.toggleModal}>Cancel</button>
                                            <button className="btn btn-primary my-1 bottom-button" type="button" disabled={!this.state.submit} onClick={this.findDuplicates}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row bottomError warningText'>
                                        {this.state.errorMessage}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser, fetchCompany })(AssignTeams)