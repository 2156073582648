import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import { Provider } from 'react-redux';
import { store } from './store/store';

// MSAL imports
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        //console.log("active account: " + JSON.stringify(account));
        msalInstance.setActiveAccount(account);
    }
});

await msalInstance.handleRedirectPromise().then(authResult => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        msalInstance.loginRedirect();
        console.log("No account");
    }
}).catch(err => {
    console.log('RedirectPromiseError', err);
});

const rootElement = document.getElementById('root');

const UnsupportedBrowserPage = () => (
    <div>
        <p>Internet Explorer is not supported, please use Chrome or Edge browsers</p>
    </div>
);

var ua = window.navigator.userAgent;
var msie = ua.indexOf('MSIE ');
var trident = ua.indexOf('Trident/');

if (trident > 0 || msie > 0) {
    ReactDOM.render(<UnsupportedBrowserPage />, document.getElementById('root'));
} else {
    ReactDOM.render(
        <Provider store={store}>
            <App instance={msalInstance} />
        </Provider>,
        rootElement);

    unregisterServiceWorker();
}