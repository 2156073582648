import React from 'react';
import './RequestFilter.css';
import { connect } from 'react-redux';
import { getRequestFilterStatus, getCurrentUser } from '../../actions';

class RequestFilter extends React.Component {
    state = {
        rany: false,
        open: true,
        closed: true,
        deleted: false
    }

    componentDidMount() {
        this.props.getRequestFilterStatus(this.state);
    }

    onChange = (event) => {
        if (event.target.value === 'rany') {
            if (!this.state.rany) {
                this.setState({ rany: true, open: true, closed: true, deleted: true, }, () => {
                    let filter = {
                        rany: this.state.rany,
                        open: this.state.open,
                        closed: this.state.closed,
                        deleted: this.state.deleted
                    }
                    this.props.getRequestFilterStatus(filter);
                });
            }
            else {
                this.setState({ rany: false, open: false, closed: false, deleted: false }, () => {
                    let filter = {
                        rany: this.state.rany,
                        open: this.state.open,
                        closed: this.state.closed,
                        deleted: this.state.deleted
                    }
                    this.props.getRequestFilterStatus(filter);
                });
            }
        }

        if (event.target.value === 'open' || event.target.value === 'closed' || event.target.value === 'deleted') {
            let status = (event.target.value === 'open' ? !this.state.open : event.target.value === 'closed' ? !this.state.closed : !this.state.deleted);
            this.setState({ [event.target.value]: status }, () => {
                if (this.state.rany) {
                    this.setState({ rany: false }, () => {
                        let filter = {
                            rany: this.state.rany,
                            open: this.state.open,
                            closed: this.state.closed,
                            deleted: this.state.deleted
                        }
                        this.props.getRequestFilterStatus(filter);
                    });
                }
                else if (this.state.open && this.state.closed && this.state.deleted) {
                    this.setState({ rany: true }, () => {
                        let filter = {
                            rany: this.state.rany,
                            open: this.state.open,
                            closed: this.state.closed,
                            deleted: this.state.deleted
                        }
                        this.props.getRequestFilterStatus(filter);
                    });
                }
                else {
                    let filter = {
                        rany: this.state.rany,
                        open: this.state.open,
                        closed: this.state.closed,
                        deleted: this.state.deleted
                    }
                    this.props.getRequestFilterStatus(filter);
                }
            });
        }
    }

    renderDelete = () => {
        if (this.props.userReducer.currentUser.isAdmin) {
            return(<div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                <input type='checkbox' className='custom-control-input statusOptions' id='deleted' value='deleted' checked={this.state.deleted} onChange={this.onChange} />
                <label className='custom-control-label statusOptions' htmlFor='deleted'>Deleted</label>
            </div>)
        }
    }

    render() {
        return (
            <div className='filter'>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='rany' value='rany' checked={this.state.rany} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='rany'>Any</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='open' value='open' checked={this.state.open} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='open'>Open</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='closed' value='closed' checked={this.state.closed} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='closed'>Closed</label>
                </div>
                {this.renderDelete()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getRequestFilterStatus, getCurrentUser })(RequestFilter);