import React, { Component } from 'react';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTeamsRequests, getRequestFilterStatus, getMdApprovalStatusFilter, showExpandAlert } from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import '../../pages/Teams/TeamsRequests.css';
import "react-datepicker/dist/react-datepicker.css";
import ExpirationModal from './ExpirationModal';
import DetailModal from './DetailModal';
import { fetchAADMembersByTeamsID, fetchAADOwnersByTeamsID } from "../../services/graphApiService";
import { apiPut, apiGet } from '../../services/apiService';
 
class component extends Component {
    disp = [];
    active = [];
    disabled = [];
    expired = [];
    data = [];

    requestStatusValue = {
        0: 'Open',
        1: 'Closed'
    };

    constructor(props) {
        super(props);
        this.state = {
            allTeamsRequest: [],
            allTeamsRequestsDupe: [],
            allTeamsRequestResponse: [],
            modal: false,
            modalYesDisable: false,
            modalNoDisable: false,
            editRequest: {},
            action: null,
            modalData: {},
            requestStatusFilter: {
                rany: false,
                open: true,
                closed: true,
                deleted: false
            },
            mdStatusFilter: {
                many: true,
                mpending: true,
                mapproved: true,
                mrejected: true,
                expired: true
            },
            requestKey: null,
            totalRows: 0,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortOrder: "desc",
            filterText: "",
            dataLoading: true,
            detailModal: false,
            detailModalAction: null,
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchDataByUser();
    }

    componentDidUpdate() {
        if ((this.state.requestStatusFilter.rany !== this.props.sharedReducer.requestStatusFilter.rany) ||
            (this.state.requestStatusFilter.open !== this.props.sharedReducer.requestStatusFilter.open) ||
            (this.state.requestStatusFilter.closed !== this.props.sharedReducer.requestStatusFilter.closed) ||
            (this.state.requestStatusFilter.deleted !== this.props.sharedReducer.requestStatusFilter.deleted)) {
            this.setStatusFilter();
        }
        if ((this.state.mdStatusFilter.many !== this.props.sharedReducer.mdStatusFilter.many) ||
            (this.state.mdStatusFilter.mpending !== this.props.sharedReducer.mdStatusFilter.mpending) ||
            (this.state.mdStatusFilter.mapproved !== this.props.sharedReducer.mdStatusFilter.mapproved) ||
            (this.state.mdStatusFilter.mrejected !== this.props.sharedReducer.mdStatusFilter.mrejected) ||
            (this.state.mdStatusFilter.expired !== this.props.sharedReducer.mdStatusFilter.expired)) {
            this.setStatusFilter();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStatusFilter = async () => {
        let requests = await this.state.allTeamsRequestsDupe;
        this.setState({ requestStatusFilter: this.props.sharedReducer.requestStatusFilter }, async () => {
            if (this.state.requestStatusFilter.deleted === false) {
                requests = requests.filter(x => x.isDeleted === false);
            }
            if (this.state.requestStatusFilter.open === false) {
                requests = requests.filter(x => x.requestStatus !== 'Open');
            }
            if (this.state.requestStatusFilter.closed === false) {
                requests = requests.filter(x => x.requestStatus !== 'Closed');
            }
            if (this.state.requestStatusFilter.open === false && this.state.requestStatusFilter.closed === false && this.state.requestStatusFilter.deleted === true) {
                requests = this.state.allTeamsRequestsDupe.filter(x => x.isDeleted === true);
            }
        });

        this.setState({ mdStatusFilter: this.props.sharedReducer.mdStatusFilter }, async () => {
            if (this.state.mdStatusFilter.mpending === false) {
                console.log("filtering pending")
                requests = await requests.filter(x => x.approvalStatus !== 'Pending');
            }
            if (this.state.mdStatusFilter.mapproved === false) {
                console.log("filtering approved")
                requests = await requests.filter(x => x.approvalStatus !== 'Approved')
            }
            if (this.state.mdStatusFilter.mrejected === false) {
                console.log("filtering rejected")
                requests = await requests.filter(x => x.approvalStatus !== 'Rejected')
            }
            if (this.state.mdStatusFilter.expired === false) {
                console.log("filtering expired")
                requests = await requests.filter(x => x.approvalStatus !== 'Expired')
            }
            requests = this.assembleRequests(requests).map((x, index) => ({ ...x, action: this.renderActions(requests[index]) }));

            await this.setState({ allTeamsRequest: requests });
        });
    }

    fetchDataByUser = async (filter = this.state.filterText) => {
        let url = `/api/ClientTeams?search=${filter}`;
        //let url = config.azRedirectUri + `/api/ClientTeams?page=${page}&size=${size}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${filter}`;
        //let url = config.azRedirectUri + '/api/ClientTeams/';
        await apiGet(url)
            .then((response) => {
                if (this.mounted) {
                    let sortedResponse = response.data;

                    let teamRequests = this.assembleRequests(sortedResponse).map((x, index) => ({ ...x, action: this.renderActions(sortedResponse[index]) }));
                    this.setState({ allTeamsRequest: teamRequests, allTeamsRequestsDupe: sortedResponse, allTeamsRequestResponse: sortedResponse, totalRows: response.data.count, dataLoading: false }, () => {
                        this.setStatusFilter();
                        const param = new URLSearchParams(window.location.search)
                        const requestKey = param.get('key');
                        if (requestKey !== null) {
                            const exists = sortedResponse.some(x => x.key === requestKey);
                            if (exists) {
                                let request = sortedResponse.find((element) => {
                                    return element.key === requestKey;
                                });
                                this.expirationModal.handleExpiration(request);
                            }
                        }
                    });
                }
            })
            .catch(function (error) {
                if (error.response != null && error.response.status === 401) {
                    //this.setState({ isReload: true });
                    this.fetchDataByUser();
                }
                console.log(error);
            })
    }

    getADMembership = async (teamsSiteUrl, teamsSiteName) => {
        let url = teamsSiteUrl.substring(teamsSiteUrl.indexOf('=') + 1);
        let groupId = url.substring(0, url.indexOf('&'));
        if (groupId.length != 36) {
            groupId = teamsSiteUrl.split('groupId=')[1].split('&')[0];
        }
        await fetchAADOwnersByTeamsID(groupId).then(async (owners) => {
            return fetchAADMembersByTeamsID(groupId).then(async (members) => {
                let teamsUsers = [...owners.value, ...members.value];
                teamsUsers = teamsUsers.filter((item, index) => index === teamsUsers.findIndex(e => e.mail === item.mail));
                teamsUsers = teamsUsers.sort((a, b) => { if (a.displayName < b.displayName) { return -1; } if (a.displayName > b.displayName) { return 1; } return 0; });
                this.toggleDetailModal({ accounts: teamsUsers, teamsSiteName: teamsSiteName}, "adMembership");
             })
        }).catch(() => {
            this.toggleDetailModal(null, "error");
        });
    }

    assembleRequests = (response) => {
        let allTeamsRequest = response.map((x) => {
            return ({
                'requestId': x.requestId,
                'requester': x.requesterName,
                'status': x.requestStatus,
                'mdStatus': x.approvalStatus,
                'mdApprover': x.mdName,
                'clientName': x.clientName,
                'engagementName': x.engagementName,
                'teamsSite': x.teamsSiteName,
                'dateCreated': Date.parse(x.createdDate),
                'teamsSiteUrl': x.teamsSiteUrl,
            })
        });
        return allTeamsRequest;
    }

    handlePageChange = page => {
        //this.fetchDataByUser(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        //this.fetchDataByUser(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    //handleSortChange = (c, d) => {
    //    this.setState({ sortBy: c.selector, sortOrder: d }, () => {
    //        this.fetchDataByUser(this.state.currentPage);
    //    });
    //}

    handleFilterChange = (e) => {
        this.setState({ filterText: e.target.value }, () => {
            this.fetchDataByUser();
        });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "" });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    toggleModal = (request, action) => {
        this.setState({
            modal: !this.state.modal,
            modalAction: action,
            modalRequest: request,
            modalYesDisable: request === null ? false : this.state.modalYesDisable,
            modalNoDisable: request === null ? false : this.state.modalNoDisable
        });
    }

    toggleDetailModal = (modalData, action) => {
        this.setState({
            detailModal: !this.state.detailModal,
            detailModalAction: action,
            modalData: modalData,
        })
    }

    handleAction = async () => {
        let link;
        switch (this.state.modalAction) {
            case 'Restore':
            case 'Delete':
                link = '/api/ClientTeams/delete/';
                this.handleUpdate(link);
                break;
            case 'Renew':
                this.props.history.push({ pathname: '/clientaccessrequest/', state: { key: this.state.modalRequest.key } });
                break;
            default:
                link = null;
        }
    }

    handleUpdate = async (link) => {
        this.setState({ modalYesDisable: true, modalNoDisable: true })
        const url = link + this.state.modalRequest.key;
        const body = '"' + this.state.modalAction + '"';
        apiPut(url, body)
            .then((response) => {
                this.setState({ modalYesDisable: false, modalNoDisable: false })
                this.toggleModal(null, null);
                this.fetchDataByUser();
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    //this.setState({ isReload: true });
                    this.handleAction();
                }
                console.log(error);
            })
    }

    openModal() {
        let requestId;
        if (this.state.modalRequest)
            requestId = this.state.modalRequest.requestId;
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal(null)}>
                    <MDBModalHeader toggle={() => this.toggleModal(null)}>
                        {this.state.modalAction} Request {requestId}
                    </MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to {this.state.modalAction} request {requestId}?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" disabled={this.state.modalNoDisable} onClick={() => this.toggleModal(null)}>No</MDBBtn>
                        <MDBBtn color="primary" disabled={this.state.modalYesDisable} onClick={this.handleAction}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderActions(request) {
        let softDelete;
        let renewExpiredAccess;
        if (this.props.userReducer.currentUser.isAdmin) {
            let action = request.isDeleted === true ? 'Restore' : 'Delete';
            softDelete = <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(request, action)}><MDBDropdownItem>{action}</MDBDropdownItem></span>
        }
        if (request.isExpired === true) {
            let action = 'Renew'
            renewExpiredAccess = <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(request, action)}><MDBDropdownItem>Renew Expired Access</MDBDropdownItem></span>
        }
        let changeExpiration = request.approvalStatus !== 'Pending' && request.approvalStatus !== 'Rejected' ? <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.expirationModal.handleExpiration(request)}><MDBDropdownItem>Change Expiration</MDBDropdownItem></span> : null;
        return (
            <div className='dropdownAction'>
                <MDBDropdown dropright>
                    <MDBDropdownToggle color="none">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <Link to={{ pathname: '/clientteamsrequestdetail/' + request.key }} style={{ color: 'black', textDecoration: 'none' }}><MDBDropdownItem>View Details</MDBDropdownItem></Link>
                        {changeExpiration}
                        {renewExpiredAccess}
                        {softDelete}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        );
    }

    render() {
        const columns = [{ name: 'Request Id', selector: row => row.requestId, maxWidth: '140px', sortable: true },
            { name: 'Requestor', selector: row => row.requester, sortable: true },
            { name: 'Request Status', selector: row => row.status, maxWidth: '130px', sortable: true },
            { name: 'MD Status', selector: row => row.mdStatus, maxWidth: '115px', sortable: true },
            { name: 'MD Approver', selector: row => row.mdApprover, sortable: true },
            { name: 'Client Name', selector: row => row.clientName, sortable: true, wrap: true },
            { name: 'Engagement Name', selector: row => row.engagementName, sortable: true, wrap: true },
            { name: 'TEAMS Site', selector: row => row.teamsSite, sortable: true, wrap: true, cell: row => this.props.userReducer.currentUser.isAdmin ? <a href="" onClick={(e) =>{e.preventDefault(); this.getADMembership(row.teamsSiteUrl, row.teamsSite)}}>{row.teamsSite}</a> : row.teamsSite },
            { name: 'Date Created', selector: row => row.dateCreated, maxWidth: '120px', sortable: true, cell: row => new Date(row.dateCreated).toLocaleDateString() },
            { name: 'Edit', selector: row => row.action, allowOverflow: true, button: true }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',

                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };
        return (
            <div>
                <DataTable
                    columns={columns}
                    data={this.state.allTeamsRequest}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    subHeader
                    //sortServer
                    progressPending={this.state.dataLoading}
                    //onSort={this.handleSortChange}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    paginationTotalRows={this.state.totalRows}
                    paginationPerPage={this.state.perPage}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={{ noRowsPerPage: false }}
                />
                {this.openModal()}
                <ExpirationModal childRef={ref => (this.expirationModal = ref)} />
                <DetailModal isOpen={this.state.detailModal} toggleModal={this.toggleDetailModal} modalAction={this.state.detailModalAction} modalData={this.state.modalData} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default withRouter(connect(mapStateToProps, { getTeamsRequests, getRequestFilterStatus, getMdApprovalStatusFilter, showExpandAlert })(component));