import React, { Component } from 'react';
import { getCurrentUser } from '../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '../../config';
import './ResetPassword.css';
import { apiPost } from '../../services/apiService';

class PasswordReset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			input: {},
			errors: {},
			submitDisabled: true,
			minLength: 8
		};
	}

	componentDidMount() {
		//console.log("Current User: " + this.props.userReducer.currentUser);

	}

	handleChange = e => {
		let input = this.state.input;
		input[e.target.name] = e.target.value;

		this.setState({ input: input }, () => this.validate());
	}

	handleSubmit = async e => {
		e.preventDefault();

		let that = this;
		let update = {
			'userEmail': this.props.userReducer.currentUser.emailAddress,
			'password': this.state.input["password"]
		};
		const url = config.azRedirectUri + '/api/Accounts/ResetPassword';
		await apiPost(url, update)
			.then(() => {
				this.props.history.push('/resetconfirmation');
			})
			.catch(function (error) {
				if (typeof error === "object") {
					error = error.message;
				}
				console.log("error " + error);
				let confirmErrors = {};
				if (error === "Request failed with status code 409") {
					confirmErrors["password_recent"] = error;
				} else {
					confirmErrors["confirm_password"] = "Something went wrong, please try again later";
				}

				that.setState({ errors: confirmErrors, submitDisable: true });
			})
	}

	validate = () => {
		let input = this.state.input;
		let errors = {};
		let passwordIsValid = true;
		let confirmPasswordIsValid = true;
		let submitDisable = true;

		if (!input["password"]) {
			passwordIsValid = false;
			errors["password"] = "Password required. ";
		}

		if (input["password"].length < this.state.minLength) {
			passwordIsValid = false;
			errors["password_length"] = "fail";
		}

		let caseMessage = this.checkCase(input["password"]);
		if (caseMessage && !caseMessage.includes('has number ')) {
			passwordIsValid = false;
			errors["password_number"] = "fail";
		}

		if (caseMessage && !caseMessage.includes('has upper ')) {
			passwordIsValid = false;
			errors["password_upper"] = "fail";
		}

		if (caseMessage && !caseMessage.includes('has lower ')) {
			passwordIsValid = false;
			errors["password_lower"] = "fail";
		}

		if (input["confirm_password"]) {
			if (input["password"] !== input["confirm_password"]) {
				confirmPasswordIsValid = false;
				errors["confirm_password"] = "Passwords do not match. Re-enter passwords";
			}
		} else {
			confirmPasswordIsValid = false;
		}

		if (passwordIsValid && confirmPasswordIsValid) {
			submitDisable = false;
		}
		this.setState({ errors: errors, submitDisabled: submitDisable });
	}

	checkCase = e => {
		var i = 0;
		let message = null;
		while (i < e.length) {
			let character = e.charAt(i);
			if (!isNaN(character * 1)) {
				message += "has number ";
			} else {
				if (character === character.toUpperCase()) {
					message += "has upper ";
				}
				if (character === character.toLowerCase()) {
					message += "has lower ";
				}
			}
			i++;
		}

		return message;
	}

	render() {
		return (
			<div>
				<div className="container-fluid title-container">
					<div className="col-md-12">
						<h3>MyLearning Password Reset Page</h3>
					</div>
				</div>
				<div className="container-fluid align-items-center main-container">
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-md-6">
								<div className="page-header">
									<h4>Password Reset</h4>
								</div>
								<div>
									<span className="badge badge-default">New Password:*</span>
									<input
										name='password'
										type='password'
										className='form-control'
										onChange={this.handleChange} />
									<div className="text-danger">{this.state.errors.password}</div>
								</div>
								<div>
									<span className="badge badge-default">Confirm Password:*</span>
									<input
										name='confirm_password'
										type='password'
										className='form-control'
										onChange={this.handleChange} />
									<div className="text-danger">{this.state.errors.confirm_password}</div>
								</div>
								<div className="button-group">
									<button className="btn btn-secondary" type="button" name="Cancel" value="Cancel">Cancel</button>
									<button className="btn btn-primary submit-button" type="submit" name="Submit" value="Submit" disabled={this.state.submitDisabled}>Submit</button>
								</div>
							</div>
							<div className="col-md-6">
								<h4>
									Rules for a Valid Password
								</h4>
								<ol type="1">
									<li className={this.state.errors.password_length ? "error-text" : ""}>Minimum 8 characters</li>
									<li>At least 1 of each of the following
										<ol type="a">
											<li className={this.state.errors.password_upper ? "error-text" : ""}>An upper-case letter (ex. A, B, C)</li>
											<li className={this.state.errors.password_lower ? "error-text" : ""}>A lower-case letter (ex. a, b, c)</li>
											<li className={this.state.errors.password_number ? "error-text" : ""}>A number (ex. 1, 2, 3)</li>
										</ol>
									</li>
									<li className={this.state.errors.password_recent ? "error-text" : ""}>Recent passwords cannot be repeated</li>
								</ol>
							</div>
						</div>
					</form>
				</div>
				<div className="container-fluid title-container">
					<div className="col-md-12">
						<h3>Access <a href={config.myLearning} target='_blank' rel='noopener noreferrer'>My Learning</a></h3>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sharedReducer: state.sharedReducer,
		userReducer: state.userReducer
	}
}

export default withRouter(connect(mapStateToProps, { getCurrentUser })(PasswordReset));