import React from 'react';
import './AccountCreate.css';
import { Link } from 'react-router-dom';
import ProOffice from '../../components/Shared/ProOffice';
import { connect } from 'react-redux';
import { clearAddOffices, clearAddOfficeRow } from '../../actions';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUser } from '../../reducers/userReducer';
import { apiGet, apiPost } from '../../services/apiService';

class AddAccount extends React.Component {
    state = {
        whitelist: [],
        office: {},
        firstName: [],
        lastName: [],
        email: [],
        accounts: [],
        disableButtons: false,
        modal: false,
        addResponse: [],
        errorMessage: null,
        rowError: new Array(10).fill(false),
        emailError: false,
        message: null
    };

    constructor(props) {
        super(props);
        this.props.clearAddOffices();
    }

    componentDidMount() {
        this.fetchEmailWhitelist()
        if (this.props.currentUser.emailAddress) {
            this.renderMessage();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentUser !== prevProps.currentUser) {
            this.renderMessage();
        }
    }

    fetchEmailWhitelist = async () => {
        let url = '/api/EMailWhitelist';
        await apiGet(url)
            .then((response) => {
                let whiteList = response.data;
                whiteList = whiteList.filter(x => x.isDeleted === false).map((x) => {
                    return (x.name)
                });
                this.setState({ whitelist: whiteList });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onGivenNameChange = (event, index) => {
        let newFirstName = this.state.firstName;
        newFirstName[index] = event.target.value;
        this.setState({ firstName: newFirstName });
    }

    onSurnameChange = (event, index) => {
        let newLastName = this.state.lastName;
        newLastName[index] = event.target.value;
        this.setState({ lastName: newLastName });
    }

    onEmailChange = (event, index) => {
        let newEmail = this.state.email;
        newEmail[index] = event.target.value;
        let rowErr = this.state.rowError;
        rowErr[index] = !this.validateEmail(event.target.value);
        this.setState({ email: newEmail, rowError: rowErr });
        if (rowErr.every(x => x === false)) {
            this.setState({ errorMessage: null });
        }
        else {
            this.setState({ errorMessage: 'Please Enter a Valid Email Address.' });
        }
    }

    validateEmail(email) {
        if (email === '') {
            return true;
        }
        let pattern = /^[\._A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase());
    }

    formatDate() {
        let date = new Date();
        date.setDate(date.getDate() + 90);
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    getAccountType(city) {
        if (city === '')
            return 0;
        return (city === "APAC" || city === "EU" || city === "NA") ? 1 : 0;
    }

    addAccounts = async (event) => {
        event.preventDefault();
        if (this.state.rowError.every(x => x === false)) {
            let accounts = [];
            let rowErrors = 0;
            let firstNameLength = this.state.firstName.filter(function (x) { return x.trim() !== ''; }).length;
            let lastNameLength = this.state.lastName.filter(function (x) { return x.trim() !== ''; }).length;
            let emailLength = this.state.email.filter(function (x) { return x.trim() !== ''; }).length;
            let officeLength = this.props.sharedReducer.selectedAddOffices ? this.props.sharedReducer.selectedAddOffices.filter(x => (x !== undefined) && (x !== null) && (x !== '')).length : 0;
            if (firstNameLength === lastNameLength && firstNameLength === emailLength && firstNameLength === officeLength && firstNameLength !== 0) {
                this.setState({ disableButtons: true });
                this.setState({ rowError: new Array(10).fill(false) });
                for (let i = 0; i < 10; i++) {
                    if (this.state.firstName[i] && this.state.lastName[i] && this.state.email[i] && this.props.sharedReducer.selectedAddOffices[i]) {
                        let account = {
                            firstName: this.state.firstName[i],
                            lastName: this.state.lastName[i],
                            emailAddress: this.state.email[i],
                            officeKey: this.props.sharedReducer.selectedAddOffices[i].key,
                            city: this.props.sharedReducer.selectedAddOffices[i].city,
                            accountType: this.getAccountType(this.props.sharedReducer.selectedAddOffices[i].city)
                        };
                        accounts.push(account);
                    }
                    else {
                        if (this.state.firstName[i] || this.state.lastName[i] || this.state.email[i] || this.props.sharedReducer.selectedAddOffices[i]) {
                            rowErrors++;
                            let rowErr = this.state.rowError;
                            rowErr[i] = true;
                            this.setState({ rowError: rowErr, errorMessage: 'Highlighted rows must be completed or cleared.' });
                            setTimeout(() => {
                                this.setState({ errorMessage: null, rowError: new Array(10).fill(false), disableButtons: false });
                            }, 5000);
                        }
                    }
                }
                if (rowErrors === 0 && accounts.length > 0) {
                    if (this.isEmailwhitelist(this.state.email)) {
                        this.setState({ rowError: new Array(10).fill(false), accounts: accounts });
                        const url = '/api/Accounts';
                        await apiPost(url, accounts)
                            .then((response) => {
                                this.setState({ addResponse: response.data });
                                let filledIndex = [];
                                this.state.email.forEach((x, index) => {
                                    if (x) {
                                        filledIndex.push(index);
                                    }
                                });
                                response.data.forEach((x, index) => {
                                    if (x !== 'Success' && (filledIndex[index] || filledIndex[index] === 0)) {
                                        let errors = this.state.rowError;
                                        errors[filledIndex[index]] = true;
                                        this.setState({ rowError: errors, emailError: true });
                                    }
                                })
                                this.toggleModal();
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                    }
                    else {
                        this.setState({ disableButtons: false });
                    }
                }
            }
            else {
                for (let i = 0; i < 10; i++) {
                    if (!(this.state.firstName[i] && this.state.lastName[i] && this.state.email[i] && this.props.sharedReducer.selectedAddOffices[i])) {
                        if (this.state.firstName[i] || this.state.lastName[i] || this.state.email[i] || this.props.sharedReducer.selectedAddOffices[i]) {
                            rowErrors++;
                            let rowErr = this.state.rowError;
                            rowErr[i] = true;
                            this.setState({ rowError: rowErr, errorMessage: 'Highlighted rows must be completed or cleared.' });
                            setTimeout(() => {
                                this.setState({ errorMessage: null, rowError: new Array(10).fill(false) });
                            }, 5000);
                        }
                    }
                }
            }
        }
    }

    isEmailwhitelist(accounts) {
        let emailWhitelist = this.state.whitelist;
        let rowErr = this.state.rowError;
        for (let j = 0; j < accounts.length; j++) {
            if (accounts[j]) {
                let accountEmail = accounts[j].substring(accounts[j].indexOf('@') + 1, accounts[j].length);
                if (!emailWhitelist.includes(accountEmail)) {
                    rowErr[j] = true;
                    this.setState({ errorMessage: 'Email Domain must be in Email Whitelist.', rowError: rowErr });
                }
            }
        }

        if (!rowErr.every(x => x === false)) {
            setTimeout(() => {
                this.setState({ errorMessage: null, rowError: new Array(10).fill(false) });
            }, 5000);
            return false;
        }
        else {
            return true;
        }
    }

    clearOffice = () => {
        this.props.clearAddOffices();
    }

    clearAll = () => {
        this.setState({ firstName: [], lastName: [], email: [], accounts: [] });
        for (let i = 0; i < this.props.sharedReducer.selectedAddOffices.length; i++) {
            this.props.clearAddOfficeRow(i);
        }
    }

    clearRow = (index) => {
        let firstNameTemp = this.state.firstName;
        let lastNameTemp = this.state.lastName;
        let emailTemp = this.state.email;
        firstNameTemp[index] = '';
        lastNameTemp[index] = '';
        emailTemp[index] = '';
        this.setState({ firstName: firstNameTemp, lastName: lastNameTemp, email: emailTemp });
        this.props.clearAddOfficeRow(index);
    }

    clearSuccess = (event) => {
        event.preventDefault();
        this.toggleModal();
        this.setState({ errorMessage: 'Email Already Exist. Please enter another email.' });
        this.state.rowError.forEach((x, index) => {
            if (x === false) {
                this.clearRow(index);
            }
        });
        setTimeout(() => {
            this.setState({ errorMessage: null, rowError: new Array(10).fill(false), emailError: false, disableButtons: false });
        }, 5000);
    }

    successModal() {
        return (
            this.state.accounts.map((x, index) =>
                <div key={index}>
                    {x.lastName + ', ' + x.firstName + ' (' + x.city + '): '}
                    <span className={(this.state.addResponse[index] === 'Success') ? 'greenText' : 'redText'}>
                        {(this.state.addResponse[index] === 'Success') ? 'Success!' : ('Error: ' + this.state.addResponse[index])}
                    </span>
                </div>
            )
        );
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    renderModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Add User Status</MDBModalHeader>
                    <MDBModalBody>
                        {this.successModal()}
                    </MDBModalBody>
                    <MDBModalFooter>
                        {!this.state.emailError ?
                            <Link to='/accountmanagement'><button className='btn btn-primary updateButton' onClick={this.clearOffice}>Done</button></Link> :
                            <button className='btn btn-primary updateButton' onClick={this.clearSuccess}>Done</button>}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderAddList() {
        return (
            [...Array(9)].map((x, index) =>
                <div key={index + 1}>
                    <div className='form-row' style={{ marginBottom: '2px' }}>
                        <div className='form-group col-md-2'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    {/*Given Name*/}
                                    <input
                                        defaultValue={this.state.firstName[index + 1] ? this.state.firstName[index + 1] : ''}
                                        type='text'
                                        className={this.state.rowError[index + 1] ? `form-control addUserInput${index + 1}` : 'form-control'}
                                        onChange={(event) => this.onGivenNameChange(event, index + 1)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    {/*Surname*/}
                                    <input
                                        defaultValue={this.state.lastName[index + 1] ? this.state.lastName[index + 1] : ''}
                                        type='text'
                                        className={this.state.rowError[index + 1] ? `form-control addUserInput${index + 1}` : 'form-control'}
                                        onChange={(event) => this.onSurnameChange(event, index + 1)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    {/*Display Name*/}
                                    <div className='userContent'>
                                        {(this.state.firstName[index + 1] && this.state.lastName[index + 1] && (this.props.sharedReducer.selectedAddOffices[index + 1] ? this.props.sharedReducer.selectedAddOffices[index + 1].city : false)) ? (this.state.lastName[index + 1] + ', ' + this.state.firstName[index + 1] + ' (' + this.props.sharedReducer.selectedAddOffices[index + 1].city + ')') : ''}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    {/*Email*/}
                                    <input
                                        defaultValue={this.state.email[index + 1] ? this.state.email[index + 1] : ''}
                                        type='text'
                                        className={this.state.rowError[index + 1] ? `form-control addUserInput${index + 1}` : 'form-control'}
                                        onChange={(event) => this.onEmailChange(event, index + 1)}
                                    />
                                </div>
                                <div className={this.state.rowError[index + 1] ? `col-md-5 officeSelect${index + 1}` : 'col-md-5'}>
                                    {/*Office*/}
                                    <ProOffice accountCreateIndex={index + 1} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-4'>
                            <div className='row'>
                                <div className='col'>
                                    {/*City*/}
                                    <div className='userContent'>{this.props.sharedReducer.selectedAddOffices[index + 1] ? this.props.sharedReducer.selectedAddOffices[index + 1].city : null}</div>
                                </div>
                                <div className='col'>
                                    {/*Country*/}
                                    <div className='userContent'>{this.props.sharedReducer.selectedAddOffices[index + 1] ? this.props.sharedReducer.selectedAddOffices[index + 1].country : null}</div>
                                </div>
                                <div className='col'>
                                    {/*Region*/}
                                    <div className='userContent'>{this.props.sharedReducer.selectedAddOffices[index + 1] ? this.props.sharedReducer.selectedAddOffices[index + 1].region : null}</div>
                                </div>
                                <div className='col'>
                                    {/*Expires on*/}
                                    <div className='userContent'>{(this.state.firstName[index + 1] && this.state.lastName[index + 1] && this.state.email[index + 1] && this.props.sharedReducer.selectedAddOffices[index + 1]) ? this.formatDate() : ''}</div>
                                </div>
                                <div className='col-md-1'>
                                    <div className='clearAllIcon'><FontAwesomeIcon icon={faTrashAlt} size='lg' title='Clear Row' onClick={() => this.clearRow(index + 1)} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }

    renderMessage() {
        let userEmail = this.props.currentUser.emailAddress;
        if (userEmail.endsWith('@protiviti.com') || userEmail.endsWith('@roberthalf.com')) {
            this.setState({ message: "When adding internal Protiviti/Roberthalf/Rhi.com users, email address must be the user's network address (e.g. jonsmi01@protiviti.com)" });
        }
    }

    render() {
        return (
            <form onSubmit={this.addAccounts}>
                <div className='container accountCreateContainer'>
                    <div className='form-group'>
                        <div className='accountDetailsTitle'>
                            <h4>Add User</h4>
                            {this.state.message}
                        </div>
                        <hr className='accountDetailHr' />
                        <div className='form-row' style={{marginBottom: '2px'}}>
                            <div className='form-group col-md-2'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div><label>Given Name</label></div>
                                        <input
                                            defaultValue={this.state.firstName[0] ? this.state.firstName[0] : ''}
                                            type='text'
                                            className={this.state.rowError[0] ? 'form-control addUserInput0' : 'form-control'}
                                            onChange={(event) => this.onGivenNameChange(event, 0) }
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <div><label>Surname</label></div>
                                        <input
                                            defaultValue={this.state.lastName[0] ? this.state.lastName[0] : ''}
                                            type='text'
                                            className={this.state.rowError[0] ? 'form-control addUserInput0' : 'form-control'}
                                            onChange={(event) => this.onSurnameChange(event, 0)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-6'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div><label>Display Name</label></div>
                                        <div className='userContent'>
                                            {(this.state.firstName[0] && this.state.lastName[0] && (this.props.sharedReducer.selectedAddOffices[0] ? this.props.sharedReducer.selectedAddOffices[0].city : false)) ? (this.state.lastName[0] + ', ' + this.state.firstName[0] + ' (' + this.props.sharedReducer.selectedAddOffices[0].city + ')') : ''}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div><label>Email</label></div>
                                        <input
                                            defaultValue={this.state.email[0] ? this.state.email[0] : ''}
                                            type='text'
                                            className={this.state.rowError[0] ? 'form-control addUserInput0' : 'form-control'}
                                            onChange={(event) => this.onEmailChange(event, 0)}
                                        />
                                    </div>
                                    <div className='col-md-5'>
                                        <div><label>Office</label></div>
                                        <div className={this.state.rowError[0] ? 'officeSelect0' : null}>
                                            <ProOffice accountCreateIndex={0} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-4'>
                                <div className='row'>
                                    <div className='col'>
                                        <div><label>City</label></div>
                                        <div className='userContent'>{this.props.sharedReducer.selectedAddOffices[0] ? this.props.sharedReducer.selectedAddOffices[0].city : null}</div>
                                    </div>
                                    <div className='col'>
                                        <div><label>Country</label></div>
                                        <div className='userContent'>{this.props.sharedReducer.selectedAddOffices[0] ? this.props.sharedReducer.selectedAddOffices[0].country : null}</div>
                                    </div>
                                    <div className='col'>
                                        <div><label>Region</label></div>
                                        <div className='userContent'>{this.props.sharedReducer.selectedAddOffices[0] ? this.props.sharedReducer.selectedAddOffices[0].region : null}</div>
                                    </div>
                                    <div className='col'>
                                        <div><label>Expires On</label></div>
                                        <div className='userContent'>{(this.state.firstName[0] && this.state.lastName[0] && this.state.email[0] && this.props.sharedReducer.selectedAddOffices[0]) ? this.formatDate() : ''}</div>
                                    </div>
                                    <div className='col-md-1'>
                                        <br />
                                        <div className='clearAllIcon' style={{paddingTop: '5px'}}><FontAwesomeIcon icon={faTrashAlt} size='lg' title='Clear Row' onClick={() => this.clearRow(0)} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.renderAddList()}
                        <hr />
                        <div className='accountDetailsButton'>
                            <button type='button' className='btn btn-dark' onClick={this.clearAll} disabled={this.state.disableButtons}>Clear All</button>
                            <Link to='/'><button type='button' className='btn btn-secondary updateButton' onClick={this.props.clearAddOffices} disabled={this.state.disableButtons}>Cancel</button></Link>
                            <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Add</button>
                        </div>
                        <div className='errorMessage'>{this.state.errorMessage}</div>
                        {this.renderModal()}
                    </div>
                </div>
            </form>    
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        currentUser: getCurrentUser(state)
    }
}

export default connect(mapStateToProps, { clearAddOffices, clearAddOfficeRow })(AddAccount);