import React from 'react';
import { Link } from 'react-router-dom';

const HelpSection = (props) => {
    let passwordReset = props.accountType === "MF" ? <li>To change your MyLearning password, <Link to='/passwordreset'>click here</Link></li> : null
    return (
        <div className='row-container'>
            <div className='icon-row float-right'>
                <div className='need-help'>
                    <h5>Need Help?</h5>
                    <p><strong>Call Protiviti Tech Support</strong><br />
                        (866) 450-3020</p>
                    <p><strong>Email Protiviti Tech Support</strong><br />
                        Technical.Support@protiviti.com</p>
                </div>
            </div>
            <div className='icon-row'>
                <h5>Protiviti Resources for {props.given_name}</h5>
            </div>
            <div className='help-text'>
                Welcome! This page lists the sites for which you have been granted access or for which access is pending.<br />
                <ol className='help-steps'>
                    <li>Please click on "Agree to Terms" below to finalize your TEAMS site access, if needed.  After you Agree to Terms you will usually have access within one hour.  It may take up to 24 hours.</li>
                    <li>Access your TEAMS site by clicking on either the Site Name.</li>
                    <li>Your access expiration date is shown below.  Notify your Protiviti contact if you feel more time is required.</li>
                    {passwordReset}
                </ol>
            </div>
            <div className='password-reset'>

            </div>
        </div>
    )
}

export default HelpSection;