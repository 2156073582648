import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'mdbreact';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { importUsersData} from '../../actions';
import './AccountImport.css';
import * as XLSX from 'xlsx/xlsx.mjs';


class ImportUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileName: '',
            hasHeaders: true,         
            csvFile: null,
            modal: false, 
            error: false
        };
        this.handleHeader = this.handleHeader.bind(this)
        this.updateData = this.updateData.bind(this);
        this.handleUpload = this.handleUpload.bind(this)
    }
    valid = true  

    componentDidMount() {
        if (this.props.location.state) {
            if (this.props.location.state.redirectError) {
                this.setState({ error: true });
                this.props.location.state.redirectError = false;
                setTimeout(() => {
                    this.setState({ error: false });
                }, 5000);
            }
        }
    }

    componentDidUpdate() {
        //if (!this.props.userReducer.currentUser.isAdmin) {
        //    this.props.history.push('/');
        //}
    }
    handleCSV(file) {
        this.valid = false;
        const csvfile = file;
        
        var Papa = require("papaparse/papaparse.min.js");
        Papa.parse(csvfile, {
            header: this.state.hasHeaders,
            skipEmptyLines: true,
            complete: (results) => {
                this.updateData(results.data);
            }
        });
       
        
    }
    handleXLSX() {
       
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
           
            const csdemo = XLSX.utils.sheet_to_csv(ws, { FS: ",", RS: "\n", blankrows: false })
           
            this.handleCSV(csdemo)
            
           

        };
        if (rABS) {
            reader.readAsBinaryString(this.state.csvFile);
        } else {
            reader.readAsArrayBuffer(this.state.csvFile);
        };
    }

    
    handleUpload(event) {
        if (this.state.csvFile === null) {
            alert('Please Select a File to Upload')
        }
        else {
            const ext = this.state.fileName.split('.')
            if (ext[1] === 'csv')
            {

                this.handleCSV(this.state.csvFile)
              }
            else if (ext[1] === 'xlsx' || ext[1] === 'xls')
            {
                this.handleXLSX()
            }

            else
                alert('Please Select a Valid File')

        }

    }
   
    toggleModal() {
        this.setState({ modal: !this.state.modal })
}

    renderUpdateModal() {
       
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal()} backdrop={false}>
                    <MDBModalHeader>Import Multiple Users</MDBModalHeader>
                    <MDBModalBody>
                        Upload Successful!
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/importedit'> <MDBBtn color="primary">Continue</MDBBtn></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
            )
    }

    updateData = async (result) => {
        let error = false;
        let parsedData = [];
        let data = result;

        if (!this.state.hasHeaders) {
            data = result.map((x) => {
                return ({
                    'First (Given Name)': x[0],
                    'Last (Surname)': x[1],
                    'Office': x[2],
                    'Email Name': x[3],
                    '@': x[4],
                    'Email Domain': x[5]
                })
            });
        }

        for (let row of data) {
            if ((!row["First (Given Name)"] && !row["Last (Surname)"] && !row["Office"] && !row["Email Name"] && !row["Email Domain"])
                || !(row.hasOwnProperty("First (Given Name)") && row.hasOwnProperty("Last (Surname)") && row.hasOwnProperty("Office") && row.hasOwnProperty("Email Domain") && row.hasOwnProperty("@"))
                || row[0] === "")
                continue;
            else {
                parsedData.push(row)
            }

        }

        const payload = {
            data: parsedData,
            headers: this.state.hasHeaders
        }

        if (parsedData.length === 0) {
            error = true;
        }

        if (error) {
            this.setState({ error: true });
            setTimeout(() => {
                this.setState({ error: false });
            }, 5000);
        }
        else {
            this.props.importUsersData(payload);
            this.toggleModal();
        }
    }

    onChange = e => {

        switch (e.target.name) {

            case 'selectedFile':
                if (e.target.files.length > 0) {

                    this.setState({
                        fileName: e.target.files[0].name,
                        csvFile: e.target.files[0],

                    });

                }

                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    };
    handleHeader() {
        this.setState(prevstate => {
            return (this.state.hasHeaders = !prevstate.hasHeaders)
        })
    }

    render() {
        const SheetJSFT = [
            "xlsx",  "xls",  "csv"].map(function (x) { return "." + x; }).join(",");
        const { fileName } = this.state;
        let file = null;

        file = fileName
            ? (<span>{fileName}</span>)
            : (<span>Choose a file...</span>);



        return (
            <div className='container importContainer'>
                <div className='importGrid'>
                    <h4 className='importTitle'>Import Multiple Users</h4>
                    <div className="dwnldBtn">
                        <Link to="csv/MemberFirm_BulkUploadTemplate.xlsx" target="_blank" download>
                            <MDBBtn>
                                <FontAwesomeIcon icon={faArrowDown} sixe="lg" />
                                <div className="btnText">Download Template</div>
                            </MDBBtn>
                        </Link>
                    </div>
                   

                    <div className="uploadForm">
                        <div className="custom-control custom-checkbox my-1 mr-sm-2">
                            <input type="checkbox"
                                className="custom-control-input"
                                checked={this.state.hasHeaders}
                                onChange={this.handleHeader}
                                id="customControlInline" />
                            <label className="custom-control-label" htmlFor="customControlInline">Has Headers</label>
                        </div>
                        <div className="custom-file">
                            <input className="custom-file-input"
                                id="file"
                                type="file"
                                name="selectedFile"
                                onChange={(event) => this.onChange(event)}
                                accept={SheetJSFT} 
                            />
                            <label className="custom-file-label" htmlFor="file">{file}</label>
                        </div>
                    </div>

                    <div className='importButtons'>
                        <Link to='/'><button type='button' className='btn btn-secondary'>Cancel</button></Link>
                        <button className='btn btn-primary updateButton' onClick={(e) => this.handleUpload(e)}>Upload</button>
                    </div>
                    {this.state.error ? <div className='errorMessage' style={{ marginTop: '20px' }}>Error: Invalid file. Please check your file and try again.</div> : null}
                    {this.renderUpdateModal()}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}


export default connect(mapStateToProps, { importUsersData})(ImportUsers);