import React from 'react';
import './RequestFilter.css';
import { connect } from 'react-redux';
import { getLegalApprovalStatusFilter } from '../../actions';

class ApprovalFilter extends React.Component {
    state = {
        lany: true,
        lpending: true,
        lapproved: true,
        lsingle: true,
        lnr: true,
        lrejected: true
    }

    onChange = (event) => {
        if (event.target.value === 'lany') {
            if (!this.state.lany) {
                this.setState({ lany: true, lpending: true, lapproved: true, lsingle: true, lnr: true, lrejected: true }, () => {
                    let filter = {
                        lany: this.state.lany,
                        lpending: this.state.lpending,
                        lapproved: this.state.lapproved,
                        lsingle: this.state.lsingle,
                        lnr: this.state.lnr,
                        lrejected: this.state.lrejected
                    }
                    this.props.getLegalApprovalStatusFilter(filter);
                });
            }
            else {
                this.setState({ lany: false, lpending: false, lapproved: false, lsingle: false, lnr:false, lrejected: false }, () => {
                    let filter = {
                        lany: this.state.lany,
                        lpending: this.state.lpending,
                        lapproved: this.state.lapproved,
                        lsingle: this.state.lsingle,
                        lnr: this.state.lnr,
                        lrejected: this.state.lrejected
                    }
                    this.props.getLegalApprovalStatusFilter(filter);
                });
            }
        }

        if (event.target.value === 'lpending' || event.target.value === 'lapproved' || event.target.value === 'lsingle' || event.target.value === 'lnr' || event.target.value === 'lrejected') {
            let status = ((event.target.value === 'lpending') ? !this.state.lpending : ((event.target.value === 'lapproved') ? !this.state.lapproved : ((event.target.value === 'lsingle') ? !this.state.lsingle : ((event.target.value === 'lnr') ? !this.state.lnr : !this.state.lrejected))));
            this.setState({ [event.target.value]: status }, () => {
                if (this.state.lany) {
                    this.setState({ lany: false }, () => {
                        let filter = {
                            lany: this.state.lany,
                            lpending: this.state.lpending,
                            lapproved: this.state.lapproved,
                            lsingle: this.state.lsingle,
                            lnr: this.state.lnr,
                            lrejected: this.state.lrejected
                        }
                        this.props.getLegalApprovalStatusFilter(filter);
                    });
                }
                else if (this.state.lpending && this.state.lapproved && this.state.lsingle && this.state.lnr && this.state.lrejected) {
                    this.setState({ lany: true }, () => {
                        let filter = {
                            lany: this.state.lany,
                            lpending: this.state.lpending,
                            lapproved: this.state.lapproved,
                            lsingle: this.state.lsingle,
                            lnr: this.state.lnr,
                            lrejected: this.state.lrejected
                        }
                        this.props.getLegalApprovalStatusFilter(filter);
                    });
                }
                else {
                    let filter = {
                        lany: this.state.lany,
                        lpending: this.state.lpending,
                        lapproved: this.state.lapproved,
                        lsingle: this.state.lsingle,
                        lnr: this.state.lnr,
                        lrejected: this.state.lrejected
                    }
                    this.props.getLegalApprovalStatusFilter(filter);
                }
            });
        }

    }

    render() {
        return (
            <div className='filter'>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='lany' value='lany' checked={this.state.lany} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='lany'>Any</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='lpending' value='lpending' checked={this.state.lpending} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='lpending'>Pending</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='lapproved' value='lapproved' checked={this.state.lapproved} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='lapproved'>Approved</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='lsingle' value='lsingle' checked={this.state.lsingle} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='lsingle'>Single</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='lnr' value='lnr' checked={this.state.lnr} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='lnr'>Not Required</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='lrejected' value='lrejected' checked={this.state.lrejected} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='lrejected'>Rejected</label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { getLegalApprovalStatusFilter })(ApprovalFilter);