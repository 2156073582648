import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import config from '../../config';
import { getUsername } from '../../services/authService';
import { getCurrentUser } from '../../actions';
import { apiGet, apiPut } from '../../services/apiService';

class TeamsExtensionApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            request: [],
            requestKey: null,
            requestType: null,
            noticeModal: false,
            modalTitle: null,
            modalBody: null,
            submitButton: false
        }
    }
    currentUser;

    async componentDidMount() {
        this.currentUser = await getUsername();
        this.props.getCurrentUser(this.currentUser.username);
        this.validateUser();
    }

    validateUser = async () => {
        const requestKey = this.props.match.params.id
        let url = config.azRedirectUri + '/api/TeamsExtension/extension/' + requestKey;
        await apiGet(url)
            .then(response => {
                let data = response.data;
                let requestType = null;
                if (data.requestId === null) {
                    this.setState({ modalTitle: 'TEAMS Extension Approval', modalBody: 'The extension request you are trying to reach has already been completed, and no further action is required.  You can close your browser window' }, () => this.toggleModal());
                }
                if ((data.mdEmail !== this.currentUser.username && !this.props.userReducer.currentUser.isAdmin) || !requestKey) {
                    <Redirect to='/error' />;
                }
                if (data.emd !== null) {
                    requestType = 'client'
                }
                this.setState({ request: data, requestKey: requestKey, requestType: requestType });
            })
            .catch((error) => {
                console.log(error);
                <Redirect to='/error' />;
            });
    }

    handleUpdate = async (e) => {
        this.setState({ submitButton: true });
        let expireRequest = {
            action: e.target.value,
            newExpireDate: this.state.request.newExpireDate
        };

        let url = config.azRedirectUri + '/api/TeamsExtension/' + this.state.requestKey;
        await apiPut(url, expireRequest)
            .then(response => {
                this.setState({ modalTitle: 'Update Successful', modalBody: 'This confirms that you ' + expireRequest.action + ' the TEAMS extension.  The requester will receive notification of your selection.  You may close this browser.' }, () => this.toggleModal());
            })
            .catch((error) => {
                console.log(error);
                this.setState({ modalTitle: 'Update Failed', modalBody: 'There was an error while trying to updating your selection.  Please close the browser and try again. If the problem persists, please contact Protiviti Tech Support' }, () => this.toggleModal());
            });
    }

    toggleModal = () => {
        this.setState({ noticeModal: !this.state.noticeModal });
    }

    generateModal = () => {
        return (
            <div>
                <MDBContainer>
                    <MDBModal isOpen={this.state.noticeModal} toggle={this.toggleSuccessModal} backdrop={false}>
                        <MDBModalHeader toggle={this.toggle}>{this.state.modalTitle}</MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group col-md-12">
                                {this.state.modalBody}
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                </MDBContainer>
            </div>
        )
    }

    render() {

        return (
            <div className='div-wrapper'>
                <h2>TEAMS Extension Approval</h2>
                <p>{this.state.request.requesterName} has requested an extension until {this.state.request.newExpireDate} for the guests listed below to access the TEAMS site.  Please approve or reject this request below.</p>

                <strong>Request ID: </strong> {this.state.request.requestId}<br />
                <strong>TEAMS Site Name: </strong> {this.state.request.teamsName}<br />
                <strong>Original Expiration Date: </strong> {this.state.request.originalExpireDate}<br />
                <strong>Requested Expiration Date: </strong> {this.state.request.newExpireDate}<br />
                {this.state.requestType === "client" &&
                    <div>
                        <strong>Client: </strong> {this.state.request.clientName}<br />
                        <strong>EMD: </strong> {this.state.request.emd}<br />
                        <strong>Engagement Sensitivity: </strong> {this.state.request.sensitivity}<br />
                        <strong>External Guest Users: </strong> {this.state.request.guestUsers}<br /><br />
                    </div>
                }

                <strong>Original Explanation: </strong> {this.state.request.businessJustification}<br /><br />

                {this.state.request.acknowledgementText}

                <br /><br />
                <button type="button" className="btn btn-primary my-1 bottom-button" type="button" onClick={e => this.handleUpdate(e, "value")} value="approve" disabled={this.state.submitButton}>Approve</button>
                <button type="button" className="btn btn-danger my-1 bottom-button" type="button" onClick={e => this.handleUpdate(e, "value")} value="reject" disabled={this.state.submitButton}>Reject</button>
                {this.generateModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(TeamsExtensionApproval)