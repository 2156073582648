import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import { connect } from 'react-redux';
import { getAccounts, getFilterStatus, showExpandAlert } from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import DataTable, { defaultThemes } from 'react-data-table-component';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './AccountGrid.css';
import { fetchAADGroupsByEmail } from "../../services/graphApiService";
import { apiGet, apiPut } from '../../services/apiService';
import DetailModal from '../Teams/DetailModal';

class component extends Component {
    disp = [];
    active = [];
    disabled = [];
    expired = [];
    pending = [];
    data = [];

    accountStatusValue = {
        0: 'Active',
        1: 'Expired',
        2: 'Disabled',
        3: 'Locked',
        4: 'Pending'
    };

    constructor(props) {
        super(props);
        this.state = {
            allAccounts: [],
            allAccountsDupe: [],
            allAccountsResponse: [],
            selectedOfficeName: 'My Offices',
            modal: false,
            errorModal: false,
            action: null,
            modalAccount: {},
            accountStatusFilter: {
                any: false,
                active: true,
                expired: true,
                pending: true,
                disabled: false
            },
            //isReload: false,
            extendedAccount: null,
            totalRows: 0,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortOrder: "desc",
            filterText: "",
            dataLoading: true,
            action: "",
            adGroups: null,
            detailModal: false,
            detailModalAction: null,
            modalData: null,
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchDataByUser();
    }

    componentDidUpdate() {
        if (this.state.selectedOfficeName !== this.props.sharedReducer.selectedOffice.name) {
            this.setState({ selectedOfficeName: this.props.sharedReducer.selectedOffice.name });
            if (this.props.sharedReducer.selectedOffice.key) {
                this.fetchDataByOffice(this.props.sharedReducer.selectedOffice.key);
            }
            else {
                this.mounted = true;
                this.fetchDataByUser();
            }
        }
        if ((this.state.accountStatusFilter.any !== this.props.sharedReducer.accountStatusFilter.any) ||
            (this.state.accountStatusFilter.active !== this.props.sharedReducer.accountStatusFilter.active) ||
            (this.state.accountStatusFilter.expired !== this.props.sharedReducer.accountStatusFilter.expired) ||
            (this.state.accountStatusFilter.disabled !== this.props.sharedReducer.accountStatusFilter.disabled) ||
            (this.state.accountStatusFilter.pending !== this.props.sharedReducer.accountStatusFilter.pending)) {
            this.setStatusFilter();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStatusFilter = async () => {
        let filteredAccounts = this.state.allAccountsResponse;
        this.setState({ accountStatusFilter: this.props.sharedReducer.accountStatusFilter }, async () => {
            if (this.state.accountStatusFilter.pending === false) {
                filteredAccounts = filteredAccounts.filter(x => this.accountStatusValue[x.accountStatus] !== 'Pending');
            }
            if (this.state.accountStatusFilter.active === false) {
                filteredAccounts = filteredAccounts.filter(x => this.accountStatusValue[x.accountStatus] !== 'Active');
            }
            if (this.state.accountStatusFilter.disabled === false) {
                filteredAccounts = filteredAccounts.filter(x => this.accountStatusValue[x.accountStatus] !== 'Disabled');
            }
            if (this.state.accountStatusFilter.expired === false) {
                filteredAccounts = filteredAccounts.filter(x => this.accountStatusValue[x.accountStatus] !== 'Expired');
            }
            if (this.state.accountStatusFilter.rany) {
                filteredAccounts = this.state.allAccountsResponse;
            }

            let accounts = this.assembleAccounts(filteredAccounts).map((x, index) => ({ ...x, action: this.renderActions(filteredAccounts[index]) }));
            this.setState({ allAccounts: accounts, allAccountsDupe: accounts });
        });
    }

    fetchDataByUser = async () => {
        let url = config.azRedirectUri + '/api/Accounts';
        this.fetchData(url);
    }

    fetchDataByOffice = async (officeKey) => {
        const url = config.azRedirectUri + '/api/Accounts/office/' + officeKey;
        this.fetchData(url);
    }

    fetchData = async (url) => {
        await apiGet(url)
            .then((response) => {
                if (this.mounted) {
                    let sortedResponse = response.data;
                    //this.setState({ isReload: false });
                    let accounts = this.assembleAccounts(sortedResponse).map((x, index) => ({ ...x, action: this.renderActions(sortedResponse[index]) }));
                    this.setState({ allAccounts: accounts, allAccountsDupe: accounts, allAccountsResponse: sortedResponse, totalRows: response.data.count, dataLoading: false }, () => {
                        this.setStatusFilter();
                    });
                    this.props.getAccounts(sortedResponse);
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    //this.setState({ isReload: true });
                    this.fetchDataByUser();
                }
                console.log(error);
            })
    }

    getAdInfo = async (account) => {
        await fetchAADGroupsByEmail(account.loginAddress)
        .then((responseJson) => {
          let value = responseJson.value;
          if (value !== undefined && value.length > 0) {
            // AD info
            let modalData = {
                account: account,
                adGroups: value
                .map((x) => x.displayName)
                .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)),
            };
            this.toggleDetailModal(modalData, "adinfo");
          } else if (responseJson?.error.code === "Request_ResourceNotFound") {
            // no AD info
            let modalData = {
                account: account,
                adGroups: null,
            };
            this.toggleDetailModal(modalData, "adinfo");
          } else {
            // some unexpected exception
            console.error(responseJson?.error);
            this.toggleDetailModal(null, "error");
          }
        })
        .catch(() =>{
            this.toggleDetailModal(null, "error");
        });
      };
    
    assembleAccounts = (response) => {
        let allAccounts = response.map((x) => {
            return ({
                'displayName': x.displayName,
                'username': x.username,
                'emailAddress': x.emailAddress,
                //'loginAddress': x.loginAddress,
                'office': x.office,
                'country': x.country,
                'accountStatus': this.accountStatusValue[x.accountStatus],
                'expirationDate': this.showExpired(x)
            })
        });
        return allAccounts;
    }

    handlePageChange = page => {
        //this.fetchDataByUser(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        //this.fetchDataByUser(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    //handleSortChange = (c, d) => {
    //    this.setState({ sortBy: c.selector, sortOrder: d }, () => {
    //        this.fetchDelegates(this.state.currentPage);
    //    });
    //}

    handleFilterChange = (e) => {
        let accounts = this.state.allAccountsDupe;
        let filter = e.target.value.toLowerCase();
        if (filter !== "") {
            accounts = accounts.filter(x => x.displayName.toLowerCase().includes(filter) || x.username.toLowerCase().includes(filter) || x.emailAddress.toLowerCase().includes(filter) || x.office.toLowerCase().includes(filter) || x.country.toLowerCase().includes(filter) || x.accountStatus.toLowerCase().includes(filter) || x.expirationDate.toString().includes(filter));
        }
        
        this.setState({ filterText: e.target.value, allAccounts: accounts });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "", allAccounts: this.state.allAccountsDupe });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    showExpired = (x) => {
        let date = x.expirationDate ? x.expirationDate.substring(0, 10) : x.expirationDate;
        let dateSplit = date.split('-');
        let dateFormat = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
        let dateDiff = this.compareDates(dateFormat);
        if (dateDiff >= -14 && dateDiff <= 0 && x.accountStatus === 0) {
            return (
                <div>
                    <div>{date}</div>
                    <button type='button' className='btn btn-secondary extend-btn' onClick={() => this.extendButton(x)}>Extend</button>
                </div>
            );
        }
        else {
            return date;
        }
    }

    compareDates = (date) => {
        let dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 14);
        return (date.getTime() - dateNow.getTime()) / (1000 * 60 * 60 * 24);
    }

    toggleModal = (account, action) => {
        this.setState({
            modal: !this.state.modal,
            modalAction: action,
            modalAccount: account
        });
    }

    toggleErrorModal = () => {
        this.setState({
            errorModal: !this.state.errorModal
        });
    }

    toggleDetailModal = (modalData, action) => {
        this.setState({
            detailModal: !this.state.detailModal,
            detailModalAction: action,
            modalData: modalData,
        })
    }

    extendButton = (account) => {
        this.setState({ modalAccount: account, extendedAccount: account.displayName }, () => {
            this.extendAccount(true);
        });
    }

    openModal() {
        let fullName = this.state.modalAccount !== null ? this.state.modalAccount.firstName + ' ' + this.state.modalAccount.lastName : null;
        let title;
        let body;
        let footer;
        switch (this.state.modalAction) {
            case 'disable':
                title = 'Disable Account: ' + fullName;
                body = 'Are you sure you want to disable this Account?';
                footer = '<MDBBtn color="primary" onClick={this.activateDisableAccount}>Yes</MDBBtn>';
                break;
            case 'activate':
                title = 'Activate Disabled Account: ' + fullName;
                body = 'Are you sure you want to activate this Account?';
                break;
            case 'extend':
                title = 'Extend Account: ' + fullName;
                body = 'Are you sure you want to extend the expiration date of this Account to 90 days from today?';
                break;
            case 'email':
                title = 'Resend Welcome E-mail to ' + fullName;
                body = 'Are you sure you want to resend a welcome E-mail?';
                break;
            case 'msEmail':
                title = 'Resend Microsoft Welcome E-mail to ' + fullName;
                body = 'Are you sure you want to resend a Microsoft welcome E-mail?';
                break;
            case 'resetMFA':
                title = 'Reset MFA Authentication: ' + fullName;
                body = 'You are about to reset MFA authentication for ' + fullName + '.  This action cannot be undone.  Choosing "Yes" will force the guest user to reconfigure their MFA authentication.  Are you sure you want to do this?';
                break;
            case 'password':
                title = 'Reset MyLearning Password: ' + fullName;
                body = 'Are you sure you want to reset MyLearning Password?';
                break;
            case 'createPercipio':
                title = 'Create Percipio Account for: ' + fullName;
                body = 'Are you sure you want to create Percipio account?';
                break;
            default:
                title = 'Error';
                body = 'An Error occured';
                break;
        }
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal(null)}>
                    <MDBModalHeader toggle={() => this.toggleModal(null)}>
                        {title}
                    </MDBModalHeader>
                    <MDBModalBody>
                        {body}
                    </MDBModalBody>
                    <MDBModalFooter>
                    {(this.state.modalAction === "error") ? (
                        <MDBBtn color="primary" onClick={() => this.toggleModal(null)}>
                            Close
                        </MDBBtn>
                    ) : (
                        <MDBBtn color="secondary" onClick={() => this.toggleModal(null)}>
                            No
                        </MDBBtn>
                    )}
                        {(this.state.modalAction === 'disable' || this.state.modalAction === 'activate') ? <MDBBtn color="primary" onClick={this.activateDisableAccount}>Yes</MDBBtn> :
                            ((this.state.modalAction === 'extend') ? <MDBBtn color="primary" onClick={() => this.extendAccount(false)}>Yes</MDBBtn> :
                                ((this.state.modalAction === 'email' || this.state.modalAction === 'msEmail' || this.state.modalAction === 'resetMFA' || this.state.modalAction === 'password' || this.state.modalAction === 'createPercipio') ? <MDBBtn color="primary" onClick={this.handleAction}>Yes</MDBBtn> : null))}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderErrorModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.errorModal} toggle={this.toggleErrorModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Submission Error</MDBModalHeader>
                    <MDBModalBody>
                        There was an error submitting your request. Please try again or contact support if the error continues.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' onClick={this.toggleErrorModal}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    activateDisableAccount = () => {
        let link = this.state.modalAction === 'disable' ? '/api/Accounts/Disable' : '/api/Accounts/ReActivate';
        const body = '"' + this.state.modalAccount.key + '"';
        apiPut(link, body)
            .then((response) => {
                //this.setState({ isReload: false });
                let newAccounts = this.state.allAccountsResponse;
                let newAccountIndex = newAccounts.findIndex(x => {
                    return x.key === this.state.modalAccount.key
                });
                newAccounts[newAccountIndex].accountStatus = response.data.accountStatus;
                let sortedResponse = newAccounts.sort((a, b) => {
                    return new Date(b.expirationDate) - new Date(a.expirationDate);
                }).reverse();
                let accounts = this.assembleAccounts(sortedResponse).map((x, index) => ({ ...x, action: this.renderActions(sortedResponse[index]) }));
                this.setState({ allAccounts: accounts, allAccountsDupe: accounts }, () => {
                    this.setStatusFilter();
                });
                this.toggleModal(null, null);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    //this.setState({ isReload: true });
                    this.activateDisableAccount();
                }
                console.log(error);
            })
    }

    extendAccount = (fromButton) => {
        const url = '/api/Accounts/Extend';
        const body = '"' + this.state.modalAccount.key + '"';
        apiPut(url, body)
            .then((response) => {
                //this.setState({ isReload: false });
                let newAccounts = this.state.allAccountsResponse;
                let newAccountIndex = this.state.allAccountsResponse.findIndex(x => {
                    return x.key === this.state.modalAccount.key
                });
                newAccounts[newAccountIndex].expirationDate = response.data.expirationDate;
                newAccounts[newAccountIndex].accountStatus = response.data.accountStatus;
                let sortedResponse = newAccounts.sort((a, b) => {
                    return new Date(b.expirationDate) - new Date(a.expirationDate);
                }).reverse();
                let accounts = this.assembleAccounts(sortedResponse).map((x, index) => ({ ...x, action: this.renderActions(sortedResponse[index]) }));
                this.setState({ allAccounts: accounts, allAccountsDupe: accounts }, () => {
                    this.setStatusFilter();
                });
                if (fromButton) {
                    this.props.showExpandAlert(this.state.extendedAccount);
                    setTimeout(() => {
                        this.props.showExpandAlert(null);
                    }, 3000);
                }
                else {
                    this.toggleModal(null, null);
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    //this.setState({ isReload: true });
                    this.extendAccount(fromButton);
                }
                console.log(error);
            })
    }

    handleAction = () => {
        let link;
        switch (this.state.modalAction) {
            case 'email':
                link = '/api/Accounts/ResendWelcomeEmail';
                break;
            case 'msEmail':
                link = '/api/Accounts/ResendMicrosoftEmail';
                break;
            case 'resetMFA':
                link = '/api/Accounts/ResetMFAAuthentication';
                break;
            case 'password':
                link = '/api/Accounts/ResetPassword';
                break;
            case 'createPercipio':
                link = '/api/Accounts/CreatePercipio';
                break;
            default:
        }
        const body = '"' + this.state.modalAccount.key + '"';
        apiPut(link, body)
            .then(() => {
                console.log('Action: ' + this.state.modalAction + ' successful');
                this.toggleModal(null, null);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.resendEmail();
                }
                this.toggleModal(null, null);
                this.toggleErrorModal();
                console.log(error);
            })
    }

    renderActions(account) {
        let resendMSEmail;
        let resetMFA;
        let createPercipio;
        let azureAdInfo;
        let email = this.props.userReducer.currentUser.emailAddress
        if (!account.emailAddress.endsWith('@roberthalf.com') && !account.emailAddress.endsWith('@protiviti.com')) {
            if ((email.endsWith('@protiviti.com') || email.endsWith('@protiviti.co.in') || email.endsWith('@roberthalf.com')) && this.props.userReducer.currentUser.isAdmin) {
                if (!account.acceptedMSInvite) {
                    resendMSEmail = <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'msEmail')}><MDBDropdownItem>Resend Microsoft E-mail</MDBDropdownItem></span>;
                }
                resetMFA = <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'resetMFA')}><MDBDropdownItem>Reset MFA Authentication</MDBDropdownItem></span>;
                if (!account.percipioUUID) {
                    createPercipio = <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'createPercipio')}><MDBDropdownItem>Create Percipio Account</MDBDropdownItem></span>;
                }
            }
        }
        if (this.props.userReducer.currentUser.isAdmin) {
            azureAdInfo = (
              <span
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => this.getAdInfo(account)}
              >
                <MDBDropdownItem>Azure AD Info</MDBDropdownItem>
              </span>
            );
        }      

        return (
            <div className='dropdownAction'>
                <MDBDropdown dropright>
                    <MDBDropdownToggle color="none">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <Link to={{ pathname: '/accountdetails', state: { accountInfo: account, isAdmin: this.props.userReducer.currentUser.isAdmin } }} style={{ color: 'black', textDecoration: 'none' }}><MDBDropdownItem>Edit</MDBDropdownItem></Link>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'disable')}><MDBDropdownItem>Disable Account</MDBDropdownItem></span>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'activate')}><MDBDropdownItem>Activate Disabled Account</MDBDropdownItem></span>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'extend')}><MDBDropdownItem>Extend Account</MDBDropdownItem></span>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'email')}><MDBDropdownItem>Resend Welcome E-mail</MDBDropdownItem></span>
                        {resendMSEmail}
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(account, 'password')}><MDBDropdownItem>Reset MyLearning Password</MDBDropdownItem></span>
                        {resetMFA}
                        {createPercipio}
                        {azureAdInfo}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        );
    }

    render() {
        const columns = [{ name: 'Display Name', selector: row => row.displayName, sortable: true, wrap: true },
            { name: 'Username', selector: row => row.username, sortable: true, maxWidth: '140px' },
            { name: 'Email', selector: row => row.emailAddress, sortable: true },
            { name: 'Office', selector: row => row.office, sortable: true, wrap: true },
            { name: 'Country', selector: row => row.country, sortable: true, maxWidth: '160px', wrap: true },
            { name: 'Status', selector: row => row.accountStatus, sortable: true, maxWidth: '110px' },
            { name: 'Expires On', selector: row => row.expirationDate, sortable: true, maxWidth: '140px' },
            { name: 'Edit', cell: row => row.action, allowOverflow: true, button: true }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',

                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };

        return (
            <div>
                <DataTable
                    columns={columns}
                    data={this.state.allAccounts}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    subHeader
                    //sortServer
                    progressPending={this.state.dataLoading}
                    //onSort={this.handleSortChange}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    paginationTotalRows={this.state.totalRows}
                    paginationPerPage={this.state.perPage}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={{ noRowsPerPage: false }}
                />
                {this.openModal()}
                {this.renderErrorModal()}
                <DetailModal isOpen={this.state.detailModal} toggleModal={this.toggleDetailModal} modalAction={this.state.detailModalAction} modalData={this.state.modalData} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getAccounts, getFilterStatus, showExpandAlert })(component);