import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchOffice, fetchCompany } from './actions';
import IdleTimer from "react-idle-timer";
import './App.css';
import Home from './pages/Home';
import NotFoundPage from './pages/Error';
import AppContainer from './components/AppContainer';
import Header from './components/Shared/Header';
import Footer from './components/Shared/Footer';
import AccountManagement from './pages/AccountManagement/AccountManagement';
import AccountDetails from './pages/AccountManagement/AccountDetails';
import AccountCreate from './pages/AccountManagement/AccountCreate';
import AccountBulkExtend from './pages/AccountManagement/BulkExtend';
import AccountImport from './pages/AccountImportPages/AccountImport';
import ManageOffices from './pages/ManageOffices/ManageOffices';
import AddOffice from './pages/ManageOffices/AddOffice';
import EditOffice from './pages/ManageOffices/EditOffice';
import ManageDelegates from './pages/ManageDelegates/ManageDelegates';
import AddDelegate from './pages/ManageDelegates/AddDelegate';
import EditDelegate from './pages/ManageDelegates/EditDelegate';
import ImportEdit from './pages/AccountImportPages/AccountImportEdit';
import SystemConfiguration from './pages/SystemConfiguration/SystemConfiguration';
import EmailWhitelist from './pages/SystemConfiguration/EmailWhitelist';
import EmailBlacklist from './pages/SystemConfiguration/EmailBlacklist';
import AuditLogs from './pages/SystemConfiguration/AuditLogs';
import EditEmail from './pages/SystemConfiguration/EditEmail';
import AddEmail from './pages/SystemConfiguration/AddEmail';
import ClientTeamsRequests from './pages/Teams/ClientTeamsRequests';
import ClientAccessRequest from './pages/Teams/ClientAccessRequest';
import ClientTeamsRequestDetail from './pages/Teams/ClientTeamsRequestDetail'
import ClientGuestAccounts from './pages/Teams/ClientGuestAccounts'
import AssignTeams from './pages/Teams/AssignTeams';
import HelpPage from './pages/Teams/AssignTeamsHelp';
import AcceptTerms from './pages/Teams/AcceptTerms';
import TeamsRequests from './pages/Teams/TeamsRequests';
import LegalApproval from './pages/Teams/LegalApproval';
import TeamRequestDetail from './pages/Teams/TeamRequestDetail';
import TeamRequestEdit from './pages/Teams/TeamRequestEdit';
import TeamsExtensionApproval from './pages/Teams/TeamsExtensionApproval';
import ManageCompanies from './pages/ManageCompanies/ManageCompanies';
import EditCompany from './pages/ManageCompanies/EditCompany';
import AddCompany from './pages/ManageCompanies/AddCompany';
import PasswordReset from './pages/PasswordReset/ResetPassword';
import ResetConfirmation from './pages/PasswordReset/ResetConfirmation';
import GuestResource from './pages/GuestResource';

// MSAL Import
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
//import { getUsername } from './services/authService';
import { getCurrentUser } from './actions';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: 1000 * 15 * 60
        }
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
    }

    _onAction(e) {
        this.setState({ isTimedOut: false });
    }

    _onActive(e) {
        this.setState({ isTimedOut: false });
    }

    _onIdle(e) {
        if (this.idleTimer.getTotalIdleTime() >= this.state.timeout) {
            this.props.instance.logout();
        } else {
            console.log("reset timer");
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }
    }

    millisToMinutes(ms) {
        const date = new Date(ms)

        return date;
    }

    componentDidMount() {
        let account = this.props.instance.getActiveAccount();
        this.props.getCurrentUser(account.username);
        this.fetchData()
    }

    fetchData = async () => {
        await this.props.fetchOffice();
        await this.props.fetchCompany();
    }

    render() {
        var request = {
            scopes: ['User.Read']
        }
        return (
            <MsalProvider instance={this.props.instance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={request}>
                    <div>
                        <IdleTimer
                            ref={ref => {
                                this.idleTimer = ref;
                            }}
                            element={document}
                            onActive={this.onActive}
                            onIdle={this.onIdle}
                            onAction={this.onAction}
                            debounce={250}
                            timeout={this.state.timeout}
                        />
                        <BrowserRouter>
                            <AppContainer>
                                <Header />
                                <div className='appContent'>
                                    <Route path='/' exact component={Home} />
                                    <Route path='/GuestResource' component={GuestResource} />
                                    {/* Accounts route */}
                                    <Route path='/accountmanagement' component={AccountManagement} />
                                    <Route path='/accountdetails' component={AccountDetails} />
                                    <Route path='/accountcreate' component={AccountCreate} />
                                    <Route path='/accountimport' component={AccountImport} />
                                    <Route path='/bulkextend' component={AccountBulkExtend} />
                                    {/* Office route */}
                                    <Route path='/offices' component={ManageOffices} />
                                    <Route path='/addoffice' component={AddOffice} />
                                    <Route path='/editoffice' component={EditOffice} />
                                    {/* Company route */}
                                    <Route path='/companies' component={ManageCompanies} />
                                    <Route path='/addcompany' component={AddCompany} />
                                    <Route path='/editcompany' component={EditCompany} />
                                    <Route path='/importedit' component={ImportEdit} />
                                    {/* Delegates route */}
                                    <Route path='/delegates' component={ManageDelegates} />
                                    <Route path='/adddelegate' component={AddDelegate} />
                                    <Route path='/editdelegate' component={EditDelegate} />
                                    {/* System configuration route */}
                                    <Route path='/systemconfig' component={SystemConfiguration} />
                                    <Route path='/systemconfig/emailwhitelist' component={EmailWhitelist} />
                                    <Route path='/systemconfig/emailblacklist' component={EmailBlacklist} />
                                    <Route path='/systemconfig/auditlogs' component={AuditLogs} />
                                    <Route path='/systemconfig/editemail' component={EditEmail} />
                                    <Route path='/systemconfig/addemail' component={AddEmail} />
                                    {/* Teams route */}
                                    <Route path='/teamsRequests' component={TeamsRequests} />
                                    <Route path='/assignteams' component={AssignTeams} />
                                    <Route path='/clientteamsrequests' component={ClientTeamsRequests} />
                                    <Route path='/clientaccessrequest' component={ClientAccessRequest} />
                                    <Route path='/clientteamsrequestdetail' component={ClientTeamsRequestDetail} />
                                    <Route path='/clientguestaccounts' component={ClientGuestAccounts} />
                                    <Route path='/legalapproval/:id' component={LegalApproval} />
                                    <Route path='/assignteamshelp' component={HelpPage} />
                                    <Route path='/acceptterms' component={AcceptTerms} />
                                    <Route path='/teamrequestdetail/:id' component={TeamRequestDetail} />
                                    <Route path='/teamrequestedit/:id' component={TeamRequestEdit} />
                                    <Route path='/teamsextensionapproval/:id' component={TeamsExtensionApproval} />
                                    {/* Password reset route */}
                                    <Route path='/passwordreset' component={PasswordReset} />
                                    <Route path='/resetconfirmation' component={ResetConfirmation} />
                                    {/* MISC route */}
                                    <Route path='/error' component={NotFoundPage} />
                                </div>
                                <div className='appFooter'>
                                    <Footer />
                                </div>
                            </AppContainer>
                        </BrowserRouter>
                    </div>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser, fetchOffice, fetchCompany })(App);

