import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faUndo, faPlus } from '@fortawesome/free-solid-svg-icons'
import DataTable, { defaultThemes } from 'react-data-table-component';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { connect } from 'react-redux';
import './EmailWhitelist.css'
import { apiGet, apiPut } from '../../services/apiService';

class EmailWhitelist extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailWhitelist: [],
            emailOptions: [],
            actionModal: false,
            deleteRestoreEmail: {},
            totalRows: 0,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortOrder: "desc",
            filterText: "",
            dataLoading: true,
            action: ""
        }

    }
    componentDidMount = async () => {
        await this.fetchEmailWhitelist(1);
    }

    fetchEmailWhitelist = async () => {
        let url = '/api/EMailWhitelist';
        await apiGet(url)
            .then((response) => {
                let whiteList = response.data;
                let emails = this.assembleEmails(response.data);
                this.setState({ emailOptions: emails, emailWhitelist: emails, totalRows: response.data.count, dataLoading: false });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    assembleEmails = (email) => {
        let allEmails = email.map((x) => {
            return ({
                'key': x.key,
                'name': x.name,
                'createdByName': x.createdByName,
                'isDeleted': x.isDeleted ? "No" : "Yes"
            })
        });
        return allEmails;
    }

    handlePageChange = page => {
        //this.fetchEmailWhitelist(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        //this.fetchEmailWhitelist(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    //handleSortChange = (c, d) => {
    //    this.setState({ sortBy: c.sortField, sortOrder: d }, () => {
    //        this.fetchEmailWhitelist(this.state.currentPage);
    //    });
    //}

    handleFilterChange = (e) => {
        let emailOptions = this.state.emailWhitelist;
        let filter = e.target.value.toLowerCase();
        console.log(filter)
        if (filter !== "") {
            emailOptions = emailOptions.filter(x => x.name.toLowerCase().includes(filter) || x.createdByName.toLowerCase().includes(filter) || x.isDeleted.toLowerCase().includes(filter));
        }

        this.setState({ filterText: e.target.value, emailOptions: emailOptions });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "", emailOptions: this.state.emailWhitelist });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    renderActions(email) {
        let editAction = email.isDeleted === "Yes" ? <Link to={{ pathname: '/systemconfig/editemail', state: { emailInfo: email, type: 'whitelist' } }}> <FontAwesomeIcon icon={faEdit} title='Edit' size="lg" /> </Link> : null;
        let domainAction = email.isDeleted === "Yes" ? <button className='list-delete-button' onClick={() => this.toggleActionModal(email)}><FontAwesomeIcon icon={faTrashAlt} title='Delete' size="lg" /></button> : <button className='list-delete-button' onClick={() => this.toggleActionModal(email)}><FontAwesomeIcon icon={faUndo} title='Restore' size="lg" /></button>;
        return (
            <>
                {editAction}
                {domainAction}
            </>
        );
    }

    deleteRestoreEmail = () => {
        let deleteRestoreEmail = this.state.deleteRestoreEmail;
        deleteRestoreEmail.isDeleted = this.state.action === "Delete" ? true : false;
        const url = '/api/EMailWhitelist/'
        apiPut(url, deleteRestoreEmail)
            .then(() => {
                let newEmails = this.state.emailOptions;
                let deletedEmailIndex = newEmails.findIndex(x => { return x.key === this.state.deleteRestoreEmail.key });
                newEmails[deletedEmailIndex].isDeleted = this.state.action === "Delete" ? "No" : "Yes";
                this.setState({ emailOptions: newEmails, deleteRestoreEmail: null })
                this.toggleActionModal(null);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    toggleActionModal = (email) => {
        let originalEmail = null;
        let action = "Delete";
        if (email !== null) {
            originalEmail = this.state.emailOptions.length > 0 ? this.state.emailOptions.filter((e) => e.name === email.name)[0] : null;
            action = email.isDeleted === "No" ? "Restore" : "Delete";
        }

        this.setState({
            actionModal: !this.state.actionModal,
            deleteRestoreEmail: originalEmail,
            action: action
        });
    }

    actionModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.actionModal} toggle={() => this.toggleActionModal(null)}>
                    <MDBModalHeader toggle={() => this.toggleActionModal(null)}>{this.state.action} Email</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to {this.state.action.toLowerCase()} this Email Domain?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.toggleActionModal(null)}>No</MDBBtn>
                        <MDBBtn color="primary" onClick={() => this.deleteRestoreEmail()}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    render() {
        const columns = [{ name: 'Name', selector: row => row.name, sortable: true, sortField: 'name' },
            { name: 'Created By', selector: row => row.createdByName, sortable: true, sortField: 'createdByName' },
            { name: 'Active', selector: row => row.isDeleted, sortable: true, sortField: 'isDeleted' },
            { name: 'Edit', cell: row => this.renderActions(row), allowOverflow: true, button: true }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',

                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };
        return (
            <div className="listcontainer" style={{ width: '90%', paddingLeft: '10%', marginBottom: '20px' }}>
                <div className="downloadIcon">
                    <Link to={{ pathname: '/systemconfig/addemail', state: { type: 'whitelist' } }}>
                        <FontAwesomeIcon icon={faPlus} size="lg" />
                        <div>Add Domain</div>
                    </Link>
                </div>
                <div>
                    <h3>Email Domain Whitelist</h3>
                </div>

                <div style={{ width: '90%', paddingLeft: '10%', marginBottom: '20px' }}>
                    <DataTable
                        columns={columns}
                        data={this.state.emailOptions}
                        customStyles={customStyles}
                        striped
                        highlightOnHover
                        responsive
                        pagination
                        //paginationServer
                        subHeader
                        //sortServer
                        progressPending={this.state.dataLoading}
                        //onSort={this.handleSortChange}
                        subHeaderComponent={this.getSubHeaderComponent()}
                        paginationTotalRows={this.state.totalRows}
                        paginationPerPage={this.state.perPage}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationComponentOptions={{ noRowsPerPage: false }}
                    />
                </div>
                {this.actionModal()}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}
export default connect(mapStateToProps, {})(EmailWhitelist);