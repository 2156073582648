import React from "react";
import ADInfoBody from "./ADInfoBody";
import ADMembershipBody from "./ADMembershipBody";
import './DetailModal.css'
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn } from 'mdbreact';

export default function DetailModal({ isOpen, toggleModal, modalAction, modalData, ...props }) {
    let title;
    let header;
    let body;
    let footer;
    let size;
    switch (modalAction) {
        case 'resendEmail':
            title='Resent Terms of Use Email';
            body='Resend Terms of Use Email Successful';
            footer=<button className='btn btn-primary updateButton' onClick={() => toggleModal(null, modalAction)}>OK</button>
            break;
        case 'adinfo':
            title="Azure AD Guest Account Information";
            body=<ADInfoBody account={modalData?.account} adGroups={modalData?.adGroups}/>
            footer=<MDBBtn color="primary" onClick={() => toggleModal(null, modalAction)}>Close</MDBBtn>
            break;
        case 'adMembership':
            title=<><h4 className="bold">Azure AD TEAMS Membership</h4><h5>{modalData?.teamsSiteName}</h5></>
            header=<MDBModalHeader tag="div" titleClass="center-text">{title}</MDBModalHeader>
            body=<ADMembershipBody accounts={modalData?.accounts} />
            footer=<MDBBtn color="primary" onClick={() => toggleModal(null, modalAction)}>Close</MDBBtn>
            size="lg";
            break;
        default:
            title='Error';
            body ='An error occured.  Please contact IshareSupport@protiviti.com if the problem continues';
            footer=<button className='btn btn-primary updateButton' onClick={() => toggleModal(null, modalAction)}>OK</button>
            break;
    }
    return (
        <MDBContainer>
            <MDBModal isOpen={isOpen} toggle={() => toggleModal(null, modalAction)} backdrop={false} size={size}>
                {header ? header : <MDBModalHeader>{title}</MDBModalHeader>}
                <MDBModalBody>
                    {body}
                </MDBModalBody>
                <MDBModalFooter>
                    {footer}
                </MDBModalFooter>
            </MDBModal>
        </MDBContainer>
    )
}
