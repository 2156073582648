import React from 'react';
import './AddEditDelegate.css';
import AddEditDelegate from './AddEditDelegate';

class EditDelegate extends React.Component {
    render() {
        return (
            <div className='container delegateContainer'>
                <div className='form-group'>
                    <AddEditDelegate delegateInfo={this.props.location.state ? this.props.location.state.delegateInfo : null} />
                </div>
            </div>
        );
    }
}

export default (EditDelegate);