import React from 'react';
import '../../pages/ManageDelegates/ManageDelegates.css';
import { connect } from 'react-redux';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import { apiGet, apiPut } from '../../services/apiService';

class ManageDelegatesGrid extends React.Component {
    state = {
        delegates: [],
        delegatesDupe: [],
        deleteModal: false,
        deletingDelegate: {},
        responseDelegates: [],
        isReload: false,
        totalRows: 0,
        perPage: 10,
        currentPage: 1,
        sortBy: "",
        sortOrder: "desc",
        filterText: "",
        dataLoading: true,
        action: ""
    }

    componentDidMount() {
        this.fetchDelegates();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchDelegates = (filter = this.state.filterText) => {
        this.mounted = true;
        //const url = config.azRedirectUri + '/api/Delegates';
        const url = `/api/Delegates?search=${filter}`;
        apiGet(url)
            .then((response) => {
                if (this.mounted) {
                    let delegates = this.assembleDelegates(response.data).map((x, index) => ({ ...x, action: this.renderActions(response.data[index]) }));
                    this.setState({ delegates: delegates, delegatesDupe: delegates, responseDelegates: response.data, totalRows: response.data.count, dataLoading: false });
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    window.location.replace(config.azLogin);
                }
            })
    }

    assembleDelegates = (response) => {
        let allDelegates = response.map((x) => {
            return ({
                'displayName': x.displayName,
                'firstName': x.firstName,
                'lastName': x.lastName,
                'offices': x.offices
            })
        });
        return allDelegates;
    }

    handlePageChange = page => {
        //this.fetchDelegates(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        //this.fetchDelegates(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    //handleSortChange = (c, d) => {
    //    this.setState({ sortBy: c.selector, sortOrder: d }, () => {
    //        this.fetchDelegates(this.state.currentPage);
    //    });
    //}

    handleFilterChange = (e) => {
        let delegates = this.state.delegatesDupe;
        let tableFilter = e.target.value.toLowerCase();
        if (tableFilter !== "") {
            delegates = delegates.filter(x => x.displayName.toLowerCase().includes(tableFilter) || x.firstName.toLowerCase().includes(tableFilter) || x.lastName.toLowerCase().includes(tableFilter) || x.offices.toLowerCase().includes(tableFilter));
        }
        this.setState({ filterText: tableFilter, delegates: delegates });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "" });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    //renderOffices(offices) {
    //    return (
    //        <Select
    //            value={offices[0]}
    //            options={offices}
    //            getOptionLabel={(option) => option.name}
    //            getOptionValue={(option) => option.key}
    //        />
    //    );
    //}

    deleteDelegate = () => {
        const url = '/api/Delegates/Delete';
        let body = '"' + this.state.deletingDelegate.key + '"';
        apiPut(url, body)
            .then(() => {
                this.setState({ isReload: false });
                let filterDelegate = this.state.responseDelegates.filter(x => x.key !== this.state.deletingDelegate.key);
                let delegates = this.assembleDelegates(filterDelegate).map((x, index) => ({ ...x, action: this.renderActions(filterDelegate[index]) }));
                this.setState({ delegates: delegates, delegatesDupe: delegates, responseDelegates: filterDelegate });
                this.toggleDeleteModal(null);
            })
            .catch(function (error) {
                if (error.response.status === 402 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.deleteDelegate();
                }
                console.log(error);
            })
    }

    toggleDeleteModal = (delegate) => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            deletingDelegate: delegate
        });
    }

    deleteModal() {
        let fullName = this.state.deletingDelegate !== null ? this.state.deletingDelegate.firstName + ' ' + this.state.deletingDelegate.lastName : null;
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggleDeleteModal(null)}>
                    <MDBModalHeader toggle={() => this.toggleDeleteModal(null)}>Delete Delegate: {fullName}</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete this Delegate?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.toggleDeleteModal(null)}>No</MDBBtn>
                        <MDBBtn color="primary" onClick={this.deleteDelegate}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderActions(delegate) {
        return (
            <div className='dropdownAction'>
                <MDBDropdown dropleft>
                    <MDBDropdownToggle color="none">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <Link to={{ pathname: '/editdelegate', state: { delegateInfo: delegate } }} style={{ color: 'black', textDecoration: 'none' }}><MDBDropdownItem>Edit</MDBDropdownItem></Link>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleDeleteModal(delegate)}><MDBDropdownItem>Delete</MDBDropdownItem></span>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        );
    }

    render() {
        const columns = [{ name: 'Display Name', selector: row => row.displayName, sortable: true },
            { name: 'Given Name', selector: row => row.firstName, sortable: true },
            { name: 'Surname', selector: row => row.lastName, sortable: true },
            { name: 'Office Association', selector: row => row.offices, sortable: true },
            { name: 'Edit', cell: row => row.action, allowOverflow: true, button: true }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',

                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };

        return (
            <div>
                <DataTable
                    columns={columns}
                    data={this.state.delegates}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    subHeader
                    //sortServer
                    progressPending={this.state.dataLoading}
                    //onSort={this.handleSortChange}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    paginationTotalRows={this.state.totalRows}
                    paginationPerPage={this.state.perPage}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={{ noRowsPerPage: false }}
                />
                {this.deleteModal()}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, {})(ManageDelegatesGrid);