import React from 'react';
import './AddEditDelegate.css';
import AddEditDelegate from './AddEditDelegate';

class AddDelegate extends React.Component {
    render() {
        return (
            <div className='container delegateContainer'>
                <div className='form-group'>
                    <AddEditDelegate />
                </div>
            </div>
        );
    }
}

export default (AddDelegate);