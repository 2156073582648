import React from 'react';
import './AddEditOffice.css';
import AddEditOffice from './AddEditOffice';

class EditOffice extends React.Component {
    render() {
        return (
            <div className='container officeContainer'>
                <div className='form-group'>
                    <AddEditOffice office={this.props.location.state ? this.props.location.state.officeInfo : null} />
                </div>
            </div>
        );
    }
}

export default (EditOffice);