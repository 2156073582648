import React from 'react';
import '../Teams/TeamRequestDetail.css';
//import ProOffice from './ProOffice';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//import { getOfficeKey } from '../actions';
import config from '../../config';
import { getCurrentUser } from '../../actions';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import {fetchAADGroupsByEmail, fetchAADMembersByTeamsID, fetchAADOwnersByTeamsID} from "../../services/graphApiService"
import DetailModal from '../../components/Teams/DetailModal';
import { apiGet, apiPost } from '../../services/apiService';

class ClientTeamsRequestDetail extends React.Component {
    state = {
        request: {},
        guestUsersName: [],
        relatedEngagements: [],
        memberFirCompanyName: '',
        modal: false,
        modalAction: null,
        modalData: {},
    };

    componentDidMount() {
        var location = this.props.location.pathname;
        var n = location.lastIndexOf('/');
        const requestKey = location.substring(n + 1);
        if (!requestKey) {
            <Redirect to='/clientteamsrequests' />;
        }
        else {
            if (requestKey) this.fetchData(requestKey)
        }
    }

    mapStatus = (num) => {
        let status = null;
        switch (num) {
            case 0:
                status = 'Active';
                break;
            case 1:
                status = 'Expired';
                break;
            case 2:
                status = 'Disabled';
                break;
            case 3:
                status = 'Locked';
                break;
            case 4:
                status = 'Pending';
                break;
            case 5:
                status = 'Cancelled';
                break;
            default:
                status = 'Unknown';
                break;
        }

        return status;
    }

    fetchData = async (requestKey) => {
        const url = config.azRedirectUri + '/api/ClientTeams/detail/' + requestKey;
        await apiGet(url)
            .then((response) => {
                if (response.data) {
                    let data = response.data;
                    let md = data.mdEmail
                    let requester = data.requesterEmail
                    let user = this.props.userReducer.currentUser
                    if (user.emailAddress === md || user.loginAddress === md || user.emailAddress === requester || user.loginAddress === requester || user.isAdmin) {
                        let reqNames = data.guestAccessClientUsers.map(x => {
                            let today = new Date();
                            let expireDate = x.expireDate !== null ? new Date(x.expireDate.split('T')[0]) : ''
                            let expired = expireDate > today ? false : true;
                            return ({
                                'memberFirmName': user.isAdmin ? <a href="#" onClick={(e) =>{e.preventDefault(); this.getAdInfo(x)}}>{x.name}</a> : x.name,
                                'terms': this.getTermStatus(x.acceptAgreement, expired, x.expireDate),
                                'acceptDate': x.acceptDate !== null ? x.acceptDate.split('T')[0] : '',
                                'expireDate': x.expireDate !== null ? x.expireDate.split('T')[0] : '',
                                'status': this.mapStatus(x.accountStatus),
                                'memberFirmEmail': x.email,
                                'resendEmail': x.sNowTaskId !== null && x.acceptAgreement == null && user.isAdmin && !expired ? <Link to='#' onClick={e => this.resendEmail(e, x)}>Terms of Use</Link> : ''
                            })
                        })
                        if (data.engagementKey != null && data.engagementKey != '00000000-0000-0000-0000-000000000000') this.getRelatedEngagements(data.engagementKey);
                        this.setState({ guestUsersName: reqNames, request: data, memberFirCompanyName: data.memberFirmCompanyList })
                    }
                    else {
                        <Redirect to='/error' />;
                    }
                }
            })
    }

    getAdInfo = async (account) => {
        await fetchAADGroupsByEmail(account.email)
        .then((responseJson) => {
          let value = responseJson.value;
          if (value !== undefined && value.length > 0) {
            // AD info
            let modalData = {
                account: account,            
                adGroups: value
                .map((x) => x.displayName)
                .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)),
            };
            this.toggleModal(modalData, "adinfo");
          } else if (responseJson?.error.code === "Request_ResourceNotFound") {
            // no AD info
            let modalData = {
                account: account,
                adGroups: null,
            };
            this.toggleModal(modalData, "adinfo");
          } else {
            // some unexpected exception
            console.error(responseJson?.error);
            this.toggleModal(null, "error");
          }
        })
        .catch(() =>{
            this.toggleModal(null, "error");
        });
      };
    
    getADMembership = async () => {
        let url = this.state.request.teamsSiteUrl.substring(this.state.request.teamsSiteUrl.indexOf('=') + 1);
        let groupId = url.substring(0, url.indexOf('&'));
        if (groupId.length != 36) {
            groupId = this.state.request.teamsSiteUrl.split('groupId=')[1].split('&')[0];
        }
        await fetchAADOwnersByTeamsID(groupId).then(async (owners) => {
            return fetchAADMembersByTeamsID(groupId).then(async (members) => {
                let teamsUsers = [...owners.value, ...members.value];
                teamsUsers = teamsUsers.filter((item, index) => index === teamsUsers.findIndex(e => e.mail === item.mail));
                teamsUsers = teamsUsers.sort((a, b) => { if (a.displayName < b.displayName) { return -1; } if (a.displayName > b.displayName) { return 1; } return 0; });
                this.toggleModal({ accounts: teamsUsers, teamsSiteName: this.state.request.teamsSiteName}, "adMembership");
             }).catch((e) => {
                this.toggleModal(null, "error");    
             })
        }).catch((e) => {
            this.toggleModal(null, "error");
        });
    }

    getRelatedEngagements = async (id) => {
        const url = config.azRedirectUri + '/api/Engagements/Additional/' + id;
        await apiGet(url)
            .then((response) => {
                if (response.data) {
                    let engagements = response.data.map(x => {
                        return ({
                            'engagementName': x.engagementName,
                            'clientName': x.clientName,
                            'emd': x.emd,
                            'bu': x.bu,
                            'sensitivity': x.sensitivity === 'True' ? 'Yes' : 'No'
                        });
                    })
                    this.setState({ relatedEngagements: engagements });
                }
            })
            .catch(function (error) {
                if (error.response != null && error.response.status === 401) {
                    this.getRelatedEngagements();
                }
                console.log(error);
            })
    }

    resendEmail = async (event, user) => {
        event.target.style.display = "none"
        let resendDTO = {
            email: user.email,
            name: user.name,
            sysid: user.sys_Id,
            expireDate: user.expireDate
        }
        this.setState({ btnDisable: true });
        const url = config.azRedirectUri + '/api/TeamsAcceptance/resendemail';
        await apiPost(url, resendDTO)
            .then((response) => {
                //console.log(response)
                this.toggleModal(null, 'resendEmail');
            })
    }

    toggleModal = (data, action) => {
        this.setState({
            modal: !this.state.modal,
            modalAction: action,
            modalData: data,
        });
    }

    getTermStatus(acceptAgreement, expired, expireDate) {
        let finalStatus = '';
        if (acceptAgreement === null && expired && expireDate !== null) {
            finalStatus = "Expired";
        } else {
            switch (acceptAgreement) {
                case 0:
                    finalStatus = 'Rejected';
                    break;
                case 1:
                    finalStatus = 'Approved';
                    break;
                case 2:
                    finalStatus = 'Expired';
                    break;
                case 3:
                    finalStatus = 'Cancelled';
                    break;
                default:
                    finalStatus = 'Pending';
                    break;
            };
        }
        
        return (finalStatus);
    }

    getComplianceLevelLabel(name) {
        let compLabel = '';
        switch (name) {
            case 0:
                compLabel = ' ';
                break;
            case 1:
                compLabel = '1 - Low';
                break;
            case 2:
                compLabel = '2 - Medium';
                break;
            case 3:
                compLabel = '3 - High';
                break;
        }
        return (compLabel);
    }

    getRequestTypeLabel(name) {
        let typeLabel = '';
        switch (name) {
            case 0:
                typeLabel = 'Client Engagement Site';
                break;
            case 1:
                typeLabel = 'Protiviti Training';
                break;
            case 2:
                typeLabel = 'Other';
                break;
        }
        return (typeLabel);
    }

    splitDate(date) {
        if (date) {
            let dateString = String(date).split('T')[0]
            return dateString
        }
        else {
            return null
        }
    }

    formatDate(date) {
        if (date !== undefined && date !== "") {
            var myDate = new Date(date);
            var month = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ][myDate.getMonth()];
            var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
            return str;
        }
        return "";
    }
    
    render() {
        const req = this.state.request;
        let mdApprovalStatus;
        let reqStatus;

        if (req.approvalStatus === 0)
            mdApprovalStatus = 'Pending'
        else if (req.approvalStatus === 1)
            mdApprovalStatus = 'Approved'
        else if (req.approvalStatus === 2)
            mdApprovalStatus = 'Rejected'
        else
            mdApprovalStatus = 'Expired'

        if (req.requestStatus === 0)
            reqStatus = 'Open'
        else
            reqStatus= 'Closed'

        const data = {
            columns: [
                { label: 'Guest Name', field: 'memberFirmName', sort: 'asc', width: 150 },
                { label: 'Terms of Use', field: 'terms', sort: 'asc', width: 150 },
                { label: 'Response Date', field: 'acceptDate', sort: 'asc', width: 150 },
                { label: 'Respond By', field: 'expireDate', sort: 'asc', width: 150 },
                { label: 'Account Status', field: 'status', sort: 'asc', width: 150 },
                { label: 'Guest User Email', field: 'memberFirmEmail', sort: 'asc', width: 150 },
                { label: 'Resend Email', field: 'resendEmail', sort: 'asc', width: 150 }
            ],
            rows: this.state.guestUsersName
        };

        const relatedEngagement = {
            columns: [
                { label: 'Engagement Name', field: 'engagementName', sort: 'asc', width: 150 },
                { label: 'Client Name', field: 'clientName', sort: 'asc', width: 150 },
                { label: 'EMD', field: 'emd', sort: 'asc', width: 150 },
                { label: 'Bu/Country', field: 'bu', sort: 'asc', width: 150 },
                { label: 'Sensitivity', field: 'sensitivity', sort: 'asc', width: 150 }
            ],
            rows: this.state.relatedEngagements
        };

        return (
            <div className='container accountDetailContainer'>
                <Link to='/clientteamsrequests'  ><button type="button" className="btn btn-primary backButton">Back To List</button></Link>
                <div className='form-group topMargin'>
                    <div className='accountDetailsTitle'>
                        <h4>TEAMS Guest Access Request Details</h4>
                        <h5>TEAMS Request ID: {req.requestId}</h5>
                    </div>
                    <hr className='teamRequestDetailHr' />
                </div>


                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels'>Requestor</label>
                                </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.requesterName}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels'>Client Name</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.clientName}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>MD Approver</label>
                             </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.mdName}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels'>Requestor Email</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.requesterEmail}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels' style={{ marginLeft: "-55px" }}>Engagement Name</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.engagementName}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>MD Email</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.mdEmail}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Service Now ID</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.serviceNowRITM}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Engagement MD</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.emd}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>MD Approval Status</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{mdApprovalStatus}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Request Status</label>
                            </div>

                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{reqStatus}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>BU/Country</label>
                            </div>

                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.bu}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>MD Approved/Rejected By</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.approver}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>TEAMS Site Type</label>
                            </div>

                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>Shared Client Engagement Site</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Sensitivity</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.sensitivity}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>MD Approved/Rejected Date</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{this.splitDate(req.approvalDate)}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>TEAMS Site</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{this.props.userReducer.currentUser.isAdmin ? <a href="" onClick={(e) => {e.preventDefault(); this.getADMembership()}}>{req.teamsSiteName}</a> : req.teamsSiteName}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>TEAMS Access Expires On</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.expireDate !== null ? this.formatDate(req.expireDate) : null}</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className='row'>*/}
                {/*    <div className='teamRequestDetailsCol col-md-12'>*/}
                {/*        <div className='row' style={{ marginLeft: "-23px" }}>*/}
                {/*            <div className='teamRequestDetailsColInner col-md-2'>*/}
                {/*                <label className='fieldLabels '>TEAMS Site</label>*/}
                {/*            </div>*/}
                {/*            <div className='teamRequestDetailsColInnerLeft col-md-10'>*/}
                {/*                <label className='fieldValue'>{req.teamsSiteName}</label>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}


                {/*</div>*/}
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-12'>
                        <div className='row' style={{ marginLeft: "-23px" }}>
                            <div className='teamRequestDetailsColInner col-md-2'>
                                <label className='fieldLabels '>TEAMS Site URL</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-10'>
                                <label className='fieldValue'>{req.teamsSiteUrl}</label>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-12'>
                        <div className='row' style={{ marginLeft: "-23px" }}>
                            <div className='teamRequestDetailsColInner col-md-2'>
                                <label className='fieldLabels '>Justification</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-10'>
                                <label className='fieldValue'>{req.justification}</label>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-12'>
                        <div className='row' style={{ marginLeft: "-23px" }}>
                            <div className='teamRequestDetailsColInner col-md-2'>
                                <label className='fieldLabels'></label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-10'>
                                <hr className='teamRequestDetailHr' />
                                <div>
                                    <MDBTable striped bordered hover>
                                        <MDBTableHead columns={data.columns} />
                                        <MDBTableBody rows={data.rows} />
                                    </MDBTable>
                                </div>
                                <hr className='teamRequestDetailHr' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-12'>
                        <div className='row' style={{ marginLeft: "-23px" }}>
                            <div className='teamRequestDetailsColInner col-md-2'>
                                <label className='fieldLabels'></label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-10'>
                                <label className='fieldLabels'>Other client engagements that are using the same TEAMS site</label>
                                <br /><br />
                                <hr className='teamRequestDetailHr' />
                                <div>
                                    <MDBTable striped bordered hover>
                                        <MDBTableHead columns={relatedEngagement.columns} />
                                        <MDBTableBody rows={relatedEngagement.rows} />
                                    </MDBTable>
                                </div>
                                <hr className='teamRequestDetailHr' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Created By</label>
                            </div>

                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.createdByName}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Modified By</label>
                            </div>

                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.modifiedByName}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Closed By</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{req.closedByName}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Created Date</label>
                            </div>

                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{this.splitDate(req.createdDate)}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Modified Date</label>
                            </div>

                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{this.splitDate(req.modifiedDate)}</label>
                            </div>
                        </div>
                    </div>
                    <div className='teamRequestDetailsCol col-md-4'>
                        <div className='row'>
                            <div className='teamRequestDetailsColInner col-md-6'>
                                <label className='fieldLabels '>Closed Date</label>
                            </div>
                            <div className='teamRequestDetailsColInnerLeft col-md-6'>
                                <label className='fieldValue'>{this.splitDate(req.closedDate)}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <hr className='teamRequestDetailHr' />
                <Link to='/clientteamsrequests'  ><button type="button" className="btn btn-primary backButtonLow">Back To List</button></Link>
                <DetailModal isOpen={this.state.modal} toggleModal={this.toggleModal} modalAction={this.state.modalAction} modalData={this.state.modalData} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}
export default connect(mapStateToProps, { getCurrentUser })(ClientTeamsRequestDetail);