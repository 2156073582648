import React, { Component } from "react";
import { MDBContainer, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import './SystemConfiguration.css';
import { connect } from 'react-redux';

class SystemConfiguration extends Component {
    constructor() {
        super();
        this.state = {
            activeItem: null
        };
    }

    componentDidMount() {
        let type = this.props.location.state ? this.props.location.state.type : null
        if (['/systemconfig/emailwhitelist', '/systemconfig/addemail', '/systemconfig/editemail'].includes(this.props.location.pathname) || type === 'whitelist') {
            this.setState({ activeItem: '2' });
        }
        else if (['/systemconfig/emailblacklist', '/systemconfig/addemail', '/systemconfig/editemail'].includes(this.props.location.pathname) || type === 'blacklist') {
            this.setState({ activeItem: '3' });
        }
        else {
            this.setState({ activeItem: '1' });
        }
    }

    componentDidUpdate() {
        if (this.props.userReducer.currentUser.isAdmin === false) {
            this.props.history.push('/');
        }
    }


    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };

    render() {
        return (
            <div className="container systemcontainer">
                <h3><FontAwesomeIcon icon={faCogs} size="lg" /> <div className="heading">System Configuration</div></h3>
                <MDBContainer>
                    <div className="classic-tabs">
                        <MDBNav classicTabs className="nav-tabs nav-justified nav-pills"  >
                            <MDBNavItem>
                                <MDBNavLink to="/systemconfig/auditlogs" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
                                    Audit Log
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to="/systemconfig/emailwhitelist" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
                                    Email Whitelist
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to="/systemconfig/emailblacklist" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
                                    Email Blacklist
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNav>
                    </div>
                </MDBContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, {})(SystemConfiguration);