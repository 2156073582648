import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import { getCurrentUser } from '../../actions';
import { getUsername } from '../../services/authService';
import { apiGet } from '../../services/apiService';

class OpenTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReload: false,
            openTerms: []
        }
    }

    componentDidMount() {
        this.getData(getUsername());
    }

    getData = async (e) => {
        var today = new Date();
        const url = '/api/TeamsAcceptance/OpenTerms';
        await apiGet(url)
            .then((response) => {
                let data = response.data;
                let openTerms = data.map((x) => {
                    return ({
                        'siteName': x.response === 1 && new Date(x.expireDate) > today ? <a href={x.siteUrl} rel="noreferrer" target="_blank">{x.siteName}</a> : x.siteName,
                        'contactName': x.contactName,
                        'contact': x.contact,
                        'respondBy': x.respondBy,
                        'response': this.getTermStatus(x.response, x.sysId),
                        'acceptDate': x.acceptDate,
                        'expireDate': x.expireDate,
                    });
                })

                this.setState({ openTerms: openTerms });
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.getData();
                }
                console.log(error);
            })
    }

    getTermStatus(x, sysId) {
        let finalStatus = '';
        switch (x) {
            case 0:
                finalStatus = 'Rejected';
                break;
            case 1:
                finalStatus = 'Accepted';
                break;
            case 2:
                finalStatus = 'Expired';
                break;
            case 3:
                finalStatus = 'Cancelled';
                break;
            default:
                finalStatus = <Link to={`/acceptterms?taskid=${sysId}`}><button type="button" className="btn btn-primary updateButton bottom-button" >Agree to Terms</button></Link>;
                break;
        };
        return (finalStatus);
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Site Name',
                    field: 'siteName',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Protiviti Contact',
                    field: 'contactName',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Contact Email',
                    field: 'contact',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Respond By',
                    field: 'respondBy',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Response',
                    field: 'response',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Responded On',
                    field: 'acceptDate',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Expiration',
                    field: 'expireDate',
                    sort: 'asc',
                    width: 110
                }
            ],
            rows: this.state.openTerms
        };
        return (
            <div>
                <MDBDataTable
                    striped
                    bordered
                    hover
                    data={data}
                    searchLabel="Search"
                />
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(OpenTerms);