import React, { Component } from 'react';
import DataTable, { defaultThemes } from 'react-data-table-component';
import config from '../../config';
import { connect } from 'react-redux';
import { apiGet } from '../../services/apiService';

class AuditLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalRows: 0,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortOrder: "desc",
            dataLoading: true,
            filterText: ""
        }
    }

    componentDidMount() {
        this.fetchAuditLogs(1);
    }

    fetchAuditLogs = async (page, size = this.state.perPage, sortBy = this.state.sortBy, sortOrder = this.state.sortOrder, filter = this.state.filterText) => {
        let url = config.azRedirectUri + `/api/Log?page=${page}&size=${size}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${filter}`;
        await apiGet(url)
            .then((response) => {
                let logs = this.mapResponse(response.data.list);
                this.setState({ data: logs, totalRows: response.data.count, dataLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    mapResponse = (response) => {
        let logs = response.map((x) => {
            return ({
                'user': x.displayName,
                'date': new Date(x.createdDate).toLocaleDateString(),
                'category': x.category,
                'description': x.description,
                'misStatus': x.misStatus,
                'sNowStatus': x.sNowStatus
            })
        });
        return logs;
    }

    handlePageChange = page => {
        this.fetchAuditLogs(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        this.fetchAuditLogs(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    handleSortChange = (c, d) => {
        this.setState({ sortBy: c.sortField, sortOrder: d }, () => {
            this.fetchAuditLogs(this.state.currentPage);
        });
    }

    handleFilterChange = (e) => {
        this.setState({ filterText: e.target.value }, () => {
            this.fetchAuditLogs(1);
        });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "" });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    render() {
        const columns = [{ name: 'User', selector: row => row.user, sortable: true, sortField: 'user' },
            { name: 'Date', selector: row => row.date, sortable: true, sortField: 'date', maxWidth: '100px' },
            { name: 'Category', selector: row => row.category, sortable: true, sortField: 'category' },
            { name: 'Description', selector: row => row.description, sortable: true, sortField: 'description', wrap: true, minWidth: '550px' },
            { name: 'MIS AD', selector: row => row.misStatus, sortable: true, sortField: 'misStatus' },
            { name: 'SNow', selector: row => row.sNowStatus, sortable: true, sortField: 'sNowStatus' }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',
                        
                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };

        return (
            <div style={{ width: '90%', paddingLeft: '10%', marginTop: '20px', marginBottom: '20px' }}>
                <DataTable
                    columns={columns}
                    data={this.state.data}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    paginationServer
                    subHeader
                    sortServer
                    progressPending={this.state.dataLoading}
                    onSort={this.handleSortChange}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    paginationTotalRows={this.state.totalRows}
                    paginationPerPage={this.state.perPage}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={{ noRowsPerPage: false }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}
export default connect(mapStateToProps, {})(AuditLogs);