import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { graphFetch } from '../../services/graphApiService';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Select from 'react-select';
import config from '../../config';
import './TeamRequestEdit.css';
import { apiGet, apiPut } from '../../services/apiService';

class TeamRequestEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            teamRequest: {},
            allManagingDirectors: [],
            submit: true,
            backbtn: false,
            disableMD: true,
            successModal: false,
            emd: null,
            userList: null,
            selectedMD: null,
            requestKey: null,
            manDirector: null,
            selectedTeam: null,
            errorMessage: null,
            submitResponse: null,
            legalApprovers: null,
            selectedTeamUrl: null,
            selectedMDEmail: null,
            manDirectorName: null,
            managingDirectors: null,
            selectedJustification: null,
            initial: 0
        };
    }

    componentDidMount() {
        if (this.props.request) {
            this.getTeamsRequest(this.props.request.key);
        } else if (this.props.match.params.id) {
            this.getTeamsRequest(this.props.match.params.id)
        } else {
            <Redirect to='/teamsrequests' />;
        }
        this.getLegalApprovers();
        this.getMds();
        this.getTeams();
    }

    getLegalApprovers = () => {
        let url = config.azRedirectUri + '/api/TeamsLegal/Approvers';
        apiGet(url)
            .then(response => {
                this.setState({ legalApprovers: response.data });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    this.getLegalApprovers();
                }
                console.log(error);
            });
    }

    getTeamsRequest = async (requestKey) => {
        const url = config.azRedirectUri + '/api/Teams/detail/' + requestKey;
        await apiGet(url)
            .then((response) => {
                if (response.data) {
                    let fullName = this.props.userReducer.currentUser.lastName.toLowerCase() + ', ' + this.props.userReducer.currentUser.firstName.toLowerCase();
                    if ((fullName === response.data.requesterName.toLowerCase() && response.data.legalApprovalStatus === 0)) {
                        this.setState({
                            teamRequest: response.data,
                            selectedTeam: response.data.teamsSiteName,
                            selectedTeamUrl: response.data.teamsSiteUrl,
                            selectedJustification: response.data.justification,
                            manDirector: response.data.mdName,
                            selectedMD: response.data.mdName,
                            selectedMDEmail: response.data.mdEmail,
                            requestKey: requestKey
                        }, () => { this.renderUserList(); });
                    } else {
                        <Redirect to='/teamsrequests' />;
                    }
                }
            })
    }

    getTeams = async () => {
        await graphFetch('https://graph.microsoft.com/v1.0/me/joinedTeams', {})
            .then((response) => {
                response.json()
                    .then((responseJson) => {
                        let value = responseJson.value;
                        if (value !== undefined) {
                            let sortedValue = value.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0));
                            this.setState({ teams: sortedValue });
                            value.filter(team => team.displayName.includes(this.state.selectedTeam)).map(filteredTeams => (this.updateTeams(filteredTeams)))
                        }
                    });
            })
            .catch((error) => {
                console.error(error);
            })
    }

    getMds = async () => {
        const url = config.azRedirectUri + '/api/Teams/md';
        await apiGet(url)
            .then((response) => {
                this.setState({
                    allManagingDirectors: response.data
                })
            })
            .catch((error) => {
                console.error(error);
            })
    }

    mapMds = async (teamsMD, internalId) => {
        let managingDirectors = this.state.allManagingDirectors;
        let mappedMD = managingDirectors.filter(o1 => teamsMD.some(o2 => o1.userPrincipalName === o2.userPrincipalName));
        let md = mappedMD.find(x => x.teamThreadId.find(y => y === internalId));

        if (this.props.userReducer.currentUser.isAdmin) {
            let mappedLegalApprovers = teamsMD.filter(({ userPrincipalName }) => this.state.legalApprovers.includes(userPrincipalName));
            let legalApprovers = mappedLegalApprovers.map(item => {
                const container = {};
                container.name = item.surname + ', ' + item.givenName;
                container.email = item.mail;
                container.userPrincipalName = item.userPrincipalName;

                return container;
            });
            mappedMD.push(...legalApprovers);
        }

        mappedMD.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.setState({
            managingDirectors: mappedMD,
            manDirector: md ? md.userPrincipalName : null,
            manDirectorName: md ? md.name : null,
            emd: md ? md.name : null,
            disableMD: false
        })
    }

    getRequestTypeLabel(name) {
        let typeLabel = '';
        switch (name) {
            case 0:
                typeLabel = 'Client Engagement Site';
                break;
            case 1:
                typeLabel = 'Protiviti Training';
                break;
            case 2:
                typeLabel = 'Other';
                break;
        }
        return (typeLabel);
    }

    handleTeamSite = selectedOption => {
        this.updateTeams(selectedOption.id);
    }

    handleAppManager = selectedOption => {
        this.setState({ selectedMDEmail: selectedOption.userPrincipalName, manDirector: selectedOption.name, selectedMD: selectedOption.name }, () => this.toggleSubmit());
    }

    handleBJustification = event => {
        this.setState({ selectedJustification: event.target.value }, () => this.toggleSubmit());
    }

    toggleSubmit() {
        if (this.state.selectedMD !== null && this.state.selectedJustification !== null && this.state.selectedTeam !== null && this.state.selectedTeamUrl !== null) {
            this.setState({ submit: false });
        } else {
            this.setState({ submit: true });
        }
    }

    toggleSuccessModal() {
        this.setState({ successModal: !this.state.successModal });
    }

    renderSuccessModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.successModal} toggle={this.toggleSuccessModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>TEAMS Request {this.state.teamRequest.requestId} {this.state.submitResponse}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.submitResponse}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/teamsrequests'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    updateTeams = async (teamsId) => {
        if (typeof(teamsId) === 'object')
            teamsId = teamsId.id
        this.setState({
            disableMD: true,
            manDirector: null,
            manDirectorName: null,
            selectedMDEmail: this.state.initial === 0 ? this.state.selectedMDEmail : null,
            selectedMD: this.state.initial === 0 ? this.state.selectedMD : null,
            submit: true,
            initial: 1
        });
        await graphFetch('https://graph.microsoft.com/v1.0/teams/' + teamsId, {})
            .then((response) => {
                response.json()
                    .then((responseJson) => {
                        this.setState({
                            selectedTeam: responseJson.displayName,
                            selectedTeamUrl: responseJson.webUrl
                        }, async () => {
                            await graphFetch('https://graph.microsoft.com/v1.0/groups/' + teamsId + '/members', {})
                                .then((response) => {
                                    response.json()
                                        .then((res) => {
                                            this.mapMds(res.value, responseJson.internalId);
                                        });
                                });
                        });

                    });
            })
            .catch((error) => {
                console.error(error);
            })
    }

    renderUserList() {
        const userList = this.state.teamRequest.guestAccessUsers.map((item, i) => {
            return (
                <div className='margin-left-50' key={i}>
                    <label className='fieldLabels'>Name:</label> {item.memberFirmName} &nbsp;

                    <label className='fieldLabels'>Guest Email:</label> {item.memberFirmEmail}
                </div>
            )
        });
        this.setState({ userList: userList });
    }

    updateRequest = async () => {
        this.setState({ submit: true, backbtn: true })
        let guestRequest = {
            requestid: this.state.teamRequest.requestId,
            requesterName: this.state.teamRequest.requesterName,
            requesterEmail: this.state.teamRequest.requesterEmail,
            teamsSiteName: this.state.selectedTeam,
            teamsSiteUrl: this.state.selectedTeamUrl,
            mDName: this.state.selectedMD,
            mDEmail: this.state.selectedMDEmail,
            justification: this.state.selectedJustification,
            legalApprovalStatus: this.state.teamRequest.legalApprovalStatus
        }
        const url = config.azRedirectUri + '/api/Teams/' + this.state.requestKey;
        await apiPut(url, guestRequest)
            .then((response) => {
                this.setState({ submitResponse: response.data }, () => this.toggleSuccessModal());
            })
            .catch(function (error) {
            })
    }

    render() {
        const req = this.state.teamRequest;
        return (
            <div className='container accountDetailContainer'>
                <form>
                    <div className=''>
                        <Link to='/teamsrequests'><button type="button" className="btn btn-primary backButton">Back To List</button></Link>
                        <div className='form-group topMargin'>
                            <div className='accountDetailsTitle'>
                                <h4>TEAMS Guest Access Request Edit</h4>
                                <h5>TEAMS Request ID: {req.requestId}</h5>
                            </div>
                            <hr className='teamRequestDetailHr' />
                        </div>
                        <div className='form-group'>
                            <div className='form-row'>
                                <p><label className='fieldLabels edit-request-label'>Purpose or Type of TEAMS Site:</label> {this.getRequestTypeLabel(this.state.teamRequest.requestType)}</p>
                            </div>

                            <div className='form-row'>
                                <div>
                                    <label className='fieldLabels margin-left-9'>Guests on Request</label>
                                </div>
                                <div>
                                    {this.state.userList}
                                </div>
                            </div>
                            <div className='form-row'><br /></div>
                            <div className='form-row'>
                                <div className='group'>
                                    <label className='fieldLabels edit-request-label float-left'>Name of Microsoft Teams Site:</label>
                                    <div className='width-400 float-left'>
                                        <Select
                                            value={this.state.selectedTeam}
                                            placeholder={this.state.selectedTeam}
                                            options={this.state.teams}
                                            getOptionLabel={(option) => option.displayName}
                                            getOptionValue={(option) => option.displayName}
                                            onChange={event => { this.handleTeamSite(event) }}
                                            className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedTeam === null ? 'warningBorder' : null} />
                                    </div>
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className='group'>
                                    <label className='fieldLabels edit-request-label float-left'>URL of Microsoft Teams Site:</label>
                                    <div className='width-400 float-left'>
                                        <input disabled value={this.state.selectedTeamUrl || ''} type='text' className='form-control' />
                                    </div>
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className='group'>
                                    <label className='fieldLabels edit-request-label float-left'>Approving Managing Director:</label>
                                    <div className='width-400 float-left'>
                                        <Select
                                            isDisabled={this.state.disableMD}
                                            value={this.state.selectedMD}
                                            placeholder={this.state.selectedMD}
                                            options={this.state.managingDirectors}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.userPrincipalName}
                                            onChange={event => { this.handleAppManager(event) }}
                                            className={this.state.errorMessage === '* Marked fields are required.' && this.state.manDirector === null && this.state.emd === null ? 'warningBorder' : null}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className='group'>
                                    <label className='fieldLabels edit-request-label float-left'>MD EMail:</label>
                                    <div className='width-400 float-left'>
                                        <input readOnly value={this.state.selectedMDEmail || ''} type='text' className='form-control' />
                                    </div>
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className='group'>
                                    <label className='fieldLabels edit-request-label float-left'>Justification:</label>
                                    <div className='width-400 float-left'>
                                        <textarea className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedJustification === '' ? 'warningBorder form-control' : 'form-control'} rows="3" onChange={this.handleBJustification} value={this.state.selectedJustification || ''} />
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className='form-row float-right'>
                                <div className='group'>
                                    <button className="btn btn-primary my-1 bottom-button" type="button" disabled={this.state.submit} onClick={this.updateRequest}>Submit</button>
                                    <Link to='/teamsrequests'><button type="button" className="btn btn-danger updateButton bottom-button" disabled={this.state.backbtn}>Cancel</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {this.renderSuccessModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps)(TeamRequestEdit);