import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import './TeamRequestActions.css';
import { CSVLink } from 'react-csv';

class TeamRequestActions extends React.Component {

    render() {
        let url = window.location.href
        let urlTrimmed = url.substring(url.lastIndexOf('/') + 1)
        let uri = '/assignTeams';
        if (['clientteamsrequests'].includes(urlTrimmed)) {
            uri = '/clientaccessrequest'
        }
        return (
            <React.Fragment>
                <span className='downloadIcon'>
                    <Link to={uri}>
                        <FontAwesomeIcon icon={faEdit} size='lg' />
                        <div>New Request</div>
                    </Link>
                </span>
            </React.Fragment>
        );
    }
}

export default connect()(TeamRequestActions);