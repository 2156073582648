import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { updateOffice, getOfficeKey, updateAddOffices } from '../../actions';

class ProOffice extends React.Component {
    componentDidMount() {
        let selectedOption = null;
        if (this.props.proOfficeKey && (this.props.sharedReducer.officeOptions.length !== 0)) {
            selectedOption = this.props.sharedReducer.officeOptions.find(x => {
                return x.key === this.props.proOfficeKey;
            })
        }
        else if ((this.props.accountCreateIndex !== undefined) || this.props.accountImport) {
            selectedOption = null;
        }
        else {
            selectedOption = { name: 'My Offices' };
        }
        this.props.updateOffice(selectedOption);
    }

    onOptionChange = selectedOption => {
        this.props.updateOffice(selectedOption);
        if (this.props.accountCreateIndex !== undefined) {
            this.props.updateAddOffices(selectedOption, this.props.accountCreateIndex);
        }
    };

    render() {
        return (
            <span style={{ width: '100%', zIndex: '999' }}>
                <Select
                    value={(this.props.accountCreateIndex !== undefined) ? this.props.sharedReducer.selectedAddOffices[this.props.accountCreateIndex] : this.props.sharedReducer.selectedOffice}
                    onChange={this.onOptionChange}
                    options={(this.props.proOfficeKey || (this.props.accountCreateIndex !== undefined) || this.props.accountImport) ? this.props.sharedReducer.officeOptions.filter(x => x.isDeleted === false) : [{ name: 'My Offices' }].concat(this.props.sharedReducer.officeOptions.filter(x => x.isDeleted === false))}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name} />
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return { sharedReducer: state.sharedReducer }
}

export default connect(mapStateToProps, { updateOffice, getOfficeKey, updateAddOffices })(ProOffice);