import React from 'react';
import './AccountDetails.css';
import ProOffice from '../../components/Shared/ProOffice';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getOfficeKey } from '../../actions';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { apiGet, apiPut } from '../../services/apiService';

class AccountDetails extends React.Component {
    accountStatusValue = {
        0: 'Active',
        1: 'Expired',
        2: 'Disabled',
        3: 'Locked'
    };
    loginAddressError = null;
    state = {
        account: {},
        office: {},
        firstName: '',
        lastName: '',
        emailAddress: '',
        accountIsAdmin: false,
        disableButtons: false,
        modal: false,
        isAdmin: false,
        errorMessage: null,
        loginAddress: ''
    };

    componentDidMount() {
        if (this.props.location.pathname === '/accountdetails' && !this.props.location.state) {
            <Redirect to='/error' />;
        }
        else {
            // from AccountGrid
            const accountInfo = this.props.location.state.accountInfo;
            const isAdmin = this.props.location.state.isAdmin;
            if (isAdmin === true)
                this.setLoginAddressError(accountInfo.emailAddress, accountInfo.loginAddress);
            this.setState({ account: accountInfo, firstName: accountInfo.firstName, lastName: accountInfo.lastName, emailAddress: accountInfo.emailAddress, loginAddress: accountInfo.loginAddress, accountIsAdmin: accountInfo.isAdmin, isAdmin: isAdmin });

            this.props.getOfficeKey(accountInfo.officeKey);
            const url = '/api/ProOffice/detail/' + accountInfo.officeKey;
            apiGet(url)
                .then((response) => {
                    const data = response.data[0];
                    this.setState({ office: data });
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    updateAccount = async (event) => {
        event.preventDefault();
        if ((this.state.firstName !== '') && (this.state.lastName !== '') && (this.state.emailAddress !== '') && (this.state.loginAddress !== '')) {
            if ((this.state.firstName !== this.state.account.firstName) || (this.state.lastName !== this.state.account.lastName) || (this.state.emailAddress !== this.state.account.emailAddress) || (this.props.sharedReducer.selectedOffice.key !== this.state.account.officeKey) || (this.state.accountIsAdmin !== this.state.account.isAdmin) || (this.state.loginAddress !== this.state.account.loginAddress)) {
                this.setState({ disableButtons: true });
                let updates = {
                    'key': this.state.account.key,
                    'firstName': this.state.firstName,
                    'lastName': this.state.lastName,
                    'emailAddress': this.state.emailAddress,
                    'loginAddress': this.state.loginAddress,
                    'officeKey': this.props.sharedReducer.selectedOffice.key,
                    'isAdmin': this.state.accountIsAdmin
                };
                const url = '/api/Accounts/Update';
                await apiPut(url, updates)
                    .then(() => {
                        this.toggleModal();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        }
        else {
            this.setState({ errorMessage: 'All fields must be populated.' });
            setTimeout(() => {
                this.setState({ errorMessage: null });
            }, 5000);
        }
    }

    setLoginAddressError = (emailAddress, loginAddress) => {
        this.loginAddressError = emailAddress !== loginAddress ? "Login address and email should match for Member Firm users" : null;
        console.log("error: " + this.loginAddressError)
    }

    onChangeAdmin = (event) => {
        this.setState({ accountIsAdmin: ((event.target.value === 'yes') ? true : false) });
    }

    onGivenNameChange = (event) => {
        this.setState({ firstName: event.target.value });
    }

    onSurnameChange = (event) => {
        this.setState({ lastName: event.target.value });
    }

    onEmailChange = (event) => {
        let loginAddress = event.target.value.endsWith('@roberthalf.com') || event.target.value.endsWith('@protiviti.com') ? this.state.loginAddress : event.target.value;
        this.setState({ emailAddress: event.target.value, loginAddress: loginAddress });
    }

    onLoginChange = (event) => {
        this.setLoginAddressError(this.state.emailAddress, event.target.value);
        this.setState({ loginAddress: event.target.value });
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    renderModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Edit User</MDBModalHeader>
                    <MDBModalBody>
                        User has been successfully saved!
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/accountmanagement'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderAdmin() {
        return (
            this.state.isAdmin ?
                (<div className='accountDetailsCol col-md-6'>
                    <label className='fieldLabels'>Is Super Admin?</label>
                    <div>
                        <div className="form-check form-check-inline adminRadio">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="adminYes" value="yes" checked={this.state.accountIsAdmin} onChange={this.onChangeAdmin} />
                            <label className="form-check-label" htmlFor="adminYes">Yes</label>
                        </div>
                        <div className="form-check form-check-inline adminRadio">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="adminNo" value="no" checked={!this.state.accountIsAdmin} onChange={this.onChangeAdmin} />
                            <label className="form-check-label" htmlFor="adminNo">No</label>
                        </div>
                    </div>
                </div>) : null);
    }

    renderLoginAddress() {
        return (
            this.state.isAdmin ?
                (<div className='accountDetailsCol col-md-6'>
                    <label className='fieldLabels'>Login Address <span className='errorMessage'>{this.loginAddressError}</span></label>
                    <input type='text'
                        className={this.state.loginAddress === this.state.emailAddress ? 'form-control' : 'form-control addUserInput0'}
                        defaultValue={this.state.loginAddress}
                        onChange={(e) => { this.onLoginChange(e) }} />
                </div>
                ) : null);
    }

    render() {
        return (
            <form onSubmit={this.updateAccount} >
                <div className='container accountDetailContainer'>
                    <div className='form-group'>
                        <div className='accountDetailsTitle'>
                            <h4>Edit User</h4>
                            <h5>{this.state.account.displayName}</h5>
                        </div>
                        <hr className='accountDetailHr' />
                        <div className='row'>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Given Name</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    defaultValue={this.state.firstName}
                                    onChange={this.onGivenNameChange} />
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Surname</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    defaultValue={this.state.lastName}
                                    onChange={this.onSurnameChange} />
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Email</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    defaultValue={this.state.emailAddress}
                                    onChange={this.onEmailChange} />
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Office</label>
                                <div>
                                    {this.state.account.officeKey ? <ProOffice proOfficeKey={this.state.account.officeKey} /> : 'N/A'}
                                </div>
                            </div>
                            {this.renderLoginAddress()}
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Account ID</label>
                                <div>
                                    {this.state.account.username ? this.state.account.username : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Country</label>
                                <div>
                                    {this.state.office.country ? this.state.office.country : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>City</label>
                                <div>
                                    {this.state.office.city ? this.state.office.city : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Country Code</label>
                                <div>
                                    {this.state.office.countryCode ? this.state.office.countryCode : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Status</label>
                                <div>
                                    {(this.state.account.accountStatus || this.state.account.accountStatus === 0) ? this.accountStatusValue[this.state.account.accountStatus] : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Office MD</label>
                                <div>
                                    {this.state.office.officeManager ? this.state.office.officeManager : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Expires On</label>
                                <div>
                                    {this.state.account.expirationDate ? this.state.account.expirationDate.substring(0, 10) : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Created</label>
                                <div>
                                    {this.state.account.created ? this.state.account.created.substring(0, 10) : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Created By</label>
                                <div>
                                    {this.state.account.createdByName ? this.state.account.createdByName : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Modified</label>
                                <div>
                                    {this.state.account.modified ? this.state.account.modified.substring(0, 10) : 'N/A'}
                                </div>
                            </div>
                            <div className='accountDetailsCol col-md-6'>
                                <label className='fieldLabels'>Modified By</label>
                                <div>
                                    {this.state.account.modifedByName ? this.state.account.modifedByName : 'N/A'}
                                </div>
                            </div>
                            {this.renderAdmin()}
                        </div>
                        <hr />
                        <div className='accountDetailsButton'>
                            <Link to='/accountmanagement'><button type='button' className='btn btn-secondary' disabled={this.state.disableButtons}>Cancel</button></Link>
                            <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Update</button>
                        </div>
                        <div className='errorMessage'>{this.state.errorMessage}</div>
                        {this.renderModal()}
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { getOfficeKey })(AccountDetails);