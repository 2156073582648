import React from 'react';
import './RequestFilter.css';
import { connect } from 'react-redux';
import { getClientAccessFilterStatus, getCurrentUser } from '../../actions';

class ClientGuestAccountsFilter extends React.Component {
    state = {
        rany: false,
        active: true,
        pending: true,
        disabled: false
    }

    onChange = (event) => {
        if (event.target.value === 'rany') {
            if (!this.state.rany) {
                this.setState({ rany: true, active: true, pending: true, disabled: true, }, () => {
                    let filter = {
                        rany: this.state.rany,
                        active: this.state.active,
                        pending: this.state.pending,
                        disabled: this.state.disabled
                    }
                    this.props.getClientAccessFilterStatus(filter);
                });
            }
            else {
                this.setState({ rany: false, active: false, pending: false, disabled: false }, () => {
                    let filter = {
                        rany: this.state.rany,
                        active: this.state.active,
                        pending: this.state.pending,
                        disabled: this.state.disabled
                    }
                    this.props.getClientAccessFilterStatus(filter);
                });
            }
        }

        if (event.target.value === 'active' || event.target.value === 'pending' || event.target.value === 'disabled') {
            let status = (event.target.value === 'active' ? !this.state.active : event.target.value === 'pending' ? !this.state.pending : !this.state.disabled);
            this.setState({ [event.target.value]: status }, () => {
                if (this.state.rany) {
                    this.setState({ rany: false }, () => {
                        let filter = {
                            rany: this.state.rany,
                            active: this.state.active,
                            pending: this.state.pending,
                            disabled: this.state.disabled
                        }
                        this.props.getClientAccessFilterStatus(filter);
                    });
                }
                else if (this.state.active && this.state.pending && this.state.disabled) {
                    this.setState({ rany: true }, () => {
                        let filter = {
                            rany: this.state.rany,
                            active: this.state.active,
                            pending: this.state.pending,
                            disabled: this.state.disabled
                        }
                        this.props.getClientAccessFilterStatus(filter);
                    });
                }
                else {
                    let filter = {
                        rany: this.state.rany,
                        active: this.state.active,
                        pending: this.state.pending,
                        disabled: this.state.disabled
                    }
                    this.props.getClientAccessFilterStatus(filter);
                }
            });
        }
    }

    render() {
        return (
            <div className='filter'>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='rany' value='rany' checked={this.state.rany} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='rany'>Any</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='active' value='active' checked={this.state.active} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='active'>Active</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='pending' value='pending' checked={this.state.pending} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='pending'>Pending</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='disabled' value='disabled' checked={this.state.disabled} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='disabled'>Disabled</label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getClientAccessFilterStatus, getCurrentUser })(ClientGuestAccountsFilter);