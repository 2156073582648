import React from 'react';
import './Home.css';
import { connect } from 'react-redux';
import { getCurrentUser } from '../actions';
import { Link } from 'react-router-dom';
import config from '../config';
import { Redirect } from 'react-router-dom';
import { apiGet } from '../services/apiService';

class AdminDashboard extends React.Component {

    render() {
        return (
            <div className="menu-row">
                <div className="row">
                    <div className="col-md-2">
                        <Link to='/accountmanagement'><img className="home-icons" src="images/Manage_MF_Accounts.png" alt="Manage_MF_Accounts" /></Link>
                    </div>

                    <div className="col-md-2">
                        <Link to='/assignteams'><img className="home-icons" src="images/Request_MF_TeamsAccess.png" alt="Request_MF_TeamsAccess" /></Link>
                    </div>

                    <div className="col-md-2">
                        <Link to='/teamsrequests'><img className="home-icons" src="images/RequestList_MF_TeamsAccess.png" alt="RequestList_MF_TeamsAccess" /></Link>
                    </div>

                    <div className="col-md-2">
                        <Link to='/clientaccessrequest'><img className="home-icons" src="images/Request_Client_TeamsAccess.png" alt="Request_Client_TeamsAccess" /></Link>
                    </div>

                    <div className="col-md-2">
                        <Link to='/clientteamsrequests'><img className="home-icons" src="images/RequestList_Client_TeamsAccess.png" alt="RequestList_Client_TeamsAccess" /></Link>
                    </div>

                </div>

                <div className="menu-row mt-30 mb-30">
                    <div className="row">
                        <div className="col-md-2">
                            <Link to='/delegates'><img className="home-icons" src="images/Manage_Delegates.png" alt="Manage_Delegates" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to='/offices'><img className="home-icons" src="images/Manage_Offices.png" alt="Manage_Offices" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{ pathname: '/systemconfig/auditlogs', state: { type: 'auditlog' } }}><img className="home-icons" src="images/View_Audit_Logs.png" alt="View_Audit_Logs" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{ pathname: '/systemconfig/emailwhitelist', state: { type: 'whitelist' } }}><img className="home-icons" src="images/Manage_Whitelist.png" alt="Manage_Whitelist" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{ pathname: '/systemconfig/emailblacklist', state: { type: 'blacklist' } }}><img className="home-icons" src="images/Manage_Blacklist.png" alt="Manage_Blacklist" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to='/clientguestaccounts'><img className="home-icons" src="images/View_Client_Accounts.png" alt="View_Client_Accounts" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class MemeberFirmDelegates extends React.Component {

    render() {
        return (
            <div className="menu-row">
                <div className="row">
                    <div className="col-md-2">
                        <Link to='/accountmanagement'><img className="home-icons" src="images/Manage_MF_Accounts.png" alt="Manage_MF_Accounts" /></Link>
                    </div>
                </div>
            </div>
        )
    }
}


class AdminDelegateOutsider extends React.Component {

    render() {
        return (
            <div className="menu-row">
                <div className="row">
                    <div className="col-md-2">
                        <Link to='/accountmanagement'><img className="home-icons" src="images/Manage_MF_Accounts.png" alt="Manage_MF_Accounts" /></Link>
                    </div>
                </div>

                <div className="menu-row mt-30 mb-30">
                    <div className="row">
                        <div className="col-md-2">
                            <Link to='/delegates'><img className="home-icons" src="images/Manage_Delegates.png" alt="Manage_Delegates" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to='/offices'><img className="home-icons" src="images/Manage_Offices.png" alt="Manage_Offices" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{ pathname: '/systemconfig/auditlogs', state: { type: 'auditlog' } }}><img className="home-icons" src="images/View_Audit_Logs.png" alt="View_Audit_Logs" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{ pathname: '/systemconfig/emailwhitelist', state: { type: 'whitelist' } }}><img className="home-icons" src="images/Manage_Whitelist.png" alt="Manage_Whitelist" /></Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{ pathname: '/systemconfig/emailblacklist', state: { type: 'blacklist' } }}><img className="home-icons" src="images/Manage_Blacklist.png" alt="Manage_Blacklist" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class UserNotDelegateNotOnList extends React.Component {
    render() {
        return (
            <div className="menu-row">

                <div className="row">
                    <div className="col-md-2">
                        <Link to='/assignteams'><img className="home-icons" src="images/Request_MF_TeamsAccess.png" alt="Request_MF_TeamsAccess" /></Link>
                    </div>
                    <div className="col-md-2">
                        <Link to='/teamsrequests'><img className="home-icons" src="images/RequestList_MF_TeamsAccess.png" alt="RequestList_MF_TeamsAccess" /></Link>
                    </div>
                </div>
            </div>
        )
    }
}

class UserNotDelegateOnList extends React.Component {
    render() {
        return (
            <div className="menu-row">
                <div className="row">
                    <div className="col-md-2">
                        <Link to='/assignteams'><img className="home-icons" src="images/Request_MF_TeamsAccess.png" alt="Request_MF_TeamsAccess" /></Link>
                    </div>
                    <div className="col-md-2">
                        <Link to='/teamsrequests'><img className="home-icons" src="images/RequestList_MF_TeamsAccess.png" alt="RequestList_MF_TeamsAccess" /></Link>
                    </div>
                    <div className="col-md-2">
                        <Link to='/clientaccessrequest'><img className="home-icons" src="images/Request_Client_TeamsAccess.png" alt="Request_Client_TeamsAccess" /></Link>
                    </div>
                    <div className="col-md-2">
                        <Link to='/clientteamsrequests'><img className="home-icons" src="images/RequestList_Client_TeamsAccess.png" alt="RequestList_Client_TeamsAccess" /></Link>
                    </div>
                </div>
            </div>
        )
    }
}

class Home extends React.Component {

    state = {
        protivitiClients: []
    };

    async componentDidMount() {
        await this.fetchProtivitiClients();
    }

    fetchProtivitiClients = async () => {
        const url = config.azRedirectUri + '/api/Config/protiviticlients';
        apiGet(url)
            .then((response) => {
                this.setState({ protivitiClients: response.data });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    isProtivitiRH(email) {
        const domains = ["roberthalf.com", "roberthalf.net", "protiviti.com"];
        const domain = email.split('@')[1];
        return domains.includes(domain);
    }

    extendAlert() {
        return (
            this.props.sharedReducer.extendAlert ?
                <div className='alert alert-success' role='alert'>
                    Success! Expiration date for <b>{this.props.sharedReducer.extendAlert}</b> is extended to 90 days from today.
                </div> : null
        );
    }

    render() {

        return (
            <div>
                {this.props.userReducer.currentUser && this.props.userReducer.currentUser.emailAddress &&
                    <div className='home mt-20'>
                        <div className="row">
                            <div className="col-md-1">
                            </div>
                            <div className="col-md-10">
                                {this.extendAlert()}
                                <h1>Hello, {this.props.userReducer.currentUser.firstName}!</h1>

                                <div className="menu">
                                    {this.props.userReducer.currentUser.isAdmin && this.isProtivitiRH(this.props.userReducer.currentUser.emailAddress) &&
                                        <AdminDashboard />
                                    }

                                    {this.props.userReducer.currentUser.isAdmin && this.props.userReducer.currentUser.isDelegate && !this.isProtivitiRH(this.props.userReducer.currentUser.emailAddress) &&
                                        <AdminDelegateOutsider />
                                    }

                                    {!this.props.userReducer.currentUser.isAdmin && !this.props.userReducer.currentUser.isDelegate && this.isProtivitiRH(this.props.userReducer.currentUser.emailAddress) && !this.state.protivitiClients.includes(this.props.userReducer.currentUser.emailAddress) &&
                                        <UserNotDelegateNotOnList />
                                    }

                                    {!this.props.userReducer.currentUser.isAdmin && !this.props.userReducer.currentUser.isDelegate && this.isProtivitiRH(this.props.userReducer.currentUser.emailAddress) && this.state.protivitiClients.includes(this.props.userReducer.currentUser.emailAddress) &&
                                        <UserNotDelegateOnList />
                                    }

                                    {!this.props.userReducer.currentUser.isAdmin && this.props.userReducer.currentUser.isDelegate &&
                                        <MemeberFirmDelegates />
                                    }

                                    {!this.props.userReducer.currentUser.isAdmin && !this.props.userReducer.currentUser.isDelegate &&
                                        !this.state.protivitiClients.includes(this.props.userReducer.currentUser.emailAddress) && !this.isProtivitiRH(this.props.userReducer.currentUser.emailAddress) &&
                                        <Redirect to='/guestresource' />
                                    }
                                </div>
                                <div className="col-md-1">
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(Home);