import React, { Component } from 'react';
import '../Teams/TeamsRequests.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import ClientGuestAccountsActions from '../../components/Teams/ClientGuestAccountsActions';
import ClientGuestAccountsGrid from '../../components/Teams/ClientGuestAccountsGrid';
import ClientGuestAccountsFilter from '../../components/Teams/ClientGuestAccountsFilter';
import { getCurrentUser } from '../../actions';
import { getUsername } from '../../services/authService';

class ClientGuestAccounts extends Component {
    async componentDidMount() {
        let currentUser = await getUsername();
        const emailSuffix = currentUser.userName ? currentUser.userName.substring(currentUser.userName.lastIndexOf('@') + 1) : null
        const checkDrop = emailSuffix === 'protiviti.com' || emailSuffix === 'roberthalf.com' ? true : false
        if (!checkDrop) {
            return <Redirect to='/' />
        }
    }

    render() {
        return (
            <div className='home'>
                <h5 className='accountSearch'>Account Search</h5>
                <div className='actionAndFilter'>
                    <div className='teamsFilterItems'>
                        <div className='filterAndSearch filter-row'>
                            <div className='padding-right'>
                                <label className='proOfficeLabel'>Account Status:</label>
                                <ClientGuestAccountsFilter />
                            </div>
                        </div>
                    </div>
                    {/*<div className='accountActions'>*/}
                    {/*    <ClientGuestAccountsActions />*/}
                    {/*</div>*/}
                </div>
                <div className='accountGrid'>
                    <ClientGuestAccountsGrid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(ClientGuestAccounts);