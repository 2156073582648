import React from 'react';
import './RequestFilter.css';
import { connect } from 'react-redux';
import { getMdApprovalStatusFilter } from '../../actions';

class MDApprovalFilter extends React.Component {
    state = {
        many: true,
        mpending: true,
        mapproved: true,
        mrejected: true,
        expired: true
    }

    onChange = (event) => {
        if (event.target.value === 'many') {
            if (!this.state.many) {
                this.setState({ many: true, mpending: true, mapproved: true, mrejected: true, expired: true }, () => {
                    let filter = {
                        many: this.state.many,
                        mpending: this.state.mpending,
                        mapproved: this.state.mapproved,
                        mrejected: this.state.mrejected,
                        expired: this.state.expired
                    }
                    this.props.getMdApprovalStatusFilter(filter);
                });
            }
            else {
                this.setState({ many: false, mpending: false, mapproved: false, mrejected: false, expired: false }, () => {
                    let filter = {
                        many: this.state.many,
                        mpending: this.state.mpending,
                        mapproved: this.state.mapproved,
                        mrejected: this.state.mrejected,
                        expired: this.state.expired
                    }
                    this.props.getMdApprovalStatusFilter(filter);
                });
            }
        }

        if (event.target.value === 'mpending' || event.target.value === 'mapproved' || event.target.value === 'mrejected' || event.target.value === 'expired') {
            let status = ((event.target.value === 'mpending') ? !this.state.mpending : ((event.target.value === 'mapproved') ? !this.state.mapproved : ((event.target.value === 'mrejected') ? !this.state.mrejected : ((event.target.value === 'expired') ? !this.state.expired : !this.state.expired))));
            this.setState({ [event.target.value]: status }, () => {
                if (this.state.many) {
                    this.setState({ many: false }, () => {
                        let filter = {
                            many: this.state.many,
                            mpending: this.state.mpending,
                            mapproved: this.state.mapproved,
                            mrejected: this.state.mrejected,
                            expired: this.state.expired
                        }
                        this.props.getMdApprovalStatusFilter(filter);
                    });
                }
                else if (this.state.mpending && this.state.mapproved && this.state.mrejected && this.state.expired) {
                    this.setState({ many: true }, () => {
                        let filter = {
                            many: this.state.many,
                            mpending: this.state.mpending,
                            mapproved: this.state.mapproved,
                            mrejected: this.state.mrejected,
                            expired: this.state.expired
                        }
                        this.props.getMdApprovalStatusFilter(filter);
                    });
                }
                else {
                    let filter = {
                        many: this.state.many,
                        mpending: this.state.mpending,
                        mapproved: this.state.mapproved,
                        mrejected: this.state.mrejected,
                        expired: this.state.expired
                    }
                    this.props.getMdApprovalStatusFilter(filter);
                }
            });
        }

    }

    render() {
        return (
            <div className='filter'>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='many' value='many' checked={this.state.many} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='many'>Any</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='mpending' value='mpending' checked={this.state.mpending} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='mpending'>Pending</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='mapproved' value='mapproved' checked={this.state.mapproved} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='mapproved'>Approved</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='mrejected' value='mrejected' checked={this.state.mrejected} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='mrejected'>Rejected</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='expired' value='expired' checked={this.state.expired} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='expired'>Expired</label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { getMdApprovalStatusFilter })(MDApprovalFilter);