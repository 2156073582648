import React from 'react';
import { withRouter } from 'react-router-dom';
import { displayPortalHelp } from '../actions';
import { connect } from 'react-redux';
import '../App.css';

class AppContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            const result = !['/clientaccessrequest', '/clientteamsrequests', '/clientguestaccounts'].includes(location.pathname);
            this.props.displayPortalHelp(result);
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }
    render() {
        return (
            <div className='appBody'>{this.props.children}</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { displayPortalHelp })(withRouter(AppContainer));