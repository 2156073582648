import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/16532572-d567-4d67-8727-f12f7bb6aed3',
        clientId: 'd0ed6353-0629-41f8-b4c9-3f96fea8558c',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        tenant: '16532572-d567-4d67-8727-f12f7bb6aed3',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: ['d0ed6353-0629-41f8-b4c9-3f96fea8558c/.default']
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}