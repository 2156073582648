import React from 'react';
import './AddEditCompany.css';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { fetchCompany } from '../../actions';
import { withRouter } from 'react-router-dom';
import { apiGet, apiPut, apiPost } from '../../services/apiService';

//AddCompany & EditCompany shares this component
class AddEditCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: '',
            users: [],
            selectedUser: null,
            complianceLevel: [],
            selectedComplianceLevel: null,
            disableButtons: false,
            modal: false,
            isDeleted: false,
            errorMessage: null,
            isReload: false

        }
    }
    componentDidMount = async () => {
        let pageType = this.props.location.pathname === '/editcompany' ? "edit" : "add";
        if (this.props.location.pathname === '/editcompany' && !this.props.company) {
            <Redirect to ='/companies' />
        }
        else {
            this.mounted = true;
            this.fetchData();
            this.fetchUsers(pageType);
        }
    }

    componentDidUpdate() {
        if (!this.props.userReducer.currentUser.isAdmin) {
            this.mounted = false;
            <Redirect to='/' />;
        }
        else {
            this.mounted = true;
        }
    }

    fetchUsers = async (pageType) => {
        let companyOption = this.props.company;
        let url = '/api/User';
        await apiGet(url)
            .then((response) => {
                let selectedOption = null;
                let company = null;
                let isDeleted = false;
                if (companyOption) {
                    selectedOption = response.data.find(x => {
                        return x.key === companyOption.managingDirectorKey
                    });
                    company = companyOption.name;
                    isDeleted = companyOption.isDeleted;
                }
                this.setState({
                    users: response.data,
                    isDeleted: isDeleted,
                    company: company,
                    selectedUser: selectedOption
                }, () => console.log('users: ' + JSON.stringify(this.state.users)));
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    onUserChange = selectedOption => {
        this.setState({ selectedUser: selectedOption });
    };
    onComplianceLevelChange = selectedOption => {
        this.setState({ selectedComplianceLevel: selectedOption });
    };
    onCompanyNameChange = (event) => {
        this.setState({ company: event.target.value });
    }
    onChangeisDeleted = (event) => {
        this.setState({ isDeleted: ((event.target.value === 'yes') ? true : false) });
    }
    
   /* componentDidUpdate() {
        this.mounted = true;
    }*/

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData() {
        let complevels = [
            {
                'level': ' ',
                'value': ' '
            },
            {
                'level': '1 - Low',
                'value': 1
            },
            {
                'level': '2 - Medium',
                'value': 2
            },
            {
                'level': '3 - High',
                'value': 3
            }
        ]
        this.setState({ complianceLevel: complevels })
        if (this.props.company) {
            let selectedComplianceLevel = complevels.find(x => {
                return x.value === this.props.company.complianceLevel
            })
            this.setState({ selectedComplianceLevel: selectedComplianceLevel })
        }
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    renderModal() {
        let companyName = this.props.company ? (this.props.company.name) : null
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader>{this.props.company ? 'Edit Company: Protiviti - ' + companyName : 'Add Company'}</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.company ? 'Company has been successfully saved!' : 'Company has been successfully added!'}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/companies'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    addUpdateCompany = async (event)=>{
        event.preventDefault();
        if (this.state.company && this.state.selectedUser) {
            let company = {
                'name': this.state.company,
                'complianceLevel': this.state.selectedComplianceLevel ? (this.state.selectedComplianceLevel.value !== ' ' ? this.state.selectedComplianceLevel.value : 0): 0,
                'managingDirectorKey': this.state.selectedUser.key,
                'isDeleted': this.state.isDeleted
            }

            if (this.props.company) {
                if ((this.props.company.name !== this.state.company) || (this.props.company.managingDirectorKey !== this.state.selectedUser.key) || (this.props.company.complianceLevel !== this.state.selectedComplianceLevel) || (this.props.company.isDeleted !== this.state.isDeleted)) {
                    this.setState({ disableButtons: true });
                    const url = '/api/Company/' + this.props.company.key;
                    await apiPut(url, company)
                        .then(() => {
                            this.props.fetchCompany();
                            this.toggleModal();
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
            }
            else {
                this.setState({ disableButtons: true });
                const url = '/api/Company'
                await apiPost(url, company)
                    .then(() => {
                        this.props.fetchCompany();
                        this.toggleModal();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }

        }
        else {
            this.setState({ errorMessage: 'All fields must be populated.' });
            setTimeout(() => {
                this.setState({ errorMessage: null });
            }, 5000);
        }
    }

    render() {
        const { selectedComplianceLevel} = this.state;
        const { selectedUser } = this.state;
        return (
            <form onSubmit={this.addUpdateCompany}>
                <div className='companyTitle'>
                    <h4>{this.props.company ? 'Edit Company' : 'Add Company'}</h4>
                    <h5>{this.props.company ? (this.props.company.name) : null}</h5>
                </div>
                <hr className='companyHr' />
                <div className='row'>
                    <div className='companyCol col-md-6'>
                        <label className='fieldLabels'>Company Name</label>

                        <input
                            type='text'
                            className='form-control'
                            defaultValue={this.state.company}
                            onChange={this.onCompanyNameChange}
                        />
                    </div>
                    <div className='companyCol col-md-6'>
                        <label className='fieldLabels'>Managing Director</label>
                        <Select
                            value={selectedUser}
                            onChange={this.onUserChange}
                            options={this.state.users}
                            getOptionLabel={(option) => option.displayName}
                            getOptionValue={(option) => option.key} />


                    </div>
                    <div className='companyCol col-md-6'>
                        <label className='fieldLabels'>Security Compliance Level</label>
                        <Select
                            value={selectedComplianceLevel}
                            onChange={this.onComplianceLevelChange}
                            options={this.state.complianceLevel}
                            getOptionLabel={(option) => option.level}
                            getOptionValue={(option) => option.value}
                        />

                    </div>
                    {this.props.company ? <div className='companyCol col-md-12'>
                        <label className='fieldLabels'>Company Deleted</label>
                        <div>
                            <div className="form-check form-check-inline deleteRadio">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="deleteYes" value="yes" checked={this.state.isDeleted} onChange={this.onChangeisDeleted} />
                                <label className="form-check-label" htmlFor="deleteYes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline deleteRadio">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="deleteNo" value="no" checked={!this.state.isDeleted} onChange={this.onChangeisDeleted} />
                                <label className="form-check-label" htmlFor="deleteNoc ">No</label>
                            </div>

                        </div>
                    </div>: null
                        }
                </div>
                <hr />
                <div className='companyButton'>
                    <Link to='/companies'><button type='button' className='btn btn-secondary' disabled={this.state.disableButtons}>Cancel</button></Link>
                    {this.props.company ?
                        <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Update</button> :
                        <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Add</button>}
                </div>
                <div className='errorMessage'>{this.state.errorMessage}</div>
                {this.renderModal()}


            </form>)
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default withRouter(connect(mapStateToProps, { fetchCompany })(AddEditCompany));
