import React from "react";
import "./ADInfoBody.css";

export default function ADInfoBody({ account, adGroups, ...props }) {
  if(!account)
    return null;

  return (
    <div>
      <p>
        <span>Guest Name:</span> {(account.lastName && account.firstName && `${account.lastName}, ${account.firstName}`) ?? account.memberFirmName} ({account.office ?? account.engagementName})
      </p>
      <p>
        <span>Email Address:</span> {account.emailAddress ?? account.email}
      </p>
      <p className="bottom-margin">
        <span>AAD Account:</span> {adGroups ? "Exists" : "Does not exist"}
      </p>
      {adGroups && (
        <>
          <p className="bold">AAD Group Membership</p>
          <div className="group-list">
            {adGroups.map((x, index) => (
              <p key={index}>{x}</p>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
