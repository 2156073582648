import React from 'react';
import '../ManageCompanies/ManageCompaniesGrid.css';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { fetchCompany } from '../../actions';
import { apiPut } from '../../services/apiService';

class ManageCompaniesGrid extends React.Component {
    data = [];

    constructor(props) {
        super(props);
        this.state = {
            companyOptions: [],
            companyOptionsDupe: [],
            deleteModal: false,
            deletingCompany: {},
            isReload: false,
            totalRows: 0,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortOrder: "desc",
            filterText: "",
            dataLoading: true,
            action: ""
        }
    }
    componentDidMount() {
        this.mapCompanies();
    }
    componentDidUpdate() {
        this.mapCompanies();
    }

    mapCompanies() {
        if (this.state.companyOptions.length !== this.props.sharedReducer.companyOptions.length && this.state.filterText === "") {
            let companies = this.assembleCompanies(this.props.sharedReducer.companyOptions).map((x, index) => ({ ...x, action: this.renderActions(this.props.sharedReducer.companyOptions[index]) }));
            this.setState({ companyOptions: companies, companyOptionsDupe: companies, dataLoading: false });
        }
    }

    assembleCompanies(response) {
        let allCompanies = response.map((x) => {
            return ({
                'name': 'Protiviti ' + x.name,
                'managingDirector': x.managingDirector.displayName,
                'securityComplianceLevel': this.getComplianceLevelLabel(x.complianceLevel),
                'isDeleted': (x.isDeleted ? 'Yes' : 'No'),
                
            })
        });
        return allCompanies;

    }

    handlePageChange = page => {
        //this.fetchDelegates(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        //this.fetchDelegates(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    //handleSortChange = (c, d) => {
    //    this.setState({ sortBy: c.selector, sortOrder: d }, () => {
    //        this.fetchDelegates(this.state.currentPage);
    //    });
    //}

    handleFilterChange = (e) => {
        let origData = this.state.companyOptionsDupe;
        let filter = e.target.value.toLowerCase();
        if (filter !== "") {
            origData = origData.filter(x => x.name.toLowerCase().includes(filter) || x.managingDirector.toLowerCase().includes(filter) || x.securityComplianceLevel.toLowerCase().includes(filter) || x.isDeleted.toLowerCase().includes(filter));
        }

        this.setState({ filterText: e.target.value, companyOptions: origData });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "", companyOptions: this.state.companyOptionsDupe });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    getComplianceLevelLabel(name) {
        let compLabel = '';
        switch (name) {
            case 0:
                compLabel = ' ';
                break;
            case 1:
                compLabel = '1 - Low';
                break;
            case 2:
                compLabel = '2 - Medium';
                break;
            case 3:
                compLabel = '3 - High';
                break;
        }
        return (compLabel);
    }
    renderActions(company) {
        return (
            <div className='dropdownAction'>
                <MDBDropdown dropleft>
                    <MDBDropdownToggle color="none">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <Link to={{ pathname: '/editcompany', state: { companyInfo: company } }} style={{ color: 'black', textDecoration: 'none' }}><MDBDropdownItem>Edit</MDBDropdownItem></Link>
                        {!company.isDeleted ? <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleDeleteModal(company)}><MDBDropdownItem>Delete</MDBDropdownItem></span> : null}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        );
    }
    deleteCompany = () => {
        const url = '/api/Company/delete/' + this.state.deletingCompany.key;
        apiPut(url, null)
            .then(() => {
                this.setState({ isReload: false });
                this.props.fetchCompany();
                let newCompanys = this.props.sharedReducer.companyOptions;
                let deletedCompanyIndex = newCompanys.findIndex(x => {
                    return x.key === this.state.deletingCompany.key
                });
                newCompanys[deletedCompanyIndex].isDeleted = true;
                let companies = this.assembleCompanies(newCompanys).map((x, index) => ({ ...x, action: this.renderActions(newCompanys[index]) }));
                this.setState({ companyOptions: companies, companyOptionsDupe: companies });
                this.toggleDeleteModal(null);
            })
            .catch(function (error) {
                if (error) {
                    console.log(error)
                    if (error.response.status === 401 && !this.state.isReload) {
                        this.setState({ isReload: true });
                        this.deleteCompany();
                    }
                    console.log(error);
                }
            })
    }

    toggleDeleteModal = (company) => {
        let isDelete = !this.state.deleteModal
        this.setState({
            deleteModal: isDelete,
            deletingCompany: company
        });
    }

    deleteModal() {
        let companyName = this.state.deletingCompany ? 'Protiviti - ' + this.state.deletingCompany.name : null
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggleDeleteModal(null)}>
                    <MDBModalHeader toggle={() => this.toggleDeleteModal(null)}>Delete Company: {companyName}</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete this Company?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.toggleDeleteModal(null)}>No</MDBBtn>
                        <MDBBtn color="primary" onClick={this.deleteCompany}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    render() {
        const columns = [{ name: 'Name', selector: row => row.name, sortable: true },
            { name: 'Managing Director', selector: row => row.managingDirector, sortable: true },
            { name: 'Security Compliance Level', selector: row => row.securityComplianceLevel, sortable: true },
            { name: 'Deleted', selector: row => row.isDeleted, sortable: true },
            { name: 'Edit', cell: row => row.action, allowOverflow: true, button: true }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',

                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };

        return (
            <div>
                <DataTable
                    columns={columns}
                    data={this.state.companyOptions}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    subHeader
                    //sortServer
                    progressPending={this.state.dataLoading}
                    //onSort={this.handleSortChange}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    paginationTotalRows={this.state.totalRows}
                    paginationPerPage={this.state.perPage}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={{ noRowsPerPage: false }}
                />
                {this.deleteModal()}
            
            </div>
        );
    }
    
}
const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { fetchCompany })(ManageCompaniesGrid);