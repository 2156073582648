import React from 'react';
import { Link } from 'react-router-dom';
import './ManageCompanies.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import ManageCompaniesGrid from '../../components/ManageCompanies/ManageCompaniesGrid';

class ManageCompanies extends React.Component {
    componentDidUpdate() {
        if (!this.props.userReducer.currentUser.isAdmin) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div className='manageOffices'>
                <div className='iconRow'>
                    <h5>Manage Companies</h5>
                    <div className='iconContainer'>
                        
                        <span className='addOffice'>
                            <Link to='/addCompany'>
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                                <div>Add Company</div>
                            </Link>
                        </span>
                    </div>
                </div>
                <div>
                    <ManageCompaniesGrid />
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, {})(ManageCompanies);