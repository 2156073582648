import React from 'react';
import './Footer.css';

class Footer extends React.Component {
    render() {
        return (

            <div className="footer">
                <div>
                    <img className="protiviti_logo" src="images/protivitiLogo.png" alt="logo" />
                </div>
                <div className="legal">
                    <span>© <span id="cpy_dt"></span> Protiviti, Inc. All Rights Reserved.</span>
                    <br />
                    You are accessing a private Protiviti computer system which contains Protiviti proprietary and confidential
                    information. All usage and access of this system may be monitored, recorded, and reviewed by Protiviti, at its
                    sole discretion, except as expressly prohibited by applicable law. Unauthorized access or usage is strictly
                    prohibited, and violators are subject to civil liabilities and criminal prosecution. You consent to the
                    collection, storage, transfer, and use of your personal details worldwide as needed to enable your access and
                    use of this service.
                   <br />
                </div>
            </div>

        );
    }
}
export default Footer;