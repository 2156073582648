import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'mdbreact';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import React from 'react';
import { Link } from 'react-router-dom';
import './BulkExtend.css';
import * as XLSX from 'xlsx/xlsx.mjs';
import { CSVLink } from 'react-csv';
import config from '../../config';
import { apiGet, apiPost } from '../../services/apiService';

export default class BulkExtend extends React.Component {
    accountStatusValue = {
        0: 'Active',
        1: 'Expired',
    };

    constructor(props) {
        super(props)
        this.state = {
            fileName: '',
            csvFile: null,
            modal: false, 
            updatingModal: false,
            error: false,
            dataProcessing: false,
            headers: [
                { label: 'First Name', key: 'firstName' },
                { label: 'Last Name', key: 'lastName' },
                { label: 'Office', key: 'office' },
                { label: 'Email Address', key: 'emailAddress' },
                { label: 'Status', key: 'accountStatus' },
                { label: 'Expiration Date', key: 'expirationDate' },
                { label: 'Extend User (Mark with X)', key: 'isExtending' },
            ],
            accounts: [],
        };
    
        this.handleUpload = this.handleUpload.bind(this)
    }

    componentDidMount() {
        if (this.props.location.state) {
            if (this.props.location.state.redirectError) {
                this.setState({ error: true });
                this.props.location.state.redirectError = false;
                setTimeout(() => {
                    this.setState({ error: false });
                }, 5000);
            }
        }

        this.fetchDataByUser();    
    }

    fetchDataByUser = async () => {
        let url = config.azRedirectUri + '/api/Accounts';
        this.fetchData(url);
    }

    fetchData = async (url) => {
        await apiGet(url)
            .then((response) => {
                let sortedResponse = response.data;
                let accounts = this.assembleAccounts(sortedResponse).map((x, index) => ({ ...x }));
                this.setState({ accounts: accounts, totalRows: response.data.count });
            })
    }

    assembleAccounts = (response) => {
        let allAccounts = response.filter(x => x.accountStatus === 0 || x.accountStatus === 1).map((x) => {
            return ({
                'firstName': x.firstName,
                'lastName': x.lastName,
                'office': x.office,
                'emailAddress': x.emailAddress,
                'accountStatus': this.accountStatusValue[x.accountStatus],
                'expirationDate': x.expirationDate ? x.expirationDate.substring(0, 10) : x.expirationDate,
                'isExtending': null,
            })
        });
        return allAccounts;
    }

    handleXLSX() {   
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
           
            const csdemo = XLSX.utils.sheet_to_csv(ws, { FS: ",", RS: "\n", blankrows: false })
           
            this.uploadFile(csdemo)
        };
        if (rABS) {
            reader.readAsBinaryString(this.state.csvFile);
        } else {
            reader.readAsArrayBuffer(this.state.csvFile);
        };
    }
    
    handleUpload(event) {
        this.setState({ dataProcessing: true })
        if (this.state.csvFile === null) {
            alert('Only CSV files are allowed.  Please Select a File to Upload')
            this.setState({ dataProcessing: false })
        }
        else {
            const ext = this.state.fileName.split('.')
            if (ext[1] === 'csv')
            {
                this.uploadFile(this.state.csvFile)
            }
            //else if (ext[1] === 'xlsx' || ext[1] === 'xls')
            //{
            //    this.handleXLSX()
            //}
            else
                //alert('Only CSV files are allowed.  Please Select a Valid File')
                this.setState({ dataProcessing: false, error: true })
        }
    }
   
    toggleModal() {
        this.setState({ modal: !this.state.modal })
    }

    toggleUpdatingModal() {
        this.setState({ updatingModal: !this.state.updatingModal })
    }

    renderSuccessModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal()} backdrop={false}>
                    <MDBModalHeader>Extend Multiple Users</MDBModalHeader>
                    <MDBModalBody>
                        Upload Successful!  Processing the file could take up to 5 minutes.  You will receive an email when users have been extended.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/accountmanagement'> <MDBBtn color="primary">Ok</MDBBtn></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
            )
    }

    //renderUpdatingModal() {
    //    return (
    //        <MDBContainer>
    //            <MDBModal isOpen={this.state.updatingModal} toggle={() => this.toggleUpdatingModal()} backdrop={false}>
    //                <MDBModalHeader>Extend Multiple Users</MDBModalHeader>
    //                <MDBModalBody>
    //                    Uploading file could take up to 1 minute, please wait...
    //                </MDBModalBody>
    //            </MDBModal>
    //        </MDBContainer>
    //    )
    //}

    uploadFile = async () => {
        const url = '/api/Accounts/BulkExtend';
        let formData = new FormData();
        formData.append("file", this.state.csvFile);
        console.log("file: " + JSON.stringify(formData));
        this.toggleUpdatingModal();
        await apiPost(url, formData, { "Content-Type": "multipart/form-data" }
            )
            .then(() => {
                this.toggleUpdatingModal();
                this.setState({ dataProcessing: false })
                this.toggleModal();
            })
            .catch(() => {
                this.toggleUpdatingModal();
                this.setState({ dataProcessing: false, error: true })
                setTimeout(() => {
                    this.setState({ error: false });
                }, 5000);        
            })
    }

    onChange = e => {
        switch (e.target.name) {
            case 'selectedFile':
                if (e.target.files.length > 0) {
                    console.log("file: " + e.target.files[0] + " FileSize: " + e.target.files[0].size)
                    this.setState({
                        fileName: e.target.files[0].name,
                        csvFile: e.target.files[0],
                    });
                } else {
                    console.log("no file selected");
                }

                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    };

    render() {
        /*const SheetJSFT = ["xlsx",  "xls",  "csv"].map(function (x) { return "." + x; }).join(",");*/
        const SheetJSFT = ["csv"].map(function (x) { return "." + x; }).join(",");
        const { fileName } = this.state;
        let file = null;

        file = fileName
            ? (<span>{fileName}</span>)
            : (<span>Choose a file...</span>);

        return (
            <div className='container extendContainer'>
                <div className='extendGrid'>
                    <h4 className='extendTitle'>Extend Multiple Users</h4>
                    <div className="dwnldBtn">
                        <CSVLink className={this.state.accounts === false && "not-ready"} data={this.state.accounts} headers={this.state.headers} filename={'ExportResults.csv'}>
                            <FontAwesomeIcon icon={faArrowDown} size='lg' />
                            <div>Download Active and Expired User List</div>
                        </CSVLink>
                    </div>

                    <div className="uploadForm">
                        <div className="custom-file">
                            <input className="custom-file-input"
                                id="file"
                                type="file"
                                name="selectedFile"
                                onChange={this.onChange}
                                accept={SheetJSFT} 
                            />
                            <label className="custom-file-label" htmlFor="file">{file}</label>
                        </div>
                    </div>

                    <div className='extendButtons'>
                        <Link to='/accountmanagement'><button type='button' className='btn btn-secondary' disabled={this.state.updatingModal}>Cancel</button></Link>
                        <button className='btn btn-primary updateButton' onClick={(e) => this.handleUpload(e)} disabled={this.state.updatingModal}>Upload</button>
                    </div>
                    {this.state.error ? <div className='errorMessage' style={{ marginTop: '20px' }}>Error: Invalid file type. Please convert the file to CSV format and reupload it.</div> : null}
                    {this.renderSuccessModal()}
                </div>
            </div>
        )
    }
}
