import React from 'react';
import './AddEditOffice.css';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '../../config';
import Select from 'react-select';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { fetchOffice } from '../../actions';
import { withRouter } from 'react-router-dom';
import { apiGet, apiPut, apiPost } from '../../services/apiService';

//AddOffice & EditOffice shares this component
class AddEditOffice extends React.Component {
    state = {
        company: [],
        selectedCompany: null,
        country: [],
        selectedCountry: null,
        users: [],
        selectedUser: null,
        office: '',
        city: '',
        officeName: '',
        disableButtons: false,
        modal: false,
        isDeleted: false,
        addCompanyModal: false,
        deleteCompanyModal: false,
        errorModal: false,
        addCompany: '',
        errorMessage: null,
        isReload: false
    };

    componentDidMount() {
        if (this.props.location.pathname === '/editoffice' && !this.props.office) {
            <Redirect to='/offices' />;
        }
        else {
            this.mounted = true;
            this.fetchData();
            if (this.props.office) {
                this.setState({ city: this.props.office.city });
                this.setState({ officeName: this.props.office.name });
                this.setState({ isDeleted: this.props.office.isDeleted });
            }
        }
    }

    componentDidUpdate() {
        if (!this.props.userReducer.currentUser.isAdmin) {
            this.mounted = false;
            <Redirect to='/' />;
        }
        else {
            this.mounted = true;
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData = async () => {
        await this.fetchCompany();
        await this.fetchCountry();
        await this.fetchUsers();
    }

    fetchCompany = () => {
        const url = config.azRedirectUri + '/api/Company';
        apiGet(url)
            .then((response) => {
                if (this.mounted) {
                    this.setState({ isReload: false });
                    this.setState({ company: response.data.filter(x => x.isDeleted === false) });
                    if (this.props.office) {
                        let selectedOption = response.data.find(x => {
                            return x.name === this.props.office.company;
                        });
                        this.setState({ selectedCompany: selectedOption });
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 401 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.fetchCompany();
                }
                console.log(error.response.data);
                this.setState({ errorMessage: error.response.data }, () => this.toggleErrorModal());
            })
    }

    fetchCountry = () => {
        const url = config.azRedirectUri + '/api/Country';
        apiGet(url)
            .then((response) => {
                if (this.mounted) {
                    this.setState({ isReload: false });
                    this.setState({ country: response.data });
                    if (this.props.office) {
                        let selectedOption = response.data.find(x => {
                            return x.name === this.props.office.country;
                        });
                        this.setState({ selectedCountry: selectedOption });
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 401 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.fetchCountry();
                }
                console.log(error.response.data);
                this.setState({ errorMessage: error.response.data }, () => this.toggleErrorModal());
            })
    }

    fetchUsers = () => {
        const url = config.azRedirectUri + '/api/User';
        apiGet(url)
            .then((response) => {
                if (this.mounted) {
                    this.setState({ isReload: false });
                    this.setState({ users: response.data });
                    if (this.props.office) {
                        let selectedOption = response.data.find(x => {
                            return x.displayName === this.props.office.officeManager;
                        });
                        this.setState({ selectedUser: selectedOption });
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 401 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.fetchUsers();
                }
                console.log(error.response.data);
                this.setState({ errorMessage: error.response.data }, () => this.toggleErrorModal());
            })
    }

    onCompanyChange = selectedOption => {
        this.setState({ selectedCompany: selectedOption });
    };

    onCountryChange = selectedOption => {
        this.setState({ selectedCountry: selectedOption });
    };

    onUserChange = selectedOption => {
        this.setState({ selectedUser: selectedOption });
    };

    onCityChange = (event) => {
        this.setState({ city: event.target.value });
    }

    onOfficeNameChange = (event) => {
        this.setState({ officeName: event.target.value });
    }

    onChangeisDeleted = (event) => {
        this.setState({ isDeleted: ((event.target.value === 'yes') ? true : false) });
    }

    onAddCompanyChange = (event) => {
        this.setState({ addCompany: event.target.value });
    }

    addUpdateOffice = async (event) => {
        event.preventDefault();
        if (this.state.selectedCompany && this.state.selectedCountry && this.state.selectedUser && this.state.city && this.state.officeName) {
            let office = {
                'city': this.state.city,
                'companyKey': this.state.selectedCompany.key,
                'countryKey': this.state.selectedCountry.key,
                'managerKey': this.state.selectedUser.key,
                'name': this.state.officeName,
                'isDeleted': this.state.isDeleted,
                'departmentKey': '4B5D6DB6-DCC3-4772-BB8A-BD76FAED82F0' //departmentKey hardcoded for now
            };

            if (this.props.office) {
                if ((this.props.office.city !== this.state.city) || (this.props.office.company !== this.state.selectedCompany.name) || (this.props.office.country !== this.state.selectedCountry.name) || (this.props.office.managerKey !== this.state.selectedUser.key) || (this.props.office.isDeleted !== this.state.isDeleted) || (this.props.office.name !== this.state.officeName)) {
                    this.setState({ disableButtons: true });
                    const url = config.azRedirectUri + '/api/ProOffice/' + this.props.office.key;
                    await apiPut(url, office)
                        .then(() => {
                            this.props.fetchOffice();
                            this.toggleModal();
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                            this.setState({ errorMessage: error.response.data }, () => this.toggleErrorModal());
                        })
                }
            }
            else {
                this.setState({ disableButtons: true });
                const url = config.azRedirectUri + '/api/ProOffice';
                await apiPost(url, office)
                    .then(() => {
                        this.props.fetchOffice();
                        this.toggleModal();
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.setState({ errorMessage: error.response.data }, () => this.toggleErrorModal());
                    })
            }
        }
        else {
            this.setState({ errorMessage: 'All fields must be populated.' });
            setTimeout(() => {
                this.setState({ errorMessage: null });
            }, 5000);
        }
    }

    addCompany = async () => {
        if (this.state.addCompany) {
            this.setState({ disableButtons: true });
            let company = {
                'Name': this.state.addCompany
            }
            const url = '/api/Company';
            await apiPost(url, company)
                .then(() => {
                    this.setState({ isReload: false });
                    this.fetchCompany();
                    this.setState({ disableButtons: false });
                    this.setState({ addCompany: '' });
                    this.toggleAddCompanyModal();
                })
                .catch((error) => {
                    if (error.response.status === 401 && !this.state.isReload) {
                        this.setState({ isReload: true });
                        this.addCompany();
                    }
                    console.log(error.response.data);
                    this.setState({ errorMessage: error.response.data }, () => this.toggleErrorModal());
                })
        }
    }

    deleteCompany = async () => {
        this.setState({ disableButtons: true });
        const url = '/api/Company/delete/' + this.state.selectedCompany.key;
        await apiPut(url, null)
            .then(() => {
                this.setState({ isReload: false });
                this.fetchCompany();
                this.toggleDeleteCompanyModal();
                this.setState({ disableButtons: false });
                this.setState({ selectedCompany: null });
            })
            .catch((error) => {
                if (error.response.status === 401 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.deleteCompany();
                }
                console.log(error.response.data);
                this.setState({ errorMessage: error.response.data }, () => this.toggleErrorModal());
            })
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    renderModal() {
        let officeName = this.props.office ? (this.props.office.company + ' - ' + this.props.office.city) : null;
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader>{this.props.office ? 'Edit Office: ' + officeName : 'Add Office'}</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.office ? 'Office has been successfully saved!' : 'Office has been successfully added!'}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/offices'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    toggleAddCompanyModal = () => {
        this.setState({
            addCompanyModal: !this.state.addCompanyModal
        });
    }

    renderAddCompanyModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.addCompanyModal} toggle={this.toggleAddCompanyModal}>
                    <MDBModalHeader toggle={this.toggleAddCompanyModal}>Add Company</MDBModalHeader>
                    <MDBModalBody>
                        <input
                            type='text'
                            className='form-control'
                            defaultValue={this.state.addCompany}
                            onChange={(event) => this.onAddCompanyChange(event)} />
                        <span style={{ fontStyle: 'italic' }}>e.g. Protiviti MiddleEast</span>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type='button' className='btn btn-secondary' disabled={this.state.disableButtons} onClick={this.toggleAddCompanyModal}>Cancel</button>
                        <button type='button' className='btn btn-primary updateButton' disabled={this.state.disableButtons} onClick={this.addCompany} >Add</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    toggleDeleteCompanyModal = () => {
        if (this.state.selectedCompany) {
            this.setState({
                deleteCompanyModal: !this.state.deleteCompanyModal
            });
        }
    }

    renderDeleteCompanyModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.deleteCompanyModal} toggle={this.toggleDeleteCompanyModal}>
                    <MDBModalHeader toggle={this.toggleDeleteCompanyModal}>Delete Company</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete this company?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type='button' className='btn btn-secondary' disabled={this.state.disableButtons} onClick={this.toggleDeleteCompanyModal}>Cancel</button>
                        <button type='button' className='btn btn-primary updateButton' disabled={this.state.disableButtons} onClick={this.deleteCompany}>Delete</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    closeErrorModal = () => {
        this.setState({ disableButtons: false }, () => this.toggleErrorModal());
    }

    toggleErrorModal = () => {
        if (this.state.errorMessage) {
            this.setState({ errorModal: !this.state.errorModal });
        }
        
    }

    renderErrorModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.errorModal} toggle={this.toggleErrorModal}>
                    <MDBModalHeader toggle={this.toggleErrorModal}>Error</MDBModalHeader>
                    <MDBModalBody>
                        The following error has occured: {this.state.errorMessage}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type='button' className='btn btn-secondary' onClick={this.closeErrorModal}>Close</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    render() {
        const { selectedCompany } = this.state;
        const { selectedCountry } = this.state;
        const { selectedUser } = this.state;

        return (
            <form onSubmit={this.addUpdateOffice}>
                <div className='officeTitle'>
                    <h4>{this.props.office ? 'Edit Office' : 'Add Office'}</h4>
                    <h5>{this.props.office ? (this.props.office.company + ' - ' + this.props.office.city) : null}</h5>
                </div>
                <hr className='officeHr' />
                <div className='row'>
                    <div className='officeCol col-md-6'>
                        <label className='fieldLabels'>Company Name</label>
                        <div>
                            <Select
                                value={selectedCompany}
                                onChange={this.onCompanyChange}
                                options={this.state.company}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.key} />
                        </div>
                    </div>
                    <div className='officeCol col-md-6'>
                    </div>
                    <div className='officeCol col-md-6'>
                        <label className='fieldLabels'>Office Name</label>
                        <input
                            type='text'
                            className='form-control'
                            defaultValue={this.state.officeName}
                            onChange={(event) => this.onOfficeNameChange(event)}
                        />
                    </div>
                    <div className='officeCol col-md-6'>
                        <label className='fieldLabels'>City</label>
                        <input
                            type='text'
                            className='form-control'
                            defaultValue={this.state.city}
                            onChange={(event) => this.onCityChange(event)} />
                    </div>
                    <div className='officeCol col-md-6'>
                        <label className='fieldLabels'>Country</label>
                        <Select
                            value={selectedCountry}
                            onChange={this.onCountryChange}
                            options={this.state.country}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.key} />
                    </div>
                    <div className='officeCol col-md-6'>
                        <label className='fieldLabels'>Lead MD</label>
                        <div>
                            <Select
                                value={selectedUser}
                                onChange={this.onUserChange}
                                options={this.state.users}
                                getOptionLabel={(option) => option.displayName}
                                getOptionValue={(option) => option.key} />
                        </div>
                    </div>
                    {this.props.office ? <div className='officeCol col-md-6'>
                        <label className='fieldLabels'>Office Deleted</label>
                        <div>
                            <div className="form-check form-check-inline deleteRadio">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="deleteYes" value="yes" checked={this.state.isDeleted} onChange={this.onChangeisDeleted} />
                                <label className="form-check-label" htmlFor="deleteYes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline deleteRadio">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="deleteNo" value="no" checked={!this.state.isDeleted} onChange={this.onChangeisDeleted} />
                                <label className="form-check-label" htmlFor="deleteNoc ">No</label>
                            </div>
                        </div>
                    </div> : null}
                </div>
                <hr />
                <div className='officeButton'>
                    <Link to='/offices'><button type='button' className='btn btn-secondary' disabled={this.state.disableButtons}>Cancel</button></Link>
                    {this.props.office ?
                        <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Update</button> :
                        <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Add</button>}
                </div>
                <div className='errorMessage'>{this.state.errorMessage}</div>
                {this.renderModal()}
                {this.renderAddCompanyModal()}
                {this.renderDeleteCompanyModal()}
                {this.renderErrorModal()}
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default withRouter(connect(mapStateToProps, { fetchOffice })(AddEditOffice));