import { getToken } from '../services/authService';
import { getCookie } from '../services/cookieService'

const INITIAL_STATE = {
    //AuthorizationUpload: { Authorization: `Bearer ${await getToken()}`, 'Content-Type': undefined, 'X-XSRF-TOKEN': getCookie("X-REQUEST-TOKEN") },
    officeOptions: [],
    selectedOffice: [],
    selectedAddOffices: [],
    selectedOfficeKey: null,
    selectedAddCompany: [],
    selectedCompany: [],
    selectedCompanyKey: null,
    accountOptions: [],
    accountStatusFilter: {
        any: false,
        active: true,
        expired: true,
        pending: true,
        disabled: false
    },
    clientAccessFilter: {
        rany: false,
        active: true,
        pending: true,
        disabled: false
    },
    requestStatusFilter: {
        rany: false,
        open: true,
        closed: true,
        deleted: false
    },
    legalStatusFilter: {
        lany: true,
        lpending: true,
        lapproved: true,
        lrejected: true
    },
    mdStatusFilter: {
        many: true,
        mpending: true,
        mapproved: true,
        mrejected: true,
        expired: true
    },
    importData: {
        data: [],
        headers: true
    },
    emailWhitelist: [],
    /*emailBlacklist: [],
    emailsInBlackList: [],*/
    companyOptions: [],
    usersBulkAdded: [],
    users: [],
    extendAlert: null,
    displayServicePortalHelp: true
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'FETCH_OFFICE':
            return { ...state, officeOptions: action.payload };
        case 'UPDATE_OFFICE':
            return { ...state, selectedOffice: action.payload };
        case 'UPDATE_ADDOFFICE':
            return { ...state, selectedAddOffices: action.payload };
        case 'CLEAR_ADDOFFICE':
            return { ...state, selectedAddOffices: action.payload };
        case 'GET_OFFICEKEY':
            return { ...state, selectedOfficeKey: action.payload };
        case 'GET_ACCOUNTS':
            return { ...state, accountOptions: action.payload };
        case 'GET_FILTER_STATUS':
            return { ...state, accountStatusFilter: action.payload };
        case 'GET_CLIENT_ACCESS_FILTER_STATUS':
            return { ...state, clientAccessFilter: action.payload };
        case 'GET_REQUEST_FILTER_STATUS':
            return { ...state, requestStatusFilter: action.payload };
        case 'GET_LEGAL_APPROVAL_FILTER_STATUS':
            return { ...state, legalStatusFilter: action.payload };
        case 'GET_MD_APPROVAL_FILTER_STATUS':
            return { ...state, mdStatusFilter: action.payload };
        case 'IMPORT_USERS':
            return {
                ...state,
                importData: {
                    data: action.payload.data,
                    headers: action.payload.headers
                }
            }
        case 'FETCH_COMPANY':
            return { ...state, companyOptions: action.payload };
        case 'UPDATE_COMPANY':
            return { ...state, selectedCompany: action.payload };
        case 'UPDATE_ADDCOMPANY':
            return { ...state, selectedAddCompany: action.payload };
        case 'CLEAR_ADDCOMPANY':
            return { ...state, selectedAddCompany: action.payload };
        case 'GET_COMPANYKEY':
            return { ...state, selectedCompanyKey: action.payload };
        case 'FETCH_USERS':
            return { ...state, users: action.payload };
        case 'BULK_ADDED':
            return { ...state, usersBulkAdded: action.payload };
        case 'SHOW_EXTEND_ALERT':
            return { ...state, extendAlert: action.payload };
        case 'DISPLAY_PORTALHELP':
            return { ...state, displayServicePortalHelp: action.payload };
        default:
            return state;
    }
};