import React from 'react';
import './LegalApproval.css';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUsername } from '../../services/authService';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn } from 'mdbreact';
import { getCurrentUser } from '../../actions';
import { apiGet, apiPut, apiPost, apiDelete } from '../../services/apiService';

class LegalApproval extends React.Component {
    state = {
        requestData: {},
        guestUsersName: [],
        mfCompnayName: null,
        teamRequestInfo: {},
        canAccess: false,
        approveDisable: true,
        rejectDisable: true,
        saveDisable: false,
        modal: false,
        attachmentModal: false,
        approvalModal: false,
        modalMessage: null,
        errorMessage: null,
        commentArea: null,
        button: null,
        buttonDisable: false,
        submitButtonDisable: false,
        uploadFile: null,
        uploadButton: 'Upload File',
        fileCheck: false,
        legalStatus: null,
        isTextAreaDisabled: false,
        uploadDisable: true,
        displaySize: null,
        exceedCSS: null,
        attachmentList: null,
        legalApprovers: [],
        selectedRequestType: null,
        notRequiredButtonDisbled: false,
        expireDate: null,
        updatedRequest: null,
        hasRelated: false,
        requestType: [{ label: 'Client Engagement Site', value: 0 }, { label: 'Protiviti Training', value: 1 }, { label: 'Other', value: 2 }]
    };

    currentUser;

    async componentDidMount() {
        this.currentUser = await getUsername();
        this.props.getCurrentUser(this.currentUser.username);
        this.getLegalApprovers();
    }

    getLegalApprovers = () => {
        let url = config.azRedirectUri + '/api/TeamsLegal/AllApprovers';
        apiGet(url)
            .then(response => {
                let allApprovers = this.props.userReducer.currentUser.isAdmin ? config.legalApprover.concat(response.data) : response.data;
                //console.log(allApprovers, response.data, getUsername().username)
                this.setState({ legalApprovers: allApprovers });
                this.getRequest();
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.getLegalApprovers();
                }
                console.log(error);
            });
    }

    getRequest() {
        const requestKey = this.props.match.params.id;
        const validUsers = this.state.legalApprovers;
        if (!validUsers.filter(x => x.email === this.currentUser.username || x.loginAddress === this.currentUser.username).length > 0 || !requestKey) {
            <Redirect to='/error' />;
        }
        else {
            const url = config.azRedirectUri + '/api/TeamsLegal?request=' + requestKey;
            apiGet(url)
                .then((response) => {
                    const data = response.data;
                    // console.log(data);
                    if (data === '' || data.isDeleted === true) {
                        <Redirect to='/teamsrequests' />;
                    } else {
                        let status;
                        let buttonDisable = true;
                        let approveDisable = this.state.approveDisable;
                        let rejectDisabled = this.state.rejectDisable;
                        switch (data.legalApprovalStatus) {
                            case 0:
                                buttonDisable = false
                                status = 'Pending';
                                break;
                            case 1:
                            case 5:
                                status = 'Guests Permitted';
                                break;
                            case 2:
                                status = 'Guests Not Permitted';
                                break;
                            case 3:
                                status = 'Expired';
                                break;
                            case 4:
                                status = 'Not Required';
                                break;
                            default:
                                break;
                        }

                        let reqNames = data.guestAccessUsers.map(x => {
                            return x.memberFirmName
                        });
                        let names = '';
                        for (let i = 0; i < reqNames.length; i++) {
                            if (i === reqNames.length - 1) {
                                names = names + reqNames[i]
                            }
                            else {
                                names = names + reqNames[i] + ', '
                            }
                        }
                        let comment = this.state.commentArea !== null ? this.state.commentArea : data.legalComment;
                        let requestType = this.state.selectedRequestType !== null ? this.state.selectedRequestType : data.requestType;
                        let nRBtnDisabled = data.legalApprovalStatus === 4 ? true : false;
                        let expireDate = new Date(data.expireDate).toDateString();
                        this.setState({
                            requestData: data, commentArea: comment, memberFirmCompany: 'Protiviti - ' + data.memberFirmCompany.name, legalStatus: status, guestUsersName: names, selectedRequestType: requestType, approveDisable: approveDisable, notRequiredButtonDisbled: nRBtnDisabled, submitButtonDisable: buttonDisable, expireDate: expireDate
                        }, () => { this.toggleApproveButton(); });
                        this.renderAttachmentList();
                        this.getRelatedValue();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    getRelatedValue() {
        const requestKey = this.props.match.params.id;
        const url = config.azRedirectUri + '/api/Teams/hasrelated/' + requestKey;
        apiGet(url)
            .then((response) => {
                let hasRelated = response.data === 'Yes' ? true : false;
                this.setState({ hasRelated: hasRelated })
            });
    }

    getRequestTypeLabel(name) {
        let typeLabel = '';
        switch (name) {
            case 0:
                typeLabel = 'Client Engagement Site';
                break;
            case 1:
                typeLabel = 'Protiviti Training';
                break;
            case 2:
                typeLabel = 'Other';
                break;
            default:
                break;
        }
        return (typeLabel);
    }

    toggleApproveButton() {
        let approveDisable = true;
        let rejDisable = true;
        let saveDisable = this.state.requestData.legalApprovalStatus > 0 ? true : this.state.saveDisable;

        if (this.state.commentArea) {
            rejDisable = this.state.requestData.legalApprovalStatus > 0 ? true : false;
            if (this.state.selectedRequestType > 0 || (this.state.selectedRequestType === 0 && this.state.requestData.guestAccessLegalAttachments.length > 0)) {
                approveDisable = this.state.requestData.legalApprovalStatus > 0 ? true : false;
                saveDisable = false;
            }
        }

        this.setState({ approveDisable: approveDisable, rejectDisable: rejDisable, saveDisable: saveDisable });
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    renderModal() {
        let headerText = 'Completed'
        let bodyText = '';
        switch (this.state.button) {
            case 'save':
                headerText = 'Saved';
                bodyText = 'Comments saved'
                break;
            case 'approveOne':
            case 'approveAll':
                bodyText = 'Member Firm guests are Permitted to the TEAMS site.';
                break;
            case 'reject':
                bodyText = 'Member Firm guests are Not Permitted to the TEAMS site.';
                break;
            default:
                break;
        }
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader>Legal Review: {headerText}</MDBModalHeader>
                    <MDBModalBody>
                        {bodyText}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/teamsrequests'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    toggleAttachmentModal = () => { this.setState({ attachmentModal: !this.state.attachmentModal }); }

    renderAttachmentModal() {
        let fileDetails;
        if (this.state.fileCheck) {
            fileDetails =
                <div>
                    <h5>File Details:</h5>
                    <div>File Name: {this.state.uploadFile.name}</div>
                    <div>File Type: {this.state.uploadFile.type}</div>
                    <div className={this.state.exceedCSS}>File Size: {this.state.displaySize}</div>
                    <div>
                        Last Modified:{" "}
                        {this.state.uploadFile.lastModifiedDate.toDateString()}
                    </div>
                </div>
        } else {
            fileDetails =
                <div>
                    <br />
                    <h6>Choose before Pressing the Upload button. Max 25mb size limit.</h6>
                </div>
        }
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.attachmentModal} toggle={this.toggleAttachmentModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggleAttachmentModal}>File Upload</MDBModalHeader>
                    <MDBModalBody>
                        <input type="file" onChange={this.onFileChange} />
                        {fileDetails}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="button" className='btn btn-danger updateButton' onClick={this.onCancelUpload}>Cancel</MDBBtn>
                        <MDBBtn type="button" className='btn btn-primary updateButton' onClick={this.onFileUpload} disabled={this.state.uploadDisable}>{this.state.uploadButton}</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    onFileChange = (e) => {
        if (e.target.files.length > 0) {
            let disableUploadButton = false;
            let fileSize = e.target.files[0].size;
            let size = 0;
            let cssStyle = null;
            size = fileSize + ' byte';
            if (fileSize > 1000)
                size = (fileSize / 1024).toFixed(2) + ' kb';
            if (fileSize > 1000000)
                size = ((fileSize / 1024) / 1024).toFixed(2) + ' mb';
            if (fileSize > 26214000) {
                size = size + ' Exceeds 25 mb limit';
                cssStyle = 'exceed-limit'
                disableUploadButton = true;
            }
            this.setState({ uploadFile: e.target.files[0], fileCheck: true, uploadDisable: disableUploadButton, displaySize: size, exceedCSS: cssStyle });
        } else {
            this.setState({ uploadFile: null, fileCheck: false, uploadDisable: true })
        }
    }

    onFileUpload = async (e) => {
        e.preventDefault();
        this.setState({ uploadButton: 'Uploading...', uploadDisable: true })
        var url = config.azRedirectUri + '/api/TeamsLegal/';
        let form = new FormData();
        form.append('file', this.state.uploadFile);
        form.append('request', this.state.requestData.key)
        await apiPost(url, form, { "Content-Type": "multipart/form-data" })
            .then(() => {
                this.setState({ uploadButton: 'Upload File', uploadDisable: false });
                this.getRequest();
                this.renderAttachmentList();
                this.onCancelUpload();

                if (this.state.commentArea !== null && this.state.requestData.legalApprovalStatus === 0 && this.state.selectedRequestType >= 0)
                    this.setState({ approveDisable: false });
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    renderAttachmentList() {
        const list = this.state.requestData.guestAccessLegalAttachments.map((item, index) => {
            return (
                <div key={index} className='requestDetailsCol col-md-12'>
                    <div className='attachmentName floatLeft'>
                        <button type='button' className='unstyledButton' onClick={() => this.handleDownloadAttachment(item)}>{item.name}</button>
                        <button type='button' className='unstyledButton' onClick={() => this.handleDownloadAttachment(item)}><FontAwesomeIcon className='padding' icon={faSearch} size='lg' /></button>
                        <button type='button' className='unstyledButton' onClick={() => this.handleDeleteAttachment(item)} disabled={this.state.requestData.legalApprovalStatus === 0 ? false : true}><FontAwesomeIcon className='padding' icon={faTrash} size='lg' /></button>
                    </div>
                </div>
            );
        })
        this.setState({ attachmentList: list });
    }

    handleDownloadAttachment = async (e) => {
        const url = config.azRedirectUri + '/api/TeamsLegal/' + e.key;
        await apiGet(url, null, { responseType: 'arraybuffer' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', e.name);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleDeleteAttachment = async (e) => {
        const url = config.azRedirectUri + '/api/TeamsLegal/' + e.key;
        await apiDelete(url)
            .then(() => {
                this.getRequest();
                this.renderAttachmentList();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let updatedRequest = JSON.parse(JSON.stringify(this.state.requestData));
        updatedRequest.legalComment = this.state.commentArea;
        this.setState({ isTextAreaDisabled: true, modalMessage: 'saved' });
        console.log('button: ' + this.state.button)
        if (this.state.button === 'approveAll' || this.state.button === 'reject' || this.state.button === 'approveOne') {
            updatedRequest.legalStatusUpdated = new Date();
            updatedRequest.legalStatusUpdater = this.currentUser.username;
            updatedRequest.legalStatusUpdaterName = this.currentUser.name;
            updatedRequest.requestType = this.state.selectedRequestType;
            let status;
            switch (this.state.button) {
                case 'approveAll': // approve and future
                    updatedRequest.legalApprovalStatus = 1;
                    this.setState({ modalMessage: 'completed', updatedRequest: updatedRequest })
                    this.toggleApprovalModal()
                    break;
                case 'reject':
                    updatedRequest.legalApprovalStatus = 2;
                    updatedRequest.requestStatus = 1;
                    this.status = 'rejected';
                    break;
                case 'approveOne': // approve, this one only
                    updatedRequest.legalApprovalStatus = 5;
                    this.status = 'completed';
                    break;
                default:
                    break;
            }
        }
        if (this.state.button !== 'approveAll') {
            this.setState({ modalMessage: this.status, updatedRequest: updatedRequest }, () => {
                if (this.state.button === 'save') {
                    this.saveComment();
                } else {
                    this.submitRequest();
                }
            });
        }
    }

    submitRequest = async (e) => {
        if (this.state.approvalModal === true)
            this.toggleApprovalModal()

        this.setState({ approveDisable: true, rejectDisable: true, buttonDisable: true, submitButtonDisable: true })
        const url = config.azRedirectUri + '/api/TeamsLegal/Update';
        await apiPut(url, this.state.updatedRequest)
            .then(() => {
                //<Redirect to ='/teamsrequests' />
                this.toggleModal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    saveComment = async (e) => {
        if (this.state.approvalModal === true)
            this.toggleApprovalModal()

        this.setState({ approveDisable: true, rejectDisable: true, buttonDisable: true, submitButtonDisable: true })
        const url = config.azRedirectUri + '/api/TeamsLegal/SaveComment';
        await apiPut(url, this.state.updatedRequest)
            .then(() => {
                this.toggleModal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    toggleApprovalModal = () => { this.setState({ approvalModal: !this.state.approvalModal }); }

    renderApprovalModal() {
        const hasRelatedMessage = this.state.hasRelated ? this.renderRelatedMessage() : null;

        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.approvalModal} toggle={this.toggleApprovalModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggleApprovalModal}>Yes and Future Requests</MDBModalHeader>
                    <MDBModalBody>
                        <p>
                            In the future, this engagement team will be able to admit Member Firm users to this TEAMS Site with MD approval.  The contracts will not need to be reviewed again by legal.
                        </p>
                        {hasRelatedMessage}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' type='button' onClick={() => this.submitRequest()}>CONFIRM</button>
                        <button className='btn btn-primary updateButton' type='button' onClick={this.confirmNoClick}>GO BACK</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderRelatedMessage() {
        return (
            <p>
                <strong>Please Note:</strong> An attorney previously indicated that contracts should be re-reviewed for further requests to the TEAMS site.  Please confirm that is no longer necessary.
            </p>
        )
    }

    confirmNoClick = () => {
        this.toggleApprovalModal();
    }

    handleRequestTypeChange = e => {
        this.setState({ selectedRequestType: e.value }, () => { this.toggleApproveButton(); });
    }

    handleCommentChange = e => {
        this.setState({ commentArea: e.target.value }, () => { this.toggleApproveButton(); });
    }

    onCancelUpload = () => {
        this.setState({ fileCheck: false, uploadDisable: true, uploadFile: null });
        this.toggleAttachmentModal();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} >
                <div className='container requestDetailContainer'>
                    <div className='form-group'>
                        <div className='requestDetailsTitle'>
                            <h4>Legal Review for Member Firm Guest Access to a Protiviti TEAMS Site</h4>
                            <p>After you have reviewed the relevant contracts please use the buttons below to submit your recommendation about whether it's OK for the member firm users to access the engagement information via TEAMS.  You may optionally add comments or attachments for the record.  Contact Ashley Hartley with questions.</p>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>Request ID:</label>
                                {this.state.requestData.requestId}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>Requester:</label>
                                {this.state.requestData.requesterName}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>TEAMS Site Name:</label>
                                {this.state.requestData.teamsSiteName}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>Expiration Date:</label>
                                {this.state.expireDate}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight floatLeft'>Justification:</label>
                                <div className='fieldValue font-16px width50 floatLeft'>
                                    {this.state.requestData.justification}
                                </div>
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>Member Firm:</label>
                                {this.state.memberFirmCompany}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight floatLeft'>Guest Names:</label>
                                <div className='fieldValue font-16px width50 floatLeft'>
                                    {this.state.guestUsersName}
                                </div>
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>Legal Review:</label>
                                {this.state.legalStatus}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>Legal Review By:</label>
                                {this.state.requestData.legalStatusUpdaterName}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>Legal POC:</label>
                                {this.state.requestData.legalApprover &&
                                    <span>{this.state.requestData.legalApprover.name}</span>
                                }
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight'>TEAMS Site Type:</label>
                                {this.getRequestTypeLabel(this.state.selectedRequestType)}
                                {/*<div className="selectBox">*/}
                                {/*    <Select value={this.state.selectedRequestType} placeholder={this.getRequestTypeLabel(this.state.selectedRequestType)} options={this.state.requestType} onChange={this.handleRequestTypeChange} >*/}
                                {/*        <option value='0'>Client Engagement Site</option>*/}
                                {/*        <option value='1'>Protiviti Training</option>*/}
                                {/*        <option value='2'>Other</option>*/}
                                {/*    </Select>*/}
                                {/*</div>*/}
                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight floatLeft'>Notes / Comments:</label>
                                <div className='textareaBox'>
                                    <textarea id="comment" className="form-control" value={this.state.commentArea || ''} onChange={this.handleCommentChange} disabled={this.state.isTextAreaDisabled} rows="5" />
                                </div>

                            </div>
                            <div className='requestDetailsCol col-md-12'>
                                <label className='fieldLabels width200 textAlignRight floatLeft'>Attachments</label>
                                <div className='addAttachment'>
                                    <MDBBtn onClick={() => this.toggleAttachmentModal()}>Add Attachment <span><FontAwesomeIcon icon={faEdit} size='lg' /></span></MDBBtn>
                                </div>
                            </div>
                            {this.state.attachmentList}

                        </div>
                        <br /><br />
                        <div className='row'>
                            <div className='requestDetailsCol col-md-12 button-margin-left'>
                                Based on your review of the information, can we send the request to the MD for approval?<br />
                            </div>
                        </div>
                        <div className='requestDetailsButton'>
                            <div className='button-box min-height-134px width-250'>
                                <p>
                                    <button type='submit' onClick={() => this.setState({ button: 'approveAll' })} className='btn btn-primary updateButton' disabled={this.state.submitButtonDisable || this.state.notRequiredButtonDisbled}>Yes, and future requests</button>
                                </p>
                                Additional member firm users can be added to this site with MD approval
                                <br />
                            </div>
                            <div className='button-box min-height-134px width-250'>
                                <p>
                                    <button type='submit' onClick={() => this.setState({ button: 'approveOne' })} className='btn btn-primary updateButton' disabled={this.state.submitButtonDisable || this.state.notRequiredButtonDisbled}>Yes, this time only</button>
                                </p>
                                Future requests require legal contract review

                                <br />
                            </div>
                            <div className='button-box min-height-134px width-150'>
                                <p>
                                    <button type='submit' onClick={() => this.setState({ button: 'reject' })} className='btn btn-danger updateButton' disabled={this.state.submitButtonDisable || this.state.notRequiredButtonDisbled} >No</button>
                                </p>
                                Do Not Proceed
                            </div>
                        </div>
                        <div className='clear-fix'></div>
                        <div className='requestDetailsButton center'>
                            <div className='button-box width-150'>
                                <div className='align-bottom'>
                                    <button type='submit' onClick={() => this.setState({ button: 'save' })} className='btn btn-primary updateButton' disabled={this.state.buttonDisable} > Save</button>
                                </div>
                            </div>
                            <div className='button-box width-150'>
                                <div className='align-bottom'>
                                    <Link to='/teamsrequests'  ><button type="button" className="btn btn-primary updateButton" disabled={this.state.buttonDisable} > Back To List</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className='clear-fix'></div>
                        <br /><br />
                        <div>
                            If you have any questions, please reach out to <a href="mailto:iShareSupport@protiviti.com">Protiviti Technical Support</a><br />
                        </div>
                        <div className='errorMessage'>{this.state.errorMessage}</div>
                        {this.renderModal()}
                        {this.renderAttachmentModal()}
                        {this.renderApprovalModal()}
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(LegalApproval);