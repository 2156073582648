import React, { Component } from 'react';
import { connect } from 'react-redux'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { Link, Redirect } from 'react-router-dom'
import config from '../../config';
import './EmailWhitelist.css';
import { apiGet, apiPost } from '../../services/apiService';

class AddEmail extends Component {
    state = {
        email: '',
        error: null,
        modal: false,
        type: this.props.location.state.type,
        linkTo: '',
        url: '',
        existing: false,
    }

    componentDidMount() {
        this.populateValues();
    }

    populateValues = async () => {
        let link = '';
        let url = '';
        if (this.props.location.state.type === 'blacklist') {
            link = '/systemconfig/emailblacklist';
            url = config.azRedirectUri + '/api/EMailBlacklist/';
        } else if (this.props.location.state.type === 'whitelist') {
            link = '/systemconfig/emailwhitelist';
            url = config.azRedirectUri + '/api/EMailWhitelist/';
        } else {
            <Redirect to='/systemconfig' />;
        }
        this.setState({ linkTo: link, url: url });
    }

    handleAddDomain(event) {
        let validDomainRegex = RegExp(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
        if (validDomainRegex.test(String(event.target.value).toLowerCase()) || event.target.value === '') {
            let error = null;
            let domain = event.target.value;
            const url = this.state.url + 'checkexisting/' + domain;
            apiGet(url)
                .then((resp) => {
                    let data = resp.data;
                    let error = null;
                    if (data.length > 0) {
                        error = '* Email Domain already exists!';
                    }
                    let newEmail = domain;
                    this.setState({ email: newEmail, error: error })
                });
        }
        else {
            this.setState({
                error: '* Enter a Valid Email Domain (lowercase letters only)'
            })
        }
    }

    addEmail() {
        if (this.state.email !== '' && !this.state.error) {
            if (!this.state.existing) {
                let email = {
                    "name": this.state.email
                }
                apiPost(this.state.url, email)
                    .then(() => {
                        this.toggleModal()
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            else {
                this.setState({ error: '* Email Domain already exist!' });
            }
        }
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    responseModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal()} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Add Email</MDBModalHeader>
                    <MDBModalBody>
                        Email Successfully Added!
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to={this.state.linkTo}><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    render() {
        return (
            <div className='container listcontainer' >
                <div style={{ marginTop: '50px' }}>
                    <h3>Add Email Domain</h3>
                    <div className="updateField">
                        <input className={this.state.error ? 'form-control emailInput' : 'form-control'} onChange={(e) => this.handleAddDomain(e)} placeholder="e.g. protiviti.com" />
                        {this.state.error ? <div style={{ color: "red" }}>{this.state.error}</div> : null}
                        <div style={{ marginTop: 30, textAlign: 'end' }}>
                            <Link to={this.state.linkTo}><button className='btn btn-secondary updateButton'>Cancel</button></Link>
                            <MDBBtn color="primary" onClick={() => this.addEmail()} style={{ marginLeft: '10px' }}>Add</MDBBtn>
                        </div>
                    </div>
                </div>
                {this.responseModal()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, {})(AddEmail);