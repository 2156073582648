import React from 'react';
import './ManageOfficesGrid.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import DataTable, { defaultThemes } from 'react-data-table-component';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { fetchOffice } from '../../actions';
import { apiPut } from '../../services/apiService';

class ManageOfficesGrid extends React.Component {
    data = [];

    constructor(props) {
        super(props);
        this.state = {
            officeOptions: [],
            deleteModal: false,
            deletingOffice: {},
            isReload: false,
            totalRows: 0,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortOrder: "desc",
            filterText: "",
            dataLoading: true,
            action: ""
        }
    }

    componentDidMount() {
        if (this.state.officeOptions.length !== this.props.sharedReducer.officeOptions.length) {
            let offices = this.assembleOffices(this.props.sharedReducer.officeOptions).map((x, index) => ({ ...x, action: this.renderActions(this.props.sharedReducer.officeOptions[index]) }));
            this.setState({ officeOptions: offices, dataLoading: false });
        }
    }

    componentDidUpdate() {
        if (this.state.officeOptions.length !== this.props.sharedReducer.officeOptions.length && this.state.filterText === "") {
            let offices = this.assembleOffices(this.props.sharedReducer.officeOptions).map((x, index) => ({ ...x, action: this.renderActions(this.props.sharedReducer.officeOptions[index]) }));
            this.setState({ officeOptions: offices, dataLoading: false });
        }
    }

    assembleOffices = (response) => {
        let allOffices = response.map((x) => {
            return ({
                'name': x.name,
                'company': 'Protiviti ' + x.company,
                'city': x.city,
                'country': x.country,
                'officeManager': x.officeManager,
                'isDeleted': (x.isDeleted ? 'Yes' : 'No')
            })
        });
        return allOffices;
    }

    handlePageChange = page => {
        //this.fetchDataByUser(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        //this.fetchDataByUser(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    //handleSortChange = (c, d) => {
    //    this.setState({ sortBy: c.selector, sortOrder: d }, () => {
    //        this.fetchDataByUser(this.state.currentPage);
    //    });
    //}

    handleFilterChange = (e) => {
        let origData = this.props.sharedReducer.officeOptions;
        origData = this.assembleOffices(origData).map((x, index) => ({ ...x, action: this.renderActions(origData[index]) }));
        let tableFilter = e.target.value.toLowerCase();
        if (tableFilter !== "") {
            origData = origData.filter(x => x.name.toLowerCase().includes(tableFilter) || x.company.toLowerCase().includes(tableFilter) || x.city.toLowerCase().includes(tableFilter) || x.country.toLowerCase().includes(tableFilter) || x.officeManager.toLowerCase().includes(tableFilter) || x.isDeleted.toLowerCase().includes(tableFilter));
        }
        this.setState({ filterText: tableFilter, officeOptions: origData });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "", officeOptions: this.props.sharedReducer.officeOptions });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    deleteOffice = () => {
        const url = '/api/ProOffice/delete/' + this.state.deletingOffice.key;
        apiPut(url, null)
            .then(() => {
                this.setState({ isReload: false });
                this.props.fetchOffice();
                let newOffices = this.props.sharedReducer.officeOptions;
                let deletedOfficeIndex = newOffices.findIndex(x => {
                    return x.key === this.state.deletingOffice.key
                });
                newOffices[deletedOfficeIndex].isDeleted = true;
                let offices = this.assembleOffices(newOffices).map((x, index) => ({ ...x, action: this.renderActions(newOffices[index]) }));
                this.setState({ officeOptions: offices });
                this.toggleDeleteModal(null);
            })
            .catch(function (error) {
                if (error.response.status === 401 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.deleteOffice();
                }
                console.log(error);
            })
    }

    toggleDeleteModal = (office) => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            deletingOffice: office
        });
    }

    deleteModal() {
        let officeName = this.state.deletingOffice ? this.state.deletingOffice.name : null
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggleDeleteModal(null)}>
                    <MDBModalHeader toggle={() => this.toggleDeleteModal(null)}>Delete Office: {officeName}</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to delete this Office?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.toggleDeleteModal(null)}>No</MDBBtn>
                        <MDBBtn color="primary" onClick={this.deleteOffice}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderActions(office) {
        return (
            <div className='dropdownAction'>
                <MDBDropdown dropleft>
                    <MDBDropdownToggle color="none">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <Link to={{ pathname: '/editoffice', state: { officeInfo: office } }} style={{ color: 'black', textDecoration: 'none' }}><MDBDropdownItem>Edit</MDBDropdownItem></Link>
                        {!office.isDeleted ? <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleDeleteModal(office)}><MDBDropdownItem>Delete</MDBDropdownItem></span> : null}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        );
    }

    render() {
        const columns = [{ name: 'Office Name', selector: row => row.name, sortable: true, wrap: true, sortField: 'name' },
            { name: 'Company', selector: row => row.company, sortable: true, wrap: true, sortField: 'company' },
            { name: 'City', selector: row => row.city, sortable: true, sortField: 'city' },
            { name: 'Country', selector: row => row.country, sortable: true, sortField: 'country' },
            { name: 'Manager', selector: row => row.officeManager, sortable: true, wrap: true, sortField: 'officeManager' },
            { name: 'Deleted', selector: row => row.isDeleted, sortable: true, sortField: 'isDeleted', maxWidth: '120px' },
            { name: 'Edit', cell: row => row.action, allowOverflow: true, button: true }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',

                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };

        return (
            <div>
                <DataTable
                    columns={columns}
                    data={this.state.officeOptions}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    subHeader
                    //sortServer
                    progressPending={this.state.dataLoading}
                    //onSort={this.handleSortChange}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    paginationTotalRows={this.state.totalRows}
                    paginationPerPage={this.state.perPage}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={{ noRowsPerPage: false }}
                />
                {this.deleteModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { fetchOffice })(ManageOfficesGrid);