const INITIAL_STATE = {
    currentUser: {},
    unAuthorized: false
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_USER':
            return { ...state, currentUser: action.payload };
        case 'SET_UNAUTHORIZED':
            return { ...state, unAuthorized: action.payload };
        default:
            return state;
    }
};

export const getCurrentUser = state => state.userReducer.currentUser ? state.userReducer.currentUser : null