import React from 'react';
import { connect } from 'react-redux';
import { getUsername } from '../services/authService';
import HelpSection from '../components/GuestResources/HelpSection';
import OpenTerms from '../components/GuestResources/OpenTerms';
import './GuestResource.css';

class GuestResource extends React.Component {
    givenName;

    async componentDidMount() {
        this.currentUser = await getUsername();
    }

    render() {
        return (
            <div className='resource-container'>
                <HelpSection given_name={this.props.userReducer.currentUser.firstName} accountType={this.props.userReducer.currentUser.accountType} />
                <OpenTerms />
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps)(GuestResource);