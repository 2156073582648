import React from 'react';
import './Error.css';
import { Link } from 'react-router-dom';
class NotFoundPage extends React.Component {
    render() {
        return <div className="error-container">
            <h2 className='heading2'>404</h2>
            <h3 className='heading3'>Oops, you dont have access or page not found</h3>
            <p className='paragraph'>Please Check the URL</p>
            <p className='paragraph'>Otherwise, <Link className='link' to="/">Go to Home </Link></p>
        </div>;
    }
}
export default NotFoundPage;