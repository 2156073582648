import { msalInstance } from '../index';

export async function getToken(scope) {
    var request = {
        scopes: ['d0ed6353-0629-41f8-b4c9-3f96fea8558c/.default']
    }
    if (scope === 'graph') {
        request = {
            scopes: ['User.Read']
        }
    }
    
    var token = null;
    await msalInstance.acquireTokenSilent(request).then(response => {
        token = response.accessToken;
        return token;
    }).catch(err => {
        console.log('GetTokenSilent failed, using redirect');
        if (err?.name === 'InteractionRequiredAuthError') {
            msalInstance.acquireTokenRedirect(request).then(response => {
                token = response.accessToken;
                console.log('acquiretokenredirect: ' + token);
                return token;
            }).catch(err => {
                console.log('Acquire token failed: ' + err);
            });
        }
    })
    //console.log("token " + token)
    return token;
}

export async function getUsername() {
    const account = await msalInstance.getActiveAccount();
    if (account) {
        return account;
    }
}

export function logout() {
    window.open('https://login.microsoftonline.com/16532572-d567-4d67-8727-f12f7bb6aed3/oauth2/logout');
}