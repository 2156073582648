import React, { Component } from 'react';
import './ResetConfirmation.css';

class ResetConfirmation extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate(prevProps) {
		//let prevPath = prevProps.history.location;
		//if (prevPath.pathname !== '/passwordreset') {
		//	this.props.history.push('/passwordreset');
  //      }
	}
	render() {
		return (
			<div>
				<div className="container-fluid title-container">
					<div className="col-md-12">
						<h3>Your MyLearning Password Reset Request Has Been Submitted</h3>
					</div>
				</div>
				<div className="confirm-container">
					<div className="col-md-6 align-middle">
						<p>Your password reset request has been submitted.  The request will take up to 15 minutes.</p>
						<p>When the reset process has completed you will receive an e-mail informing you that your password has been reset.  If you do not receive an e-mail in your inbox, check your spam or junk folder.  If you do not receive an e-mail, please contact <a href='mailto:iSharesupport@protiviti.com'>Protiviti Technical Support</a></p>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetConfirmation;