import React from 'react';
import './Header.css';
import { Link, Redirect } from 'react-router-dom';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import HelpPage from '../../assets/MemberFirmAdminGuide.pdf';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../actions';
import { getUsername } from '../../services/authService';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';

class Header extends React.Component {

    async componentDidMount() {
        let currentUser = await getUsername();
        this.props.getCurrentUser(currentUser.username);
        if (this.props.userReducer.unAuthorized) {
            <Redirect to='/guestresource' />;
        }
    }

    renderHeaderText() {
        return (
            <Link className='navbar-brand headerLogo' to='/'>
                <h2>Guest Access</h2>
                <h5>Self-Service Account Portal</h5>
            </Link>
        )
        //if (['assignteams', 'assignteamshelp', 'teamsrequests', 'clientteamsrequests', 'clientaccessrequest', 'acceptterms'].includes(urlTrimmed))
        //    return (
        //        <Link className='navbar-brand headerLogo' to='/'>
        //            <h2>Guest Access</h2>
        //            <h5>Guest Access Request Form</h5>
        //        </Link>
        //    )
        //else {
        //    return (
        //        <Link className='navbar-brand headerLogo' to='/'>
        //            <h2>Member Firms</h2>
        //            <h5>Self-Service Account Portal</h5>
        //        </Link>
        //    )
        //}
    }
    renderHelpSection() {
        let approvedDomains = {}
        let email = this.props.userReducer.currentUser.emailAddress
        let emailSuffix = email ? email.substring(email.lastIndexOf('@') + 1) : null
        let checkDrop = ['roberthalf.com', 'roberthalf.net', 'protiviti.com'].includes(emailSuffix) ? true : false
        return (
            this.props.userReducer.currentUser.isAdmin || this.props.userReducer.currentUser.isDelegate ?
                <div className='helpSection'>
                    {this.props.sharedReducer.displayServicePortalHelp &&
                        <a className='helpLink' href={HelpPage} target='_blank' rel='noopener noreferrer'>Self Service Portal Help <FontAwesomeIcon icon={faQuestionCircle} className='questionIcon' /></a>
                    }
                    <div className='dropdownHeader'>
                        <div className='dropdownName'>{this.props.userReducer.currentUser.displayName} <FontAwesomeIcon icon={faCaretDown} /></div>
                        <div className='dropdownContent'>
                            <a href={config.iShareMis} target='_blank' rel='noopener noreferrer'>iShare</a>
                            {this.props.userReducer.currentUser.isAdmin && <Link to={{ pathname: '/systemconfig/auditlogs', state: { type: 'auditlog' } }}>Configuration</Link>}
                            {checkDrop && <Link to='/teamsrequests'>Teams for Member Firms</Link>}
                            {checkDrop && <Link to='/clientteamsrequests'>Teams for Clients</Link>}
                            <Link to='/passwordreset'>My Learning Password Reset</Link>
                        </div>
                    </div>
                </div> : null
        )

    }

    unauthorizedModal() {
        return (
            <MDBContainer>
                {this.props.userReducer.unAuthorized ? <div className='modalBackdrop'></div> : null}
                <MDBModal isOpen={this.props.userReducer.unAuthorized} backdrop={false}>
                    <MDBModalHeader>Unauthorized User</MDBModalHeader>
                    <MDBModalBody>
                        You are not authorized to view this page. <br />If you feel you have received this message in error, please contact isharesupport@roberthalf.com to request access.
                    </MDBModalBody>
                </MDBModal>
            </MDBContainer>
        );
    }


    renderAdminOptions() {
        return (
            this.props.userReducer.currentUser.isAdmin ?
                <div className="navbar-collapse navbarOptions">
                    <div className="navbar-nav navbarItems">
                        <Link className="nav-link navbarItem" to='/delegates'>Manage Delegates</Link>
                        <Link className="nav-link navbarItem" to='/offices'>Manage Offices</Link>
                    </div>
                </div> : null
        );
    }

    render() {

        return (
            <nav className="navbar navbar-expand-lg header">
                <div className="d-flex flex-column flexHeader">
                    {this.renderHelpSection()}

                    <div className="d-flex flex-row">
                        {this.renderHeaderText()}
                        {/*{this.renderAdminOptions()}*/}
                    </div>
                    {this.unauthorizedModal()}
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(Header);