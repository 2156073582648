import { apiGet } from '../services/apiService.js';

export const fetchOffice = () => async (dispatch, getState) => {
    let url = '/api/ProOffice';
    await apiGet(url)
        .then(function (response) {
            dispatch({
                type: 'FETCH_OFFICE',
                payload: response.data
            })
            let selectedOption = null;
            if (getState().sharedReducer.selectedOfficeKey && (response.data.length !== 0)) {
                selectedOption = response.data.find(x => {
                    return x.key === getState().sharedReducer.selectedOfficeKey;
                })
            }
            else {
                selectedOption = { name: 'My Offices' };
            }
            dispatch(updateOffice(selectedOption));
        })
        .catch(function (error) {
            console.log(error);
        })
}

export const fetchCompany = () => async (dispatch, getState) => {
    let url = '/api/Company';
    await apiGet(url, )
        .then(function (response) {
            dispatch({
                type: 'FETCH_COMPANY',
                payload: response.data
            })
            let selectedOption = null;
            if (getState().sharedReducer.selectedCompanyKey && (response.data.length !== 0)) {
                selectedOption = response.data.find(x => {
                    return x.key === getState().sharedReducer.selectedCompanyKey;
                })
            }
            else {
                selectedOption = { name: 'All Companies' };
            }
        })
        .catch(function (error) {
            console.log(error);
        })

}
export const fetchUsers = () => async (dispatch, getState) => {
    let url = '/api/User';
    await apiGet(url)
        .then(function (response) {
            dispatch({
                type: 'FETCH_USERS',
                payload: response.data
            })

        })
        .catch(function (error) {
            console.log(error);
        })
}

export const updateOffice = (selectedOption) => async dispatch => {
    dispatch({
        type: 'UPDATE_OFFICE',
        payload: selectedOption
    });
};

export const updateAddOffices = (selectedOption, index) => async (dispatch, getState) => {
    let currentOffices = getState().sharedReducer.selectedAddOffices;
    currentOffices[index] = selectedOption;
    dispatch({
        type: 'UPDATE_ADDOFFICE',
        payload: currentOffices
    });
};

export const updateCompany = (selectedOption) => async dispatch => {
    dispatch({
        type: 'UPDATE_COMPANY',
        payload: selectedOption
    });
};

export const updateAddCompany = (selectedOption, index) => async (dispatch, getState) => {
    let currentCompany = getState().sharedReducer.selectedAddCompany;
    currentCompany[index] = selectedOption;
    dispatch({
        type: 'UPDATE_ADDCompany',
        payload: currentCompany
    });
};

export const clearAddOfficeRow = (index) => async (dispatch, getState) => {
    let currentOffices = getState().sharedReducer.selectedAddOffices;
    currentOffices[index] = '';
    dispatch({
        type: 'UPDATE_ADDOFFICE',
        payload: currentOffices
    });
};

export const clearAddOffices = () => async dispatch => {
    dispatch({
        type: 'CLEAR_ADDOFFICE',
        payload: []
    });
};

export const getOfficeKey = (officeKey) => async dispatch => {
    dispatch({
        type: 'GET_OFFICEKEY',
        payload: (officeKey ? officeKey : null)
    });
};

export const getCompanyKey = (companyKey) => async dispatch => {
    dispatch({
        type: 'GET_COMPANYKEY',
        payload: (companyKey ? companyKey : null)
    });
};

export const getCurrentUser = (username) => async (dispatch, getState) => {
    //console.log("getcurrentuser username: " + username);
    const url = '/api/User/CurrentUser/' + username;
    let emailSuffix = username ? username.substring(username.lastIndexOf('@') + 1) : null
    let internalUser = ['roberthalf.com', 'roberthalf.net', 'protiviti.com'].includes(emailSuffix) ? true : false
    await apiGet(url)
        .then(function (response) {
            //console.log('Current User: ' + JSON.stringify(response.data))
            if (window.location.href.endsWith('/acceptterms')) {
                dispatch({
                    type: 'GET_USER',
                    payload: response.data
                });
            }
            if (internalUser && window.location.href.endsWith('/assignteams')) {
                dispatch({
                    type: 'GET_USER',
                    payload: response.data
                });
            }
            if ((response.data.isAdmin === false && response.data.isDelegate === false) && !internalUser && (!window.location.href.includes('/acceptterms?taskid=') && !window.location.href.endsWith('/assignteams') && !window.location.href.endsWith('/passwordreset') && !window.location.href.endsWith('/guestresource'))) {
                window.location.replace('/guestresource');
            }
            dispatch({
                type: 'GET_USER',
                payload: response.data
            });
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: 'SET_UNAUTHORIZED',
                payload: true
            });
        })
}


export const importUsersData = (data) => async (dispatch) => {
    dispatch({
        type: 'IMPORT_USERS',
        payload: data
    })

};

export const bulkAdded = (data) => async (dispatch) => {
    dispatch({
        type: 'BULK_ADDED',
        payload: data
    })

};

export const getAccounts = (data) => async dispatch => {
    dispatch({
        type: 'GET_ACCOUNTS',
        payload: data
    })
}

export const getFilterStatus = (filter) => async dispatch => {
    dispatch({
        type: 'GET_FILTER_STATUS',
        payload: filter
    })
}

export const getRequestFilterStatus = (filter) => async dispatch => {
    dispatch({
        type: 'GET_REQUEST_FILTER_STATUS',
        payload: filter
    })
}

export const getClientAccessFilterStatus = (filter) => async dispatch => {
    dispatch({
        type: 'GET_CLIENT_ACCESS_FILTER_STATUS',
        payload: filter
    })
}

export const getLegalApprovalStatusFilter = (filter) => async dispatch => {
    dispatch({
        type: 'GET_LEGAL_APPROVAL_FILTER_STATUS',
        payload: filter
    })
}

export const getMdApprovalStatusFilter = (filter) => async dispatch => {
    dispatch({
        type: 'GET_MD_APPROVAL_FILTER_STATUS',
        payload: filter
    })
}

export const showExpandAlert = (show) => async dispatch => {
    dispatch({
        type: 'SHOW_EXTEND_ALERT',
        payload: show
    })
}

export const getTeamsRequests = (filter) => async dispatch => {
    dispatch({
        type: 'GET_TEAMS_REQUESTS',
        payload: filter
    })
}

export const displayPortalHelp = (selectedOption) => async dispatch => {
    dispatch({
        type: 'DISPLAY_PORTALHELP',
        payload: selectedOption
    });
};