import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUserPlus, faUpload, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import './AccountActions.css';
import { CSVLink } from 'react-csv';

class AccountActions extends React.Component {
    accountStatusValue = {
        0: 'Active',
        1: 'Expired',
        2: 'Disable',
        3: 'Locked'
    };

    state = {
        headers: [
            { label: 'Display Name', key: 'displayName' },
            { label: 'First Name', key: 'firstName' },
            { label: 'Last Name', key: 'lastName' },
            { label: 'Email Address', key: 'emailAddress' },
            { label: 'Username', key: 'username' },
            { label: 'Office', key: 'office' },
            { label: 'Region', key: 'region' },
            { label: 'Country', key: 'country' },
            { label: 'City', key: 'city' },
            { label: 'Country Code', key: 'countryCode' },
            { label: 'Account Status', key: 'accountStatus' },
            { label: 'Expire Date', key: 'expirationDate' },
            { label: 'Requested By', key: 'requestedBy' },
            { label: 'Creation Date', key: 'created' },
            { label: 'Created By', key: 'createdBy' },
            { label: 'Modified Date', key: 'modified' },
            { label: 'Modified By', key: 'modifiedBy' }
        ],
        accounts: []
    }

    componentDidUpdate() {
        if ((this.props.sharedReducer.accountOptions.length !== 0) && (this.state.accounts.length !== this.props.sharedReducer.accountOptions.length)) {
            let exportAccounts = this.props.sharedReducer.accountOptions.map((x) => {
                return ({
                    'displayName': x.displayName,
                    'firstName': x.firstName,
                    'lastName': x.lastName,
                    'emailAddress': x.emailAddress,
                    'username': x.username,
                    'office': x.office,
                    'region': x.region,
                    'country': x.country,
                    'city': x.city,
                    'countryCode': x.countryCode,
                    'accountStatus': this.accountStatusValue[x.accountStatus],
                    'expirationDate': x.expirationDate ? x.expirationDate.substring(0, 10) : x.expirationDate,
                    'requestedBy': x.requestedByName,
                    'created': x.created ? x.created.substring(0, 10) : x.created,
                    'createdBy': x.createdByName,
                    'modified': x.modified ? x.modified.substring(0, 10) : x.modified,
                    'modifiedBy': x.modifedByName
                })
            });
            this.setState({ accounts: exportAccounts });
        }
    }

    bulkExtend() {
        return (
            <span className='actionIcon'>
                <Link to='/bulkextend'>
                    <FontAwesomeIcon icon={faCalendarAlt} size='lg' />
                    <div>Bulk Extension</div>
                </Link>
            </span>
        );
    }

    render() {
        return (
            <React.Fragment>
                <span className='actionIcon'>
                    <Link to='/accountcreate'>
                        <FontAwesomeIcon icon={faUserPlus} size='lg' />
                        <div>Add User</div>
                    </Link>
                </span>
                <span className='actionIcon'>
                    <Link to='/accountimport'>
                        <FontAwesomeIcon icon={faUpload} size='lg' />
                        <div>Bulk Upload</div>
                    </Link>
                </span>
                {(this.props.userReducer.currentUser.isAdmin || this.props.userReducer.currentUser.canBulkExtend) && this.bulkExtend()}
                <span className='actionIcon'>
                    {(this.props.sharedReducer.accountOptions.length !== 0) ?
                        <CSVLink data={this.state.accounts} headers={this.state.headers} filename={'ExportResults.csv'}>
                            <FontAwesomeIcon icon={faDownload} size='lg' />
                            <div>Export My Accounts</div>
                        </CSVLink> : null}
                </span>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, {})(AccountActions);