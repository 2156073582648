import React from 'react';
import { connect } from 'react-redux';
import "./AccountManagement.css"
import ProOffice from '../../components/Shared/ProOffice';
import AccountActions from '../../components/AccountGrid/AccountActions';
import AccountGrid from '../../components/AccountGrid/AccountGrid';
import StatusFilter from '../../components/Shared/StatusFilter';

class AccountManagement extends React.Component {

    extendAlert() {
        return (
            this.props.sharedReducer.extendAlert ?
                <div className='alert alert-success' role='alert'>
                    Success! Expiration date for <b>{this.props.sharedReducer.extendAlert}</b> is extended to 90 days from today.
                </div> : null
        );
    }

    render() {
        return (
            <div className='home'>
                {this.extendAlert()}
                <h5 className='accountSearch'>Account Search</h5>
                <div className='actionAndFilter'>
                    <div className='filterItems'>
                        <div className='officeFilter'>
                            <div className='proOfficeDropdown'>
                                <label className='proOfficeLabel'>Office:</label>
                                <ProOffice />
                            </div>
                        </div>
                        <div className='filterAndSearch'>
                            <label className='proOfficeLabel'>Account Status:</label>
                            <StatusFilter />
                        </div>
                    </div>
                    <div className='accountActions'>
                        <AccountActions />
                    </div>
                </div>
                <div className='accountGrid'>
                    <AccountGrid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, {})(AccountManagement);