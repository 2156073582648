import React, { Component } from 'react';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { connect } from 'react-redux';
import { getClientAccessFilterStatus, showExpandAlert } from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import './ClientGuestAccountsGrid.css';
import { fetchAADGroupsByEmail } from "../../services/graphApiService";
import ADInfoBody from '../../components/Teams/ADInfoBody';
import { apiGet, apiPut } from '../../services/apiService';
import DetailModal from '../Teams/DetailModal';

class ClientGuestAccountsGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allTeamsRequest: [],
            allTeamsRequestsDupe: [],
            allTeamsRequestResponse: [],
            teamNames: [],
            modal: false,
            modalRequest: null,
            modalYesDisable: false,
            modalNoDisable: false,
            modalAction: null,
            editRequest: {},
            disableSuccess: false,
            action: null,
            modalAccount: {},
            requestStatusFilter: {
                rany: false,
                active: true,
                pending: true,
                disabled: false
            },
            totalRows: 0,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortOrder: "desc",
            filterText: "",
            dataLoading: true,
            action: "",
            adGroups: null,
            detailModal: false,
            detailModalAction: null,
            modalData: null,
        }
    }

    componentDidMount() {
        this.fetchDataByUser();
    }

    componentDidUpdate() {
        if ((this.state.requestStatusFilter.rany !== this.props.sharedReducer.clientAccessFilter.rany) ||
            (this.state.requestStatusFilter.active !== this.props.sharedReducer.clientAccessFilter.active) ||
            (this.state.requestStatusFilter.pending !== this.props.sharedReducer.clientAccessFilter.pending) ||
            (this.state.requestStatusFilter.disabled !== this.props.sharedReducer.clientAccessFilter.disabled)) {
            this.setStatusFilter();
        }
    }

    componentWillUnmount() {
    }

    setStatusFilter = async () => {
        let requestFilter = await this.state.allTeamsRequestResponse;
        let filteredArray = [];
        this.setState({ allTeamsRequest: [] });
        this.setState({ requestStatusFilter: this.props.sharedReducer.clientAccessFilter }, async () => {
            if (this.state.requestStatusFilter.active) {
                filteredArray = requestFilter.filter(x => x.status === 'Active');
            }
            if (this.state.requestStatusFilter.pending) {
                filteredArray = filteredArray.concat(requestFilter.filter(x => x.status === 'Pending'));
            }
            if (this.state.requestStatusFilter.disabled) {
                filteredArray = filteredArray.concat(requestFilter.filter(x => x.status === 'Disabled'));
            }
            if (this.state.requestStatusFilter.rany) {
                filteredArray = requestFilter;
            }
            let teamRequests = this.assembleRequests(filteredArray).map((x, index) => ({ ...x, action: this.renderActions(filteredArray[index]) }));
            this.setState({ allTeamsRequest: teamRequests, allTeamsRequestsDupe: teamRequests });
        });
    }

    fetchDataByUser = async () => {
        let url = '/api/ClientTeamUsers/';
        await apiGet(url)
            .then((response) => {
                let sortedResponse = response.data;
                let teamRequests = this.assembleRequests(sortedResponse).map((x, index) => ({ ...x, action: this.renderActions(sortedResponse[index]) }));
                this.setState({ allTeamsRequestsDupe: teamRequests, allTeamsRequestResponse: sortedResponse, totalRows: response.data.count, dataLoading: false }, () => {
                    this.setStatusFilter();
                });
            })
            .catch((error) => {
                if (error.response != null && error.response.status === 401) {
                    this.fetchDataByUser();
                }
                console.log(error);
                this.toggleDetailModal(null, "error");
            })
    }

    getAdInfo = async (account) => {
        await fetchAADGroupsByEmail(account.email)
        .then((responseJson) => {
          let value = responseJson.value;
          if (value !== undefined && value.length > 0) {
            // AD info
            this.setState({
              adGroups: value
                .map((x) => x.displayName)
                .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)),
            });
            this.toggleModal(account, "adinfo");
          } else if (responseJson?.error.code === "Request_ResourceNotFound") {
            // no AD info
            this.setState({
              adGroups: null,
            });
            this.toggleModal(account, "adinfo");
          } else {
            // some unexpected exception
            console.error(responseJson?.error);
            this.toggleModal(null, "error");
          }
        })
        .catch(() =>{
            this.toggleModal(null, "error");
        });
      };

    assembleRequests = (response) => {
        let allTeamsRequest = response.map((x) => {
            return ({
                'guestName': x.guestName,
                'email': x.email,
                'clientName': x.clientName,
                'engagementName': x.engagementName,
                'teamsSiteName': x.teamsSiteName,
                'status': x.status,
                'expiresOn': this.parseExpirationDateRow(x.teamsExpiresOn)
            })
        });
        return allTeamsRequest;
    }

    handlePageChange = page => {
        //this.fetchDataByUser(page);
        this.setState({ currentPage: page })
    }

    handlePerRowsChange = async (newPerPage, page) => {
        //this.fetchDataByUser(page, newPerPage);
        this.setState({ perPage: newPerPage });
    }

    //handleSortChange = (c, d) => {
    //    this.setState({ sortBy: c.selector, sortOrder: d }, () => {
    //        this.fetchDelegates(this.state.currentPage);
    //    });
    //}

    handleFilterChange = (e) => {
        let accounts = this.state.allTeamsRequestsDupe;
        let filter = e.target.value.toLowerCase();
        if (filter !== "") {
            accounts = accounts.filter(x => x.guestName.toLowerCase().includes(filter) || x.email.toLowerCase().includes(filter) || x.clientName.toLowerCase().includes(filter) || x.engagementName.toLowerCase().includes(filter) || x.teamsSiteName.toLowerCase().includes(filter) || x.status.toLowerCase().includes(filter) || x.expiresOn.toString().includes(filter));
        }

        this.setState({ filterText: e.target.value, allTeamsRequest: accounts });
    }

    handleFilterClear = () => {
        if (this.state.filterText) {
            this.setState({ filterText: "", allTeamsRequest: this.state.allTeamsRequestsDupe });
        }
    }

    getSubHeaderComponent = () => {
        return (
            <input id="search" type="text" placeholder="Search" defaultValue={this.state.filterText} onChange={this.handleFilterChange} />
        )
    }

    toggleModal = async (request, action) => {
        this.setState({
            modal: !this.state.modal,
            modalAction: action,
            modalRequest: request,
            modalYesDisable: request === null ? false : this.state.modalYesDisable,
            modalNoDisable: request === null ? false : this.state.modalNoDisable,
            teamNames: null
        });

        if (action === 'disable') {
            await this.fetchUserNonExpiredTeams(request.key);
        }
    }

    toggleDetailModal = (modalData, action) => {
        this.setState({
            detailModal: !this.state.detailModal,
            detailModalAction: action,
            modalData: modalData,
        })
    }

    fetchUserNonExpiredTeams = async (key) => {
        const url = '/api/ClientTeamUsers/activeteams/' + key;
        apiGet(url)
            .then((response) => {
                this.setState({ teamNames: response.data });
            })
            .catch(async (error) => {
                if (error.response.status === 401) {
                    this.fetchUserNonExpiredTeams(key);
                }
                console.log(error);
                this.toggleDetailModal(null, "error");
            })
    }

    resetMFAAuth = () => {
        const url = '/api/ClientTeamUsers/ResetMFAAuthentication';
        const body = '"' + this.state.modalRequest.key + '"';
        apiPut(url, body)
            .then(() => {
                this.toggleModal(null, null);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.resetMFAAuth();
                }
                console.log(error);
                this.toggleModal(null, null);
                this.toggleDetailModal(null, "error");
            })
    }

    resendMSEmail = () => {
        const url = '/api/ClientTeamUsers/ResendMicrosoftEmail';
        const body = '"' + this.state.modalRequest.key + '"';
        apiPut(url, body)
            .then(() => {
                this.toggleModal(null, null);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.resendMSEmail();
                }
                console.log(error);
                this.toggleModal(null, null);
                this.toggleDetailModal(null, "error");
            })
    }

    disableAccount = () => {
        this.setState({ disableSuccess: false });
        const url = '/api/ClientTeamUsers/Disable';
        const body = '"' + this.state.modalRequest.key + '"';
        apiPut(url, body)
            .then((response) => {

                let newAccounts = this.state.allTeamsRequestResponse;
                let newAccountIndex = this.state.allTeamsRequestResponse.findIndex(x => {
                    return x.key === this.state.modalRequest.key
                });
                newAccounts[newAccountIndex].status = response.data.status;
                let sortedResponse = newAccounts.sort((a, b) => {
                    return new Date(b.expiresOn) - new Date(a.expiresOn);
                }).reverse();

                let teamRequests = this.assembleRequests(sortedResponse).map((x, index) => ({ ...x, action: this.renderActions(sortedResponse[index]) }));
                this.setState({ allTeamsRequestsDupe: teamRequests, allTeamsRequestResponse: sortedResponse, disableSuccess: true }, () => {
                    this.setStatusFilter();
                    setTimeout(() => {
                        this.setState({ disableSuccess: false });
                        this.toggleModal(null, null);
                    }, 3 * 1000);
                });

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    //this.setState({ isReload: true });
                    this.disableAccount();
                }
                console.log(error);
                this.toggleModal(null, null);
                this.toggleDetailModal(null, "error");
            })
    }

    parseExpirationDateRow = (expiryDate) => {
        return new Date(expiryDate).toISOString().split('T')[0]
    }

    parseExpirationDateModal = (expiryDate) => {
        let newDateArray = new Date(expiryDate).toDateString().split(' ');
        return (`${newDateArray[2]}-${newDateArray[1]}-${newDateArray[3]}`)
    }

    openModal() {
        let clientData = this.state.modalRequest ? this.state.allTeamsRequestResponse.filter(x => x.key === this.state.modalRequest.key)[0] : null;
        let fullName = this.state.modalRequest !== null ? this.state.modalRequest.firstName + ' ' + this.state.modalRequest.lastName : null;
        return (
            <MDBContainer>
                {this.state.modalRequest && clientData && this.state.modalAction === 'detail' &&
                    <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal(null)} className="modal-lg modal-dialog-centered">
                        <MDBModalHeader toggle={() => this.toggleModal(null)}>
                            <b>Account and TEAMS Details for: </b> {this.state.modalRequest.guestName}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>Access Request ID</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{clientData.requestId}</span>
                                </div>
                                <div className="col-md-2 text-right">
                                    <label>Client Name</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{this.state.modalRequest.clientName}</span>
                                </div>
                            </div>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>First Name</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{clientData.firstName}</span>
                                </div>
                                <div className="col-md-2 text-right">
                                    <label>Engagement Name</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{this.state.modalRequest.engagementName}</span>
                                </div>
                            </div>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>Last Name</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{clientData.lastName}</span>
                                </div>
                                <div className="col-md-2 text-right">
                                    <label>Engagement MD</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{clientData.engagementMD}</span>
                                </div>
                            </div>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>Email Address</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{this.state.modalRequest.email}</span>
                                </div>
                                <div className="col-md-2 text-right">
                                    <label>Sensitive</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{clientData.sensitive}</span>
                                </div>
                            </div>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>Account Status</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{this.state.modalRequest.status}</span>
                                </div>
                                <div className="col-md-2 text-right">
                                    <label>BU/Country</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{clientData.bu}</span>
                                </div>
                            </div>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>Respond By Date</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{this.parseExpirationDateModal(this.state.modalRequest.expiresOn)}</span>
                                </div>
                                <div className="col-md-2 text-right">
                                    <label>TEAMS Access Expires</label>
                                </div>
                                <div className="col-md-4">
                                    <span>{this.parseExpirationDateModal(this.state.modalRequest.teamsExpiresOn)}</span>
                                </div>
                            </div>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>TEAMS Site Name</label>
                                </div>
                                <div className="col-md-10">
                                    <span>{this.state.modalRequest.teamsSiteName}</span>
                                </div>
                            </div>
                            <div className="row guestAccessRow">
                                <div className="col-md-2 text-right">
                                    <label>TEAMS Site URL</label>
                                </div>
                                <div className="col-md-10">
                                    <span>{this.state.modalRequest.teamsSiteURL}</span>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" disabled={this.state.modalNoDisable} onClick={() => this.toggleModal(null)}>Back To List</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                }
                {this.state.modalRequest && fullName && this.state.modalAction !== 'detail' && this.state.modalAction != 'adinfo' &&
                    <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal(null)}>
                    <MDBModalHeader toggle={() => this.toggleModal(null)}>
                            {(this.state.modalAction === 'disable') ? 'Disable client guest: ' + fullName :
                                ((this.state.modalAction === 'msEmail') ? 'Resend Microsoft Welcome E-mail to ' + fullName :
                                    ((this.state.modalAction === 'resetMFA') ? 'Reset MFA Authentication: ' + fullName : null))}
                        </MDBModalHeader>
                        <MDBModalBody>
                            {(!this.state.disableSuccess && this.state.modalAction === 'disable') ? 'Disabling a client guest account will remove the guests access to all TEAMS sites and will delete the account.  This action cannot be undone.  Do you wish to proceed?' :
                                ((!this.state.disableSuccess && this.state.modalAction === 'msEmail') ? 'Are you sure you want to resend a Microsoft welcome E-mail?' :
                                    ((!this.state.disableSuccess && this.state.modalAction === 'resetMFA') ? 'You are about to reset MFA authentication for ' + fullName + '.  This action cannot be undone.  Choosing "Yes" will force the guest user to reconfigure their MFA authentication.  Are you sure you want to do this?' :
                                        ''))}
                            {this.state.disableSuccess &&
                                <p>User disabled successfully.</p>
                        }
                        {(!this.state.disableSuccess && this.state.modalAction === 'disable') && this.state.teamNames &&
                            <ul className="mt-2">
                            {this.state.teamNames && this.state.teamNames.map(name => { return <li>{name}</li> })}
                            </ul>                            
                        }
                        </MDBModalBody>
                        {!this.state.disableSuccess &&
                            <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.toggleModal(null)}>No</MDBBtn>
                                {(this.state.modalAction === 'disable') ? <MDBBtn color="primary" onClick={this.disableAccount}>Yes</MDBBtn> :
                                    ((this.state.modalAction === 'msEmail') ? <MDBBtn color="primary" onClick={this.resendMSEmail}>Yes</MDBBtn> :
                                        ((this.state.modalAction === 'resetMFA') ? <MDBBtn color="primary" onClick={this.resetMFAAuth}>Yes</MDBBtn> :
                                            null))}
                            </MDBModalFooter>
                        }
                    </MDBModal>
                }
                {this.state.modalRequest && this.state.modalAction === 'adinfo' &&
                    <MDBModal isOpen={this.state.modal} toggle={() => this.toggleModal(null)}>
                        <MDBModalHeader toggle={() => this.toggleModal(null)}>
                            Azure AD Guest Account Information
                        </MDBModalHeader>
                        <MDBModalBody>
                            <ADInfoBody account={this.state.modalRequest} adGroups={this.state.adGroups}/>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={() => this.toggleModal(null)}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                }
            </MDBContainer>
        )
    }

    renderActions(request) {
        let azureAdInfo;

        if (this.props.userReducer.currentUser.isAdmin) {
            azureAdInfo = (
              <span
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => this.getAdInfo(request)}
              >
                <MDBDropdownItem>Azure AD Info</MDBDropdownItem>
              </span>
            );
        }      

        return (
            <div className='dropdownAction'>
                <MDBDropdown dropright>
                    <MDBDropdownToggle color="none">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(request, 'detail')}><MDBDropdownItem>View Details</MDBDropdownItem></span>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(request, 'disable')}><MDBDropdownItem>Disable and Remove Account</MDBDropdownItem></span>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(request, 'msEmail')}><MDBDropdownItem>Resend Microsoft Email</MDBDropdownItem></span>
                        <span style={{ color: 'black', textDecoration: 'none' }} onClick={() => this.toggleModal(request, 'resetMFA')}><MDBDropdownItem>Reset MFA Authentication</MDBDropdownItem></span>
                        {azureAdInfo}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        );
    }

    render() {
        const columns = [{ name: 'Guest Name', selector: row => row.guestName, sortable: true },
            { name: 'Email Address', selector: row => row.email, sortable: true },
            { name: 'Client Name', selector: row => row.clientName, sortable: true, wrap: true },
            { name: 'Engagement Name', selector: row => row.engagementName, sortable: true, wrap: true },
            { name: 'TEAMS Site Name', selector: row => row.teamsSiteName, sortable: true, wrap: true, maxWidth: '280px' },
            { name: 'Account Status', selector: row => row.status, sortable: true, maxWidth: '140px' },
            { name: 'TEAMS Expires On', selector: row => row.expiresOn, sortable: true, maxWidth: '140px' },
            { name: 'Edit', cell: row => row.action, allowOverflow: true, button: true }]
        const customStyles = {
            header: {
                style: {
                    minHeight: '56px'
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: defaultThemes.default.divider.default,
                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '18px',

                    },
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        borderRightColor: defaultThemes.default.divider.default,
                        fontSize: '14px'
                    },
                },
            },
        };

        return (
            <div>
                <DataTable
                    columns={columns}
                    data={this.state.allTeamsRequest}
                    customStyles={customStyles}
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    subHeader
                    //sortServer
                    progressPending={this.state.dataLoading}
                    //onSort={this.handleSortChange}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    paginationTotalRows={this.state.totalRows}
                    paginationPerPage={this.state.perPage}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={{ noRowsPerPage: false }}
                />
                {this.openModal()}
                <DetailModal isOpen={this.state.detailModal} toggleModal={this.toggleDetailModal} modalAction={this.state.detailModalAction} modalData={this.state.modalData} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getClientAccessFilterStatus, showExpandAlert })(ClientGuestAccountsGrid);