import React from 'react';
import './AddEditCompany.css';
import AddEditCompany from './AddEditCompany';

class AddCompany extends React.Component {
    render() {
        return (
            <div className='container companyContainer'>
                <div className='form-group'>
                    <AddEditCompany />
                </div>
            </div>
        );
    }
}

export default (AddCompany);