import React from 'react';
import './AddEditDelegate.css';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { connect } from 'react-redux';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { withRouter } from 'react-router-dom';
import { apiGet, apiPut, apiPost } from '../../services/apiService';

class AddEditDelegate extends React.Component {
    state = {
        selectedOffices: [],
        prevSelectedOffices: [],
        disableButtons: false,
        users: [],
        selectedUser: null,
        modal: false,
        errorMessage: null,
        isReload: false
    }

    componentDidMount() {
        if (this.props.location.pathname === '/editdelegate' && !this.props.delegateInfo) {
            <Redirect to='/delegates' />;
        }
        else {
            if (this.props.delegateInfo) {
                this.fetchOffices(this.props.delegateInfo.key);
            }
            else {
                this.mounted = true;
                this.fetchUsers();
            }
        }
    }

    componentDidUpdate() {
        if (!this.props.userReducer.currentUser.isAdmin) {
            this.mounted = false;
            <Redirect to='/' />;
        }
        else {
            this.mounted = true;
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchOffices = async (key) => {
        const url = '/api/Delegates/GetDelegateOffices/' + key;
        await apiGet(url)
            .then((response) => {
                this.setState({ selectedOffices: response.data, prevSelectedOffices: response.data });
            });
    }

    fetchUsers = async () => {
        const url = '/api/User';
        await apiGet(url)
            .then((response) => {
                this.setState({ isReload: false });
                if (this.mounted) {
                    this.setState({ users: response.data });
                }
            })
            .catch(function (error) {
                if (error.response.status === 401 && !this.state.isReload) {
                    this.setState({ isReload: true });
                    this.fetchUsers();
                }
                console.log(error);
            })
    }

    addUpdate = async (event) => {
        event.preventDefault();
        if (this.props.delegateInfo) {
            if ((!this.arraysEqual(this.state.selectedOffices, this.state.prevSelectedOffices)) && this.state.selectedOffices.length !== 0) {
                this.setState({ disableButtons: true });
                let delegate = {
                    'userKey': this.props.delegateInfo.key,
                    'officeKey': this.state.selectedOffices.map(x => x.key)
                };
                const url = '/api/Delegates';
                await apiPut(url, delegate)
                    .then((response) => {
                        this.toggleModal();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        }
        else {
            if (this.state.selectedOffices.length !== 0 && this.state.selectedUser) {
                this.setState({ disableButtons: true });
                let delegate = {
                    'userKey': this.state.selectedUser.key,
                    'officeKey': this.state.selectedOffices.map(x => x.key)
                };
                const url = '/api/Delegates';
                await apiPost(url, delegate)
                    .then(() => {
                        this.toggleModal();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
            else {
                this.setState({ errorMessage: 'All fields must be populated.' });
                setTimeout(() => {
                    this.setState({ errorMessage: null });
                }, 5000);
            }
        }
    }

    arraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length)
            return false;
        for (var i = arr1.length; i--;) {
            if (arr1[i] !== arr2[i])
                return false;
        }
        return true;
    }

    onUserChange = selectedOption => {
        this.setState({ selectedUser: selectedOption });
    };

    onMultiOfficeChange = (selected) => {
        this.setState({ selectedOffices: selected });
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    renderModal() {
        let fullName = this.props.delegateInfo ? this.props.delegateInfo.firstName + ' ' + this.props.delegateInfo.lastName : null;
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader>{this.props.delegateInfo ? 'Edit Delegate: ' + fullName : 'Add Delegate'}</MDBModalHeader>
                    <MDBModalBody>
                        {this.props.delegateInfo ? 'Delegate has been successfully saved!' : 'Delegate has been successfully added!'}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/delegates'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    render() {
        const { selectedUser } = this.state;

        return (
            <form onSubmit={this.addUpdate}>
                <div className='delegateTitle'>
                    <h4>{this.props.delegateInfo ? 'Edit Delegate' : 'Add Delegate'}</h4>
                    <h5>{this.props.delegateInfo ? this.props.delegateInfo.displayName : null}</h5>
                </div>
                <hr className='delegateHr' />
                {this.props.delegateInfo ?
                    null :
                    <div className='addDelegateField'>
                        <label className='fieldLabels'>User</label>
                        <Select
                            value={selectedUser}
                            onChange={this.onUserChange}
                            options={this.state.users}
                            getOptionLabel={(option) => option.displayName}
                            getOptionValue={(option) => option.key} />
                    </div>}
                <div className='addDelegateField'>
                    <label className='fieldLabels'>Offices</label>
                    <Select
                        value={this.state.selectedOffices}
                        isMulti
                        options={this.props.sharedReducer.officeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.key}
                        onChange={this.onMultiOfficeChange}
                    />
                </div>
                <hr />
                <div className='delegateButton'>
                    <Link to='/delegates'><button type='button' className='btn btn-secondary' disabled={this.state.disableButtons}>Cancel</button></Link>
                    {this.props.delegateInfo ?
                        <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Update</button> :
                        <button type='submit' className='btn btn-primary updateButton' disabled={this.state.disableButtons}>Add</button>}
                </div>
                <div className='errorMessage'>{this.state.errorMessage}</div>
                {this.renderModal()}
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default withRouter(connect(mapStateToProps, { })(AddEditDelegate));