import React from 'react';
import './TeamsRequests.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Company from '../../components/Teams/Company';
import TeamRequestActions from '../../components/Teams/TeamRequestActions';
import TeamsRequestGrid from '../../components/Teams/TeamsRequestGrid';
import RequestFilter from '../../components/Teams/RequestFilter';
import ApprovalFilter from '../../components/Teams/ApprovalFilter';
import MDApprovalFilter from '../../components/Teams/MDApprovalFilter';
import { getCurrentUser } from '../../actions';
import { getUsername } from '../../services/authService';

class Home extends React.Component {
    async componentDidMount() {
        let currentUser = await getUsername();
        const emailSuffix = currentUser.userName ? currentUser.userName.substring(currentUser.userName.lastIndexOf('@') + 1) : null
        const checkDrop = emailSuffix === 'protiviti.com' || emailSuffix === 'roberthalf.com' ? true : false
        if (!checkDrop) {
            return <Redirect to='/' />
        }
    }

    render() {
        return (
            <div className='home'>
                <h5 className='accountSearch'>Protiviti TEAMS Site Access Requests for Guest Users</h5>
                <div className='actionAndFilter'>
                    <div className='teamsFilterItems'>
                        <div className='officeFilter'>
                            <div className='proOfficeDropdown'>
                                <label className='proOfficeLabel'>Company:</label>
                                <Company />
                            </div>
                        </div>
                        <div className='filterAndSearch'>
                            <label className='proOfficeLabel'>Request Status:</label>
                            <RequestFilter />
                            <label className='proOfficeLabel padding-left'>MD Approval Status:</label>
                            <MDApprovalFilter />
                        </div>
                        <div className='filterAndSearch'>
                            <label className='proOfficeLabel'>Legal Review Status:</label>
                            <ApprovalFilter />
                        </div>
                    </div>
                    <div className='accountActions'>
                        <TeamRequestActions />
                    </div>
                </div>
                <div className='accountGrid'>
                    <TeamsRequestGrid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(Home);