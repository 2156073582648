import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Link } from 'react-router-dom'
import config from '../../config';
import './EmailWhitelist.css'
import { apiPut } from '../../services/apiService';

class EditEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.emailInfo,
            type: this.props.location.state.type,
            modal: false,
            response: null,
            error: null,
            disabled: true,
            linkTo: ''
        }

    }

    componentDidMount() {
        let link = this.state.type === 'blacklist' ? '/systemconfig/emailblacklist' : '/systemconfig/emailwhitelist';
        this.setState({ linkTo: link });
    }
    handleEditEmail(event) {
        const validEmailRegex = RegExp(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
        if (validEmailRegex.test(event.target.value))
        {
            let newEmail = this.state.email
            newEmail.name = event.target.value
            this.setState({
                email: newEmail,
                disabled: false,
                error: null
            })
        }
        else
            this.setState({
                error: 'Enter a Valid Email Domain (lowercase letters only)',
                disabled: true
            })
    }
    editEmail(email) {
        email.isDeleted = email.isDeleted === "Yes" || email.isDeleted === false ? false : true;
        const url = this.state.type === 'blacklist' ? config.azRedirectUri + '/api/EMailBlacklist/' : config.azRedirectUri + '/api/EMailWhitelist/';
        apiPut(url, email)
            .then((response)=>{
          
                this.toggleModal(response)
            }).catch((err) => {
                console.log(err.response.data)
                this.setState({ error: err.response.data });
            })
    }
    toggleModal(response) {
        this.setState({
            modal: !this.state.modal,
            response: response.status
        })
    }
    responseModal() {
        return(
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={()=>this.toggleModal(null)} backdrop={false}>
                    <MDBModalHeader toggle={()=>this.toggleModal(null)}></MDBModalHeader>
                    <MDBModalBody>
                        {this.state.response===200?'Update Successful':'Update Failed'}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to={this.state.linkTo}><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>

            )
    }
    render() {
        return (
            <div className="container listcontainer" >
                <div style={{ marginTop: '50px' }}>
                    <h3>Edit Email Domain</h3>
                    <div className="updateField">
                        <input className=" form-control" defaultValue={this.state.email.name} onChange={(e) => this.handleEditEmail(e)} placeholder="e.g. protiviti.com" />
                        {this.state.error ? <div style={{ color: "red" }}>{this.state.error}</div> : ''}
                        <div style={{ marginTop: 30, textAlign: 'end' }}>
                            <Link to={this.state.linkTo}><button className='btn btn-secondary updateButton'>Cancel</button></Link>
                            <MDBBtn color="primary" disabled={this.state.disabled} onClick={() => this.editEmail(this.state.email)} style={{ marginLeft: '10px' }}>Update</MDBBtn>
                        </div>
                    </div>
                </div>
                {this.responseModal()}
            </div>
            )
    }
}
const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}
export default connect(mapStateToProps, {})(EditEmail);