import React from 'react';
import './ManageOffices.css';
import { Link } from 'react-router-dom';
import ManageOfficesGrid from '../../components/ManageOffices/ManageOfficesGrid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

class ManageOffices extends React.Component {
    componentDidUpdate() {
        if (!this.props.userReducer.currentUser.isAdmin) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div className='manageOffices'>
                <div className='iconRow'>
                    <h5>Manage Offices</h5>
                    <div className='iconContainer'>
                        <span className='addOffice'>
                            <Link to='/companies'>
                                <FontAwesomeIcon icon={faEdit} size="lg" />
                                <div>Manage Companies</div>
                            </Link>
                        </span>
                        <span className='addOffice'>
                            <Link to='/addoffice'>
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                                <div>Add Office</div>
                            </Link>
                        </span>
                    </div>
                </div>
                <div>
                    <ManageOfficesGrid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, {})(ManageOffices);