import React from 'react';
import './AddEditCompany.css';
import AddEditCompany from './AddEditCompany';

class EditCompany extends React.Component {
    render() {
        return (
            <div className='container companyContainer'>
                <div className='form-group'>
                    <AddEditCompany company={this.props.location.state ? this.props.location.state.companyInfo : null} />
                </div>
            </div>
        );
    }
}

export default (EditCompany);