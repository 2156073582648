import React from 'react';
import './AddEditOffice.css';
import AddEditOffice from './AddEditOffice';

class AddOffice extends React.Component {
    render() {
        return (
            <div className='container officeContainer'>
                <div className='form-group'>
                    <AddEditOffice />
                </div>
            </div>
        );
    }
}

export default (AddOffice);