import React, { Component } from 'react';
import '../Teams/TeamsRequests.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import TeamRequestActions from '../../components/Teams/TeamRequestActions';
import ClientTeamsRequestGrid from '../../components/Teams/ClientTeamsRequestGrid';
import RequestFilter from '../../components/Teams/RequestFilter';
import MDApprovalFilter from '../../components/Teams/MDApprovalFilter';
import { getCurrentUser } from '../../actions';
import { getUsername } from '../../services/authService';

class ClientTeamsRequests extends Component {
    async componentDidMount() {
        let currentUser = await getUsername();
        const emailSuffix = currentUser.userName ? currentUser.userName.substring(currentUser.userName.lastIndexOf('@') + 1) : null
        const checkDrop = emailSuffix === 'protiviti.com' || emailSuffix === 'roberthalf.com' ? true : false
        if (!checkDrop) {
            return <Redirect to='/' />
        }
    }

    render() {
        return (
            <div className='home'>
                <h5 className='accountSearch'>Protiviti TEAMS Site Access Requests for Client Guest Users</h5>
                <div className='actionAndFilter'>
                    <div className='teamsFilterItems'>
                        <div className='filterAndSearch filter-row'>
                            <div className='padding-right'>
                                <label className='proOfficeLabel'>Request Status:</label>
                                <RequestFilter />
                            </div>
                            <div>
                                <label className='proOfficeLabel'>MD Approval Status:</label>
                                <MDApprovalFilter />
                            </div>
                        </div>
                    </div>
                    <div className='accountActions'>
                        <TeamRequestActions />
                    </div>
                </div>
                <div className='accountGrid'>
                    <ClientTeamsRequestGrid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser })(ClientTeamsRequests);