import React, { Component } from 'react'
import config from '../../config';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import './ExpirationModal.css'
import { apiGet, apiPost } from '../../services/apiService';

class ExpirationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expireModal: false,
            currentExpireDate: null,
            pendingModal: false,
            teamsSite: null,
            dateSubmitted: null,
            requestStatus: null,
            previousRequester: null,
            requestedExpireDate: null,
            approvingMD: null,
            successModal: false,
            expireDate: null,
            errorMessage: null,
            selectedRequestKey: null,
            status: null,
            submitButton: false
        }
    }

    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    handleExpiration = (request) => {
        this.getRequest(request);
    }

    getRequest = async (request) => {
        let url = null;

        url = '/api/TeamsExtension/details/' + request.key;
        await apiGet(url)
            .then((response) => {
                let data = response.data;
                if (data.requestStatus === "Pending") {
                    this.setState({
                        teamsSite: data.teamsName,
                        dateSubmitted: data.submitDate,
                        requestStatus: data.requestStatus,
                        previousRequester: data.previousRequester,
                        requestedExpireDate: data.requstedExpireDate,
                        approvingMD: data.approvingMD
                    }, () => this.togglePendingModal())
                } else {
                    this.setState({ currentExpireDate: data.previousExpireDate, selectedRequestKey: request.key }, () => this.toggleExpirationModal());
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    handleUpdate = async () => {
        this.setState({ submitButton: true });
        if (this.state.expireDate !== null) {
            const url = '/api/TeamsExtension/Extend/' + this.state.selectedRequestKey;
            await apiPost(url, { newExpireDate: this.state.expireDate })
                .then((response) => {
                    let body = null;
                    const currentExpiredate = new Date(this.state.currentExpireDate)
                    if (currentExpiredate >= this.state.expireDate) {
                        body = 'TEAMS expiration date extend successful';
                    } else {
                        body = 'Your request to extend the TEAMS expiration date has been submitted to the MD for approval.';
                    }
                    this.setState({ status: "Success", expireDate: null, successBody: body, submitButton: false });
                    this.toggleExpirationModal();
                    this.toggleSuccessModal();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ successBody: "Update failed. Please try again. If the problem persists, please contact Protiviti Support.", status: "Failed", expireDate: null, submitButton: false });
                    this.toggleExpirationModal();
                    this.toggleSuccessModal();
                });
        } else {
            this.setState({ errorMessage: '* Marked fields are required.', expireDate: null })
        }

    }

    toggleExpirationModal = () => {
        this.setState({
            expireModal: !this.state.expireModal
        });
    }

    expirationModal = () => {
        const today = new Date();
        let days = this.state.showMaxDate ? config.teamsExpireDaysLength : 183;
        let inDays = new Date();
        inDays.setDate(inDays.getDate() + days);
        return (
            <div>
                <MDBContainer>
                    <MDBModal isOpen={this.state.expireModal} toggle={this.toggleExpirationModal} backdrop={false}>
                        <MDBModalHeader toggle={this.toggle}>Change TEAMS Access Expiration Date</MDBModalHeader>
                        <MDBModalBody>

                            <div className="form-group col-md-12">
                                <div className="row">
                                    <p>You can change the date that access to a TEAMS site will expire.</p>
                                    <p>Shortening of the expiration date is always allowed.  If you extend the expiration date beyond the current expiration date, the original approving MD will receive an email requesting approval for the new date.</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='rightText'><label className="expireLabel">Current Expiration Date</label></div>
                                        <input type="text" defaultValue={this.state.currentExpireDate} className='form-control dropdownAction' disabled />
                                    </div>
                                    <div className="col-md-6">
                                        <div className='rightText'><label className="expireLabel">New Expiration Date</label></div>
                                        <DatePicker
                                            dateFormat="dd-MMM-yyyy"
                                            selected={this.state.expireDate}
                                            onChange={(e) => this.setState({ expireDate: e })}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            minDate={today}
                                            maxDate={inDays}
                                            className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedDate === null ? 'warningBorder form-control' : 'form-control'} />
                                        {this.state.errorMessage === '* Marked fields are required.' && this.state.selectedDate === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                        <div className="warningText">
                                            Maximum date allowed is 6 months from today.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button className="btn btn-primary my-1 bottom-button" type="button" disabled={this.state.submitButton} onClick={this.handleUpdate}>Submit</button>
                            <button type="button" className="btn btn-secondary my-1 bottom-button" type="button" disabled={this.state.submitButton} onClick={this.toggleExpirationModal}>Cancel</button>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </div>
        )
    }

    togglePendingModal = () => {
        this.setState({
            pendingModal: !this.state.pendingModal
        });
    }

    pendingModal = () => {
        return (
            <div>
                <MDBContainer>
                    <MDBModal isOpen={this.state.pendingModal} toggle={this.togglePendingModal} backdrop={false}>
                        <MDBModalHeader toggle={this.toggle}>Change TEAMS Access Expiration Date</MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group col-md-12">
                                <div className="row">
                                    <p>There is already a request to change the TEAMS guest access expiration date pending approval by the Managing Director.  You cannot submit a new request until the first request is approved or rejected.</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="pendingLabel">TEAMS Site Name: </label>
                                        {this.state.teamsSite}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="pendingLabel">Date Submitted: </label>
                                        {this.state.dateSubmitted}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="pendingLabel">Previous Requester: </label>
                                        {this.state.previousRequester}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="pendingLabel">Requested Expiration Date: </label>
                                        {this.state.requestedExpireDate}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="pendingLabel">Approving MD: </label>
                                        {this.state.approvingMD}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="pendingLabel">Approval Status: </label>
                                        {this.state.requestStatus}
                                    </div>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn btn-primary my-1 bottom-button" type="button" onClick={this.togglePendingModal}>Close</button>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </div>
        )
    }

    toggleSuccessModal = () => {
        this.setState({
            successModal: !this.state.successModal
        });
    }

    successModal = () => {
        return (
            <div>
                <MDBContainer>
                    <MDBModal isOpen={this.state.successModal} toggle={this.toggleSuccessModal} backdrop={false}>
                        <MDBModalHeader toggle={this.toggle}>Update {this.state.status}</MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group col-md-12">
                                {this.state.successBody}
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn btn-primary my-1 bottom-button" type="button" onClick={this.toggleSuccessModal}>Close</button>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </div>
        )
    }

    onCancel = () => {
        this.setState({ expireDate: null })
        this.toggleExpirationModal();
    }

    render() {
        return (
            <div>
                {this.expirationModal()}
                {this.pendingModal()}
                {this.successModal()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, {})(ExpirationModal);