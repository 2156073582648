import React from 'react';
import './StatusFilter.css';
import { connect } from 'react-redux';
import { getFilterStatus } from '../../actions';

class StatusFilter extends React.Component {
    state = {
        any: false,
        active: true,
        expired: true,
        pending: true,
        disabled: false
    }

    onChange = (event) => {
        if (event.target.value === 'any') {
            if (!this.state.any) {
                this.setState({ any: true, active: true, expired: true, disabled: true, pending: true }, () => {
                    let filter = {
                        any: this.state.any,
                        active: this.state.active,
                        expired: this.state.expired,
                        disabled: this.state.disabled,
                        pending: this.state.pending
                    }
                    this.props.getFilterStatus(filter);
                });
            }
            else {
                this.setState({ any: false, active: false, expired: false, disabled: false, pending: false }, () => {
                    let filter = {
                        any: this.state.any,
                        active: this.state.active,
                        expired: this.state.expired,
                        disabled: this.state.disabled,
                        pending: this.state.pending
                    }
                    this.props.getFilterStatus(filter);
                });
            }
        }

        if (event.target.value === 'active' || event.target.value === 'expired' || event.target.value === 'disabled' || event.target.value === 'pending') {
            let status = null;

            switch (event.target.value) {
                case 'active':
                    status = !this.state.active;
                    break;
                case 'disabled':
                    status = !this.state.disabled;
                    break;
                case 'expired':
                    status = !this.state.expired;
                    break;
                case 'pending':
                    status = !this.state.pending;
                    break;
            }

            this.setState({ [event.target.value]: status }, () => {
                if (this.state.any) {
                    this.setState({ any: false }, () => {
                        let filter = {
                            any: this.state.any,
                            active: this.state.active,
                            expired: this.state.expired,
                            disabled: this.state.disabled,
                            pending: this.state.pending
                        }
                        this.props.getFilterStatus(filter);
                    });
                }
                else if (this.state.active && this.state.expired && this.state.disabled && this.state.pending) {
                    this.setState({ any: true }, () => {
                        let filter = {
                            any: this.state.any,
                            active: this.state.active,
                            expired: this.state.expired,
                            disabled: this.state.disabled,
                            pending: this.state.pending
                        }
                        this.props.getFilterStatus(filter);
                    });
                }
                else {
                    let filter = {
                        any: this.state.any,
                        active: this.state.active,
                        expired: this.state.expired,
                        disabled: this.state.disabled,
                        pending: this.state.pending
                    }
                    this.props.getFilterStatus(filter);
                }
            });
        }

    }

    render() {
        return (
            <div className='filter'>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='any' value='any' checked={this.state.any} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='any'>Any</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='active' value='active' checked={this.state.active} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='active'>Active</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='expired' value='expired' checked={this.state.expired} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='expired'>Expired</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='pending' value='pending' checked={this.state.pending} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='pending'>Pending</label>
                </div>
                <div className='custom-control custom-checkbox my-1 mr-sm-2 statusOptions'>
                    <input type='checkbox' className='custom-control-input statusOptions' id='disabled' value='disabled' checked={this.state.disabled} onChange={this.onChange} />
                    <label className='custom-control-label statusOptions' htmlFor='disabled'>Disabled</label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}

export default connect(mapStateToProps, { getFilterStatus })(StatusFilter);