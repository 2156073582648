import React, { Component } from 'react';
import config from '../../config';
import '../../pages/Teams/AssignTeams.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { connect } from 'react-redux';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getCurrentUser, fetchCompany } from '../../actions';
import { graphFetch } from '../../services/graphApiService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiGet, apiPost } from '../../services/apiService';

class ClientAccessRequest extends Component {
    constructor(props) {
        const counter = 0;
        super(props)
        this.state = {
            allAccounts: [],
            accountNames: [],
            engagements: [],
            engagementDetails: [],
            engagementKey: null,
            additionalEngagements: [],
            selectedEngagement: "Loading",
            hasAdditional: false,
            checked: false,
            accept: false,
            submit: false,
            backbtn: false,
            selectedTeam: null,
            selectedTeamUrl: '',
            iManageTeamUrl: '',
            teamSitePrivateChannelID: '',
            guestLimit: 24,
            counterLimit: 22,
            counter: counter,
            addAnother: [
                {
                    id: counter
                }
            ],
            guestFirstNames: [],
            guestLastNames: [],
            guestEmails: [],
            bJustification: '',
            selectedDate: null,
            guestAccounts: [],
            guestRequest: [],
            modal: false,
            limitModal: true,
            rowError: new Array(50).fill(false),
            errorMessage: null,
            adResponse: '',
            successModal: false,
            confirmationModal: false,
            mdErrorModal: false,
            errorModal: false,
            teams: [],
            allManagingDirectors: [],
            managingDirectors: [],
            selectedMD: null,
            selectedMDName: null,
            disableMD: false,
            showMaxDate: true,
            disableEngagementName: true,
            isBlacklisted: false,
            selectedEmail: '',
            // existing data
            existingRequestKey: null,
            existingMD: null,
            existingMDName: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.incrementAnother = this.incrementAnother.bind(this);
    }
    async componentDidMount() {
        await this.fetchData();
        if (this.props.location.state) {
            this.setState({ existingRequestKey: this.props.location.state.key }, () => this.getExistingRequest());

        }
    }

    getExistingRequest = async () => {
        let url = config.azRedirectUri + '/api/ClientTeams/detail/' + this.state.existingRequestKey;
        await apiGet(url)
            .then(response => {
                let data = response.data;
                let selectedEngagement = { name: data.clientName, key: data.engagementKey }
                this.handleEngagementSelect(selectedEngagement);
                var newGuestEmails = this.state.guestEmails;
                var newGuestFirstNames = this.state.guestFirstNames;
                var newGuestLastNames = this.state.guestLastNames;
                for (var i = 0; i < data.guestAccessClientUsers.length; i++) {
                    if (i > 0) { this.incrementAnother(); }
                    newGuestEmails.push(data.guestAccessClientUsers[i].email);
                    newGuestFirstNames.push(data.guestAccessClientUsers[i].firstName);
                    newGuestLastNames.push(data.guestAccessClientUsers[i].lastName);
                }
                this.setState({ guestEmails: newGuestEmails, guestFirstNames: newGuestFirstNames, guestLastNames: newGuestLastNames, bJustification: data.justification, existingMD: data.mdEmail, existingMDName: data.mdName })
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.getExistingRequest();
                }
                console.log(error);
            })
    }

    fetchData = async () => {
        await this.getEngagements();
    }

    checkBlackListEmails = async (e) => {
        const url = config.azRedirectUri + '/api/EMailBlacklist/details/' + e;
        await apiGet(url)
            .then((response) => {
                let blackList = false;
                let responseValue = response.data[0]
                if (responseValue != null) {
                    if (responseValue.toLowerCase() === e) {
                        blackList = true;
                    }
                }

                this.setState({ isBlacklisted: blackList })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getEngagements = async () => {
        const url = config.azRedirectUri + '/api/Engagements';
        await apiGet(url)
            .then((response) => this.setState({ engagements: response.data, disableEngagementName: false, selectedEngagement: null }))
            .catch((error) => {
                console.log(error)
            })
    }

    updateEngagement = async (engagementKey) => {
        this.setState({ allManagingDirectors: null, disableMD: true, errorMessage: null })
        const url = config.azRedirectUri + '/api/Engagements/Details/' + engagementKey;
        await apiGet(url)
            .then((response) => {
                let hasAdditional = false;
                if (response.data[0].relatedEngagements.length > 0) {
                    hasAdditional = true;
                }
                this.setState({ engagementDetails: response.data[0], allManagingDirectors: response.data[0].managingDirectors, engagementKey, hasAdditional, teamSitePrivateChannelID: response.data[0].teamSitePrivateChannelID }, async () => {
                    if (response.data[0].teamsSiteUrl != null) {
                        let url = response.data[0].teamsSiteUrl.substring(response.data[0].teamsSiteUrl.indexOf('=') + 1);
                        let groupId = url.substring(0, url.indexOf('&'));
                        if (groupId.length != 36) {
                            groupId = response.data[0].teamsSiteUrl.split('groupId=')[1].split('&')[0];
                        }
                        await this.updateTeams(groupId);
                    } else {
                        this.setState({ errorMessage: "This client engagement does not have an associated Teams site." }, () => this.toggleMDErrorModal());
                    }
                })
            });
    }

    updateTeams = async (teamsId) => {
        await graphFetch('https://graph.microsoft.com/v1.0/teams/' + teamsId, {})
            .then((response) => {
                response.json()
                    .then((responseJson) => {
                        this.setState({
                            selectedTeam: responseJson.displayName ? responseJson.displayName : "Unknown TEAMS site",
                            selectedTeamUrl: responseJson.webUrl,
                            iManageTeamUrl: this.state.engagementDetails.teamsSiteUrl
                        }, async () => {
                            await graphFetch('https://graph.microsoft.com/v1.0/groups/' + teamsId + '/members', {})
                                .then((response) => {
                                    response.json()
                                        .then(async (members) => {
                                            await graphFetch('https://graph.microsoft.com/v1.0/groups/' + teamsId + '/owners', {})
                                                .then((response) => {
                                                    response.json()
                                                        .then((owners) => {
                                                            let fullMD = [...members.value, ...owners.value];
                                                            this.mapMds(fullMD);
                                                        }).catch((error) => {
                                                            console.log(error)
                                                        });
                                                });
                                        });
                                }).catch((error) => {
                                    console.log(error)
                                });
                        });

                    });
            })
            .catch((error) => {
                console.error(error);

            });
    }

    mapMds = async (teamsMD) => {
        let managingDirectors = this.state.allManagingDirectors;
        let mappedMD = managingDirectors.filter(o1 => teamsMD.some(o2 => o1.userPrincipalName === o2.userPrincipalName));
        let md = mappedMD;
        let existingMD = this.state.existingMD;
        let existingMDName = this.state.existingMDName;
        mappedMD.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.setState({
            managingDirectors: mappedMD,
            selectedMD: existingMD !== null ? existingMD : null,
            selectedMDName: existingMDName !== null ? existingMDName : null,
            disableMD: false
        })
    }

    mapSensitivity = (sensitivity) => {
        let result = '';
        if (sensitivity) {
            result = sensitivity === 'True' ? 'Yes' : 'No';
        }
        return result;
    }

    handleChange(e) {
        if (e.target.value === 'check') {
            this.setState(
                prevState => {
                    return {
                        checked: !prevState.checked,
                        submit: !this.state.submit
                    }
                }
            )
        }
    }

    confirmNoClick = () => {
        this.setState({ submit: true, backbtn: false });
        this.toggleConfirmationModal();
    }

    incrementAnother = () => {
        let nextId = this.state.counter + 1;
        let newAdd = [...this.state.addAnother, { id: nextId }]

        this.setState({ addAnother: newAdd, counter: nextId });
    }
    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    toggleSuccessModal = () => {
        this.setState({
            successModal: !this.state.successModal
        });
    }
    toggleLimitModal = () => {
        this.setState({
            limitModal: !this.state.limitModal
        });
    }
    toggleConfirmationModal = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal
        });
    }
    toggleMDErrorModal = () => {
        let submit = this.state.submit;
        if (this.state.checked && !submit) submit = !submit
        this.setState({ mdErrorModal: !this.state.mdErrorModal, submit });
    }
    toggleErrorModal = () => {
        this.setState({
            submit: !this.state.submit,
            errorModal: !this.state.errorModal
        });
    }

    emailTest = (e) => {
        let pattern = /^[\._A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(e.trim()).toLowerCase());
    }

    findDuplicates = () => {
        this.setState({ submit: false, backbtn: true });
        let results = [];
        for (let i = 0; i < this.state.guestEmails.length; i++) {
            for (let j = 0; j < this.state.guestEmails.length; j++) {
                if (j === i)
                    continue;
                else {
                    if (this.state.guestEmails[i] === this.state.guestEmails[j])
                        results.push(i)
                }
            }
        }
        if (results.length === 0) {
            this.addAccounts()
        }
        else {
            this.setState({ submit: true, backbtn: false });
            let rowErr = this.state.rowError;
            for (let i = 0; i < results.length; i++) {
                rowErr[results[i]] = true;
                this.setState({ rowError: rowErr, errorMessage: '* Duplicate accounts must either be changed or deleted', errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false) });
                setTimeout(() => {
                    this.setState({ errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false) });
                }, 50000);
            }
        }
    }

    addAccounts = () => {
        let accounts = []
        let rowErrors = 0
        let manDir = this.state.selectedMD
        let isEmail = this.emailTest(manDir);
        let hasExpiredDate = true;
        if (this.state.showMaxDate) {
            if (this.state.selectedDate == null) {
                hasExpiredDate = false;
            }
        }
        if (this.state.rowError.every(row => row === false)) {
            if (this.state.selectedTeam && this.state.selectedTeamUrl && isEmail && this.state.addAnother.length === this.state.guestEmails.length && hasExpiredDate) {
                this.setState({ rowError: new Array(this.state.guestLimit).fill(false) });
                for (let i = 0; i < this.state.guestEmails.length; i++) {
                    if (this.state.guestFirstNames[i] && this.state.guestEmails[i] && this.state.guestLastNames[i]) {
                        let account = {
                            name: this.state.guestFirstNames[i] + ' ' + this.state.guestLastNames[i],
                            firstName: this.state.guestFirstNames[i],
                            lastName: this.state.guestLastNames[i],
                            email: this.state.guestEmails[i]
                        }
                        accounts.push(account)
                    }
                    else {
                        rowErrors++;
                        let rowErr = this.state.rowError;
                        rowErr[i] = true;
                        this.setState({ rowError: rowErr, errorMessage: '* Highlighted rows must be completed or deleted.', submit: true });
                        setTimeout(() => {
                            this.setState({ errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false), submit: true });
                        }, 50000);

                    }
                }

                let guestRequest = {
                    name: 'Request',
                    requestType: 0,
                    teamsSiteName: this.state.selectedTeam,
                    teamsSiteUrl: this.state.selectedTeamUrl,
                    privateChannelID: this.state.teamSitePrivateChannelID,
                    //iManageTeamUrl: this.state.iManageTeamUrl,
                    justification: this.state.bJustification,
                    securityComplianceLevel: 0,
                    mdName: this.state.selectedMDName,
                    mdEmail: manDir,
                    guestAccessClientUsers: accounts,
                    expireDate: this.state.selectedDate,
                    clientName: this.state.engagementDetails.clientName,
                    engagementKey: this.state.engagementKey,
                    engagementName: this.state.selectedEngagement,
                    emd: this.state.engagementDetails.emd,
                    bu: this.state.engagementDetails.bu,
                    sensitivity: this.mapSensitivity(this.state.engagementDetails.sensitivity)
                }
                if (rowErrors === 0 && accounts.length > 0) {
                    this.setState({ rowError: new Array(this.state.guestLimit).fill(false), guestAccounts: accounts, guestRequest: guestRequest });
                    this.toggleConfirmationModal()
                }
            }
            //else if (this.state.addAnother > this.state.guestEmails.length - 1) {
            else if (this.state.guestEmails.length > 0 && (this.state.addAnother > this.state.guestEmails.length - 1)) {
                for (let i = this.state.addAnother; i > this.state.guestEmails.length - 1; i--) {
                    rowErrors++;
                    let rowErr = this.state.rowError;
                    rowErr[i] = true;
                    this.setState({ rowError: rowErr, errorMessage: '* Highlighted rows must be completed or deleted.', submit: true, errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false) });
                    setTimeout(() => {
                        this.setState({ errorMessage: null, rowError: new Array(this.state.guestLimit).fill(false) });
                    }, 50000);
                }
            }
            else {
                if (this.state.selectedTeam === null || this.state.selectedTeamUrl === '' || this.state.selectedMD === null && !hasExpiredDate) {
                    this.setState({ submit: true, backbtn: true, errorMessage: '* Marked fields are required.' });
                } else {
                    this.setState({ errorMessage: "There was an error submitting your request.  Please verify all required fields are populated." }, () => this.toggleMDErrorModal());
                }
            }
        } else if (!isEmail || this.state.rowError.some(row => row === true) || this.state.selectedTeam === null || this.state.selectedTeamUrl === '' || this.state.selectedMD === null || this.state.selectedDate == null && !hasExpiredDate) {
            this.setState({ submit: true, backbtn: true, errorMessage: '* Marked fields are required.' });
        } else {
            this.setState({ errorMessage: "There was an error submitting your request.  Please verify all required fields are populated." }, () => this.toggleMDErrorModal());
        }
        setTimeout(() => {
            this.setState({ errorMessage: null });
        }, 50000);
    }

    renderCancelModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Cancel</MDBModalHeader>
                    <MDBModalBody>
                        {this.cancelModal()}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' onClick={this.onCancel}>Yes</button>
                        <button className='btn btn-primary updateButton' onClick={this.toggleModal}>No</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    renderSuccessModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.successModal} toggle={this.toggleSuccessModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Submission Successful</MDBModalHeader>
                    <MDBModalBody>
                        Your request to allow users to Teams was successful
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Link to='/clientteamsrequests'><button className='btn btn-primary updateButton'>Done</button></Link>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }

    renderConfirmationModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.confirmationModal} toggle={this.toggleConfirmationModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Confirm</MDBModalHeader>
                    <MDBModalBody>
                        Are You sure you want to add:
                        {this.confirmationModal()}
                        <br /> to {this.state.selectedTeam}?
                        <div>
                            <br />Please confirm that you wish to add these users to the selected Teams site.
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' type='button' onClick={() => this.confirmPost()}>Confirm</button>
                        <button className='btn btn-danger updateButton' type='button' onClick={this.confirmNoClick}>Cancel</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    renderMDErrorModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.mdErrorModal} toggle={this.toggleMDErrorModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Form Error</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.errorMessage}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' onClick={this.toggleMDErrorModal}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    renderErrorModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.errorModal} toggle={this.toggleErrorModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Submission Error</MDBModalHeader>
                    <MDBModalBody>
                        There was an error submitting your request. Please try again or contact support if the error continues.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className='btn btn-primary updateButton' onClick={this.toggleErrorModal}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
    confirmationModal() {
        return (this.state.guestAccounts.map((x, index) =>
            <div key={index}>
                {x.firstName + ' ' + x.lastName + ' '}
            </div>
        )
        )
    }
    confirmPost = async (event) => {
        this.toggleConfirmationModal()
        let guestRequest = this.state.guestRequest
        const url = config.azRedirectUri + '/api/ClientTeams';
        await apiPost(url, guestRequest)
            .then((response) => {
                this.setState({ adResponse: response.data, rowError: new Array(this.state.guestEmails.length).fill(false), backbtn: true })
                this.toggleSuccessModal()
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    onCancel() {
        window.location.reload(true);

    }

    cancelModal() {
        return (

            <div>
                Are you sure you want to Cancel and lose the changes made?
            </div>
        );
    }
    handleGuestEmail = async (event, index) => {
        event.preventDefault();
        let email = event.target.value.trim();
        this.setState({ selectedEmail: email });

        const lastError = this.state.errorMessage;
        let rowErr = this.state.rowError;
        let emailList = this.state.guestEmails;
        emailList[index] = email;
        let isEmail = this.emailTest(email);

        let firstNameList = this.state.guestFirstNames;
        let lastNameList = this.state.guestLastNames;

        if (isEmail) {
            setTimeout(async () => {
                var domain = this.state.selectedEmail.length >= email.length ? this.state.selectedEmail.split('@')[1].toLowerCase() : email.split('@')[1].toLowerCase();
                await this.checkBlackListEmails(domain);
                if (!this.state.isBlacklisted) {
                    const url = config.azRedirectUri + '/api/ClientTeamUsers/' + email;
                    await apiGet(url)
                        .then(async (response) => {
                            if (response.data.length > 0) {
                                let userData = response.data[0];
                                firstNameList[index] = userData.firstName;
                                lastNameList[index] = userData.lastName;
                            } else {
                                firstNameList[index] = null;
                                lastNameList[index] = null;
                            }

                            rowErr = this.state.rowError;
                            rowErr[index] = !isEmail;

                            var errorMessage = null;
                            var isThereValue = rowErr.includes(true);
                            if (isThereValue) {
                                errorMessage = this.state.isBlacklisted ? '* Cannot use blacklisted email' : lastError;
                                rowErr[index] = true;
                            }

                            this.setState({ guestEmails: emailList, rowError: rowErr, guestFirstName: firstNameList, guestLastNames: lastNameList, errorMessage: errorMessage });
                        })
                }
                else {
                    rowErr[index] = true;
                    this.setState({ rowError: rowErr, errorMessage: '* Cannot use blacklisted email' });
                }
            }, 300);
        } else {
            rowErr[index] = !isEmail;
            this.setState({ rowError: rowErr, errorMessage: "* Not valid email" });
        }
    }
    handleGuestFirstName = (event, index) => {
        let firstNameList = this.state.guestFirstNames;
        firstNameList[index] = event.target.value;
        this.setState({ guestFirstNames: firstNameList })
    }
    handleGuestLastName = (event, index) => {
        let lastNameList = this.state.guestLastNames;
        lastNameList[index] = event.target.value;
        this.setState({ guestLastNames: lastNameList })
    }

    handleEngagementSelect = selectedOption => {
        this.setState({ selectedEngagement: selectedOption.name, selectedMD: null, selectedMDName: null, existingMD: null, existingMDName: null }, () => this.updateEngagement(selectedOption.key));
    }

    handleTeamSite = selectedOption => {
        this.updateTeams(selectedOption.id);
    }
    handleAppManager = selectedOption => {
        let manEmail = selectedOption.loginAddress ? selectedOption.loginAddress : selectedOption.userPrincipalName
        this.setState({ selectedMD: manEmail, selectedMDName: selectedOption.name })

    }
    handleBJustification = event => {
        this.setState({ bJustification: event.target.value })

    }
    handleExpireDate = event => {
        this.setState({ selectedDate: event })
    }

    handleDelete = (i, index) => {
        let requestEmails = this.state.guestEmails
        let requestFirstNames = this.state.guestFirstNames
        let requestLastNames = this.state.guestLastNames
        if (this.state.addAnother.length > 1) {

            let newAdd = this.state.addAnother.filter(x => {
                return x.id !== i
            })
            requestEmails.splice(index, 1)
            requestFirstNames.splice(index, 1)
            requestLastNames.splice(index, 1)
            this.setState({
                guestEmails: requestEmails,
                guestFirstNames: requestFirstNames,
                guestLastNames: requestLastNames,
                addAnother: newAdd
            })
        }
        else {
            requestEmails[index] = ''
            requestFirstNames[index] = ''
            requestLastNames[index] = ''
            this.setState({
                guestEmails: requestEmails,
                guestFirstNames: requestFirstNames,
                guestLastNames: requestLastNames
            })
        }
    }

    pushGuestAccounts = (g) => {
        let newList = this.state.accountNames;
        newList.push(g);
        newList.sort((a, b) => a.display.localeCompare(b.display));
        this.setState({ accountNames: newList });
    }

    maxLimitModal() {
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.limitModal} toggle={this.toggleLimitModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Limit Exceeded</MDBModalHeader>
                    <MDBModalBody>
                        You've reached a maximum of {this.state.guestLimit} users to be added.
                    </MDBModalBody>
                    <MDBModalFooter>

                        <button className='btn btn-primary updateButton' type='button' onClick={this.toggleLimitModal}>Okay</button>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }

    renderAdditionalEngagementMessage = () => {
        return (
            <div className='row'>
                <div className='col-md-12'>
                    Other client engagements that are using the same Teams site as the engagement selected above.
                </div>
            </div>
        )
    }

    renderAdditionalEngagements = () => {
        return (
            this.state.engagementDetails.relatedEngagements.map((x, index) =>
                <div className='row' key={index}>
                    <div className='col-md-3'>
                        <div className='rightText'><label className='teamsLabel'>Engagement Name</label></div>
                        <input disabled defaultValue={x.engagementName} type='text' className='form-control' />
                    </div>
                    <div className='col-md-2'>
                        <div className='rightText'><label className='teamsLabel'>Client Name</label></div>
                        <input disabled defaultValue={x.clientName} type='text' className='form-control' />
                    </div>
                    <div className='col-md-2'>
                        <div className='rightText nowrap'><label className='teamsLabel'>Engagement Managing Director</label></div>
                        <input disabled defaultValue={x.emd} type='text' className='form-control' />
                    </div>
                    <div className='col-md-2'>
                        <div className='rightText'><label className='teamsLabel'>BU/Country</label></div>
                        <input disabled defaultValue={x.bu} type='text' className='form-control' />
                    </div>
                    <div className='col-md-2'>
                        <div className='rightText'><label className='teamsLabel'>Sensitivity</label></div>
                        <input disabled defaultValue={this.mapSensitivity(x.sensitivity)} type='text' className='form-control' />
                    </div>
                </div>
            )
        )
    }

    renderAddAnother = () => {
        return (
            this.state.addAnother.map((x, index) =>
                <div className='form-group col-md-12' key={x.id}>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='rightText'><label className='teamsLabel'>Guest Email</label></div>
                            <input type='text' id={`${index}_guest_email`} defaultValue={this.state.guestEmails[index]} onChange={(event) => this.handleGuestEmail(event, index)} className={(this.state.errorMessage === '* Marked fields are required.' || this.state.errorMessage === '* Highlighted rows must be completed or deleted.') && (this.state.guestEmails[index] === null || this.state.rowError[index]) ? 'warningBorder form-control' : 'form-control'} />
                            {this.state.errorMessage === '* Marked fields are required.' && (this.state.guestEmails[index] === null || this.state.rowError[index]) ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                            {this.state.rowError[index] && (this.state.errorMessage === '* Highlighted rows must be completed or deleted.' || this.state.errorMessage === '* Duplicate accounts must either be changed or deleted') ? <div className='warningText' > {this.state.errorMessage}</div> : null}
                            {this.state.rowError[index] && (this.state.errorMessage === '* Not valid email') ? <div className='warningText' > {this.state.errorMessage}</div> : null}
                            {this.state.rowError[index] && (this.state.errorMessage === '* Cannot use blacklisted email') ? <div className='warningText' > {this.state.errorMessage}</div> : null}
                            {this.state.rowError[index] && (this.state.errorMessage === null) ? <div className='warningText' > * {this.state.errorMessage}</div> : null}
                        </div>
                        <div className='col-md-2'>
                            <div className='rightText'><label className='teamsLabel'>Guest First Name</label></div>
                            <input type='text' value={this.state.guestFirstNames[index] || ''} onChange={(event) => this.handleGuestFirstName(event, index)} className={(this.state.errorMessage === '* Marked fields are required.' || this.state.errorMessage === '* Highlighted rows must be completed or deleted.') && this.state.guestFirstNames[index] === null ? 'warningBorder form-control' : 'form-control'} />
                            {this.state.errorMessage === '* Marked fields are required.' && this.state.guestFirstNames[index] === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                            {this.state.rowError[index] && (this.state.errorMessage === '* Highlighted rows must be completed or deleted.' || this.state.errorMessage === '* Duplicate accounts must either be changed or deleted') ? <div className='warningText' > {this.state.errorMessage}</div> : null}
                        </div>
                        <div className='col-md-2'>
                            <div className='rightText'><label className='teamsLabel'>Guest Last Name</label></div>
                            <input type='text' value={this.state.guestLastNames[index] || ''} onChange={(event) => this.handleGuestLastName(event, index)} className={(this.state.errorMessage === '* Marked fields are required.' || this.state.errorMessage === '* Highlighted rows must be completed or deleted.') && this.state.guestLastNames[index] === null ? 'warningBorder form-control' : 'form-control'} />
                            {this.state.errorMessage === '* Marked fields are required.' && this.state.guestLastNames[index] === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                            {this.state.rowError[index] && (this.state.errorMessage === '* Highlighted rows must be completed or deleted.' || this.state.errorMessage === '* Duplicate accounts must either be changed or deleted') ? <div className='warningText' > {this.state.errorMessage}</div> : null}
                        </div>
                        <div className='col-md-1'>
                            <div className='delete' >
                                <FontAwesomeIcon icon={faTrashAlt} size='lg' onClick={(event) => this.handleDelete(x.id, index)} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
    render() {
        const additionalEngagements = this.state.hasAdditional ? this.renderAdditionalEngagements() : null;
        const another = this.renderAddAnother();
        const limit = this.state.addAnother > this.state.counterLimit ? this.maxLimitModal() : null;
        const email = this.props.userReducer.currentUser.emailAddress;
        const emailSuffix = email ? email.substring(email.lastIndexOf('@') + 1) : null;
        const checkDrop = emailSuffix === 'protiviti.com' || emailSuffix === 'roberthalf.com' ? true : false;
        let additionalEngagementMessage = this.state.hasAdditional ? this.renderAdditionalEngagementMessage() : null;
        let days = this.state.showMaxDate ? config.teamsExpireDaysLength : 1000;
        const today = new Date();
        let inDays = new Date();
        inDays.setDate(inDays.getDate() + days);

        //uncomment once complete
        //if (!checkDrop) {
        //    return <Redirect to='/' />
        //}

        return (
            <div className="teamsContainer">
                <div className="introductoryText">
                    <br />
                    Please complete this form to request client access to your Teams site. You can request access for multiple clients at once for the same site. MD approval is required for all requests. The approving MD must be a member of the Teams site. Make sure your contract with the client allows their data and documents to be stored in the Robert Half/Protiviti environment. Make sure your Team members know that the client will have access to the posts and files and they should take special care to manage discussions and documents accordingly.
                    <br /><br />
                </div>
                {this.renderCancelModal()}
                {this.renderSuccessModal()}
                {this.renderConfirmationModal()}
                {this.renderMDErrorModal()}
                {this.renderErrorModal()}
                <form>
                    <div className="formContainer">
                        <div className='form-group'>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <p>1. Choose your Client Engagement (Shell) from iManage.  You must be a member of the Engagement as well as a member of the TEAMS site that is linked to the iManage Shell.</p>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-group col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className='rightText'>
                                                        <label className='teamsLabel'>Client Engagement Name</label>
                                                    </div>
                                                    <Select
                                                        value={this.state.selectedEngagement}
                                                        placeholder={this.state.selectedEngagement}
                                                        options={this.state.engagements}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.key}
                                                        disabled={this.state.disableEngagementName}
                                                        onChange={event => { this.handleEngagementSelect(event) }}
                                                        className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedEngagement === null ? 'warningBorder' : null} />
                                                    {this.state.errorMessage === '* Marked fields are required.' && this.state.selectedEngagement === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='rightText'><label className='teamsLabel'>Client Name</label></div>
                                                    <input disabled defaultValue={this.state.engagementDetails.clientName} type='text' className='form-control' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='rightText nowrap'><label className='teamsLabel'>Engagement Managing Director</label></div>
                                                    <input disabled defaultValue={this.state.engagementDetails.emd} type='text' className='form-control' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='rightText'><label className='teamsLabel'>BU/Country</label></div>
                                                    <input disabled defaultValue={this.state.engagementDetails.bu} type='text' className='form-control' />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='rightText'><label className='teamsLabel'>Sensitivity</label></div>
                                                    <input disabled defaultValue={this.mapSensitivity(this.state.engagementDetails.sensitivity)} type='text' className='form-control' />
                                                </div>
                                                <br />
                                                <div className='col-md-3'>
                                                    <div className='rightText'><label className='teamsLabel'>Name of Microsoft Teams Site</label></div>
                                                    <input disabled defaultValue={this.state.selectedTeam} type='text' className='form-control' />
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className='rightText'><label className='teamsLabel'>URL of Microsoft Teams Site</label></div>
                                                    <input disabled defaultValue={this.state.selectedTeamUrl} type='text' className='form-control' />
                                                </div>
                                            </div>
                                            <br />
                                            {additionalEngagementMessage}
                                            {additionalEngagements}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <p>2. Choose your MD Approver.  The MD must be a member of the TEAMS site, but does not need to be the EMD on the engagement.</p>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-group col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className='rightText'>
                                                        <label className='teamsLabel'>Approving Managing Director</label>
                                                    </div>
                                                    <Select
                                                        isDisabled={this.state.selectedEngagement === null || this.state.disableMD}
                                                        value={this.state.selectedMD}
                                                        placeholder={this.state.selectedMDName}
                                                        options={this.state.managingDirectors}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.loginAddress}
                                                        onChange={event => { this.handleAppManager(event) }}
                                                        className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedMD === null ? 'warningBorder' : null}
                                                    />
                                                    {this.state.errorMessage === '* Marked fields are required.' && this.state.selectedMD === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='rightText'><label className='teamsLabel'>MD Email</label></div>
                                                    <input
                                                        readOnly
                                                        defaultValue={this.state.selectedMD ? this.state.selectedMD : ''}
                                                        type='text'
                                                        className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <p>3. Fill your guest information. Provide a first and last name and their client company email address. Personal email address are not allowed.</p>
                                    </div>
                                    {another}
                                    {limit}
                                    <button type='button' className={this.state.addAnother > this.state.counterLimit ? 'addIconDisabled' : 'addIcon'} onClick={this.incrementAnother} disabled={this.state.addAnother > this.state.counterLimit}><FontAwesomeIcon icon={faPlus} size='sm' />  Add Another Guest</button>
                                    <br />
                                    <br />
                                    <div className='row'>
                                        <p>4. Provide an end date for guest access (6 month maximum) and a business justification.</p>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>End Date for Guest Access</label><br />
                                            <DatePicker
                                                className='form-control'
                                                selected={this.state.selectedDate}
                                                onChange={this.handleExpireDate}
                                                minDate={today}
                                                maxDate={inDays}
                                                dateFormat="dd MMM yyyy"
                                                className={this.state.errorMessage === '* Marked fields are required.' && this.state.selectedDate === null ? 'warningBorder form-control' : 'form-control'} />
                                            {this.state.errorMessage === '* Marked fields are required.' && this.state.selectedDate === null ? <div className='warningText'>{this.state.errorMessage}</div> : null}
                                            <div className="required-text">
                                                Maximum allowed duration for guest access is 6 months.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <div className='rightText'>
                                                <label className='teamsLabel'>Business Justification (optional)</label>
                                            </div>
                                            <textarea className='form-control' value={this.state.bJustification} rows="3" onChange={this.handleBJustification} ></textarea>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="containerOuter">
                                                <div className="termsContainer">
                                                    <div className="introductoryText">
                                                        Please acknowledge that you understand that by providing access to this Teams site to a Client User you are allowing the Client User to access all the information contained on this Teams site including: Protiviti, client, contractor and/or vendor information which may be of a business proprietary, confidential and/or competition sensitive nature. Client Users will have the capability to download files from this Teams site. Access should be given only on an as-needed basis to meet legitimate business and/or client needs. Your client will be able to see all the members of your Teams site so please ensure that everyone’s access is permitted by the client contract.
                                                    </div>
                                                    <div className="custom-control custom-checkbox my-1 mr-sm-2 checkStyle">
                                                        <input type="checkbox" className="custom-control-input" value='check' id="customControlInline" checked={this.state.checked} onChange={this.handleChange} />
                                                        <label className="custom-control-label teamsLabel" htmlFor="customControlInline" >I have read and understand the risks and responsibilities</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row  actionButtons'>
                                        <div className='col-md-12'>
                                            <Link to='/clientteamsrequests'><button type="button" className="btn btn-primary updateButton bottom-button" disabled={this.state.backbtn}>Back To List</button></Link>
                                            <button type="button" className="btn btn-secondary my-1 bottom-button" type="button" disabled={!this.state.submit} onClick={this.toggleModal}>Cancel</button>
                                            <button className="btn btn-primary my-1 bottom-button" type="button" disabled={!this.state.submit} onClick={this.findDuplicates}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-md-12'>
                                    <div className='row bottomError warningText'>
                                        {this.state.errorMessage}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer,
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, { getCurrentUser, fetchCompany })(ClientAccessRequest)