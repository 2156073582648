import React from "react";
import "./ADMembershipBody.css";

export default function ADMembershipBody({ accounts, ...props }) {
  return (
    <div>
      <p className="bold">AAD Accounts</p>
      <div className="member-list">
        <table>
          <tbody>
            {accounts && accounts.map((account, index) => (
              <tr key={index}>
                <td>{account.displayName}</td>
                <td>{account.mail}</td>
                <td>{account.userType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
