import React from 'react';
import './ManageDelegates.css';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ManageDelegatesGrid from '../../components/ManageDelegates/ManageDelegatesGrid';
import { connect } from 'react-redux';

class ManageDelegates extends React.Component {
    componentDidUpdate() {
        if (!this.props.userReducer.currentUser.isAdmin) {
            <Redirect to='/' />;
        }
    }

    render() {
        return (
            <div className='manageDelegates'>
                <div className='iconRow'>
                    <h5>Manage Delegates</h5>
                    <div className='iconContainer'>
                        <div className='addDelegate'>
                            <Link to='/adddelegate'>
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                                <div>Add Delegate</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <ManageDelegatesGrid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer
    }
}

export default connect(mapStateToProps, {})(ManageDelegates);