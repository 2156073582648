import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { updateCompany, getCompanyKey, updateAddCompany } from '../../actions';

class Company extends React.Component {
    componentDidMount() {
        let selectedOption = null;
        if (this.props.proCompanyKey && (this.props.sharedReducer.companyOptions.length !== 0)) {
            selectedOption = this.props.sharedReducer.companyOptions.find(x => {
                return x.key === this.props.proCompanyKey;
            })
        }
        else if ((this.props.accountCreateIndex !== undefined) || this.props.accountImport) {
            selectedOption = null;
        }
        else {
            selectedOption = { name: 'All Companies' };
        }
        this.props.updateCompany(selectedOption);
    }

    onOptionChange = selectedOption => {
        this.props.updateCompany(selectedOption);
        if (this.props.accountCreateIndex !== undefined) {
            this.props.updateAddCompany(selectedOption, this.props.accountCreateIndex);
        }
    };

    render() {
        return (
            <span style={{ width: '100%', zIndex: '999' }}>
                <Select
                    value={(this.props.accountCreateIndex !== undefined) ? this.props.sharedReducer.selectedAddCompany[this.props.accountCreateIndex] : this.props.sharedReducer.selectedCompany}
                    onChange={this.onOptionChange}
                    options={(this.props.proCompanyKey || (this.props.accountCreateIndex !== undefined) || this.props.accountImport) ? this.props.sharedReducer.companyOptions.filter(x => x.isDeleted === false) : [{ name: 'All Companies' }].concat(this.props.sharedReducer.companyOptions.filter(x => x.isDeleted === false))}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name} />
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return { sharedReducer: state.sharedReducer }
}

export default connect(mapStateToProps, { updateCompany, getCompanyKey, updateAddCompany })(Company);