import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {  updateOffice, bulkAdded } from '../../actions';
import './AccountImport.css';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';  
import { apiPost, apiGet } from '../../services/apiService';

class ImportEdit extends React.Component {
    constructor(props) {
        super(props)
        this.initialEmailError = []
        this.state =
            {
                accounts: [],
                firstNames:[],
                lastNames: [],
                emailNames: [],
                emailDomainSelected: [],
                officeNames: [],
                modal: false,
                addResponse: [],
                disabled: false,
                errorMessage: null,
                emailError: false,
                rowError: [],
                isReload: false,
                emailWhitelist: [],
            }
        this.findSelectedDomain = this.findSelectedDomain.bind(this)
        this.findOffice = this.findOffice.bind(this)
    }

    componentDidMount() {
        this.fetchEmailWhitelist()
    }

    fetchEmailWhitelist = async () => {
        let url = '/api/EMailWhitelist';
        return await apiGet(url)
            .then((response) => {
                let whiteList = response.data;
                let emails = this.assembleEmails(response.data);
                this.setState({ emailWhitelist: emails }, () => this.assembleImportData());
            })
            .catch((error) => {
                console.error(error);
            })
    }

    assembleImportData = () => {
        this.setState({
            firstNames: this.props.sharedReducer.importData.data.map(x => {
                let firstName = this.props.sharedReducer.importData.headers ? x["First (Given Name)"] : x[this.getByIndex(x, 0)]
                return firstName
            }),
            lastNames: this.props.sharedReducer.importData.data.map(x => {
                let lastName = this.props.sharedReducer.importData.headers ? x["Last (Surname)"] : x[this.getByIndex(x, 1)]
                return lastName
            }),
            emailNames: this.props.sharedReducer.importData.data.map((x, index) => {
                let emailName = this.props.sharedReducer.importData.headers ? x["Email Name"] : x[this.getByIndex(x, 3)]
                this.initialEmailError[index] = !this.validateEmail(emailName);
                return emailName
            }),
            emailDomainSelected: this.props.sharedReducer.importData.data.map(x => {
                let emailDomain = this.props.sharedReducer.importData.headers ? this.findSelectedDomain(x["Email Domain"]) : this.findSelectedDomain(x[this.getByIndex(x, 5)])
                return emailDomain
            }),
            officeNames: this.props.sharedReducer.importData.data.map(x => {
                let office = this.props.sharedReducer.importData.headers ? this.findOffice(x["Office"]) : this.findOffice(x[this.getByIndex(x, 2)])
                return (office)
            }),    
        }, () => {
            if (this.state.officeNames.length === 0 || this.state.firstNames.length === 0 || this.state.lastNames.length === 0 || this.state.emailNames.length === 0 || this.state.emailDomainSelected.length === 0) {
                this.props.history.push({
                    pathname: '/accountimport',
                    state: { redirectError: true }
                });
            }
            else {
                if (!this.initialEmailError.every(x => x === false)) {
                    this.setState({ rowError: this.initialEmailError });
                    this.setState({ errorMessage: 'Please Enter a Valid Email Address.' });
                }
            }        
        })
    }

    assembleEmails = (email) => {
        let allEmails = email.map((x) => {
            return ({
                'key': x.key,
                'name': x.name,
                'createdByName': x.createdByName,
                'isDeleted': x.isDeleted,
            })
        });
        return allEmails;
    }

    findSelectedDomain(selectedOption) {
        return (
            this.state.emailWhitelist.find(x => {
                return x.name === selectedOption
            })
        )
    }

    findOffice(selectedOffice)
    {
        if (selectedOffice.includes("-")) {
            const temp = selectedOffice.split('-')
            const off = this.props.sharedReducer.officeOptions.find(x => {
                return x.city === temp[1].trim()

            })
            return off
        }
        else {
            const off = this.props.sharedReducer.officeOptions.find(x => {
                return x.city === selectedOffice
            })
            return off
        }
    }

    getByIndex(obj, i) {
        return Object.keys(obj)[i];
    }
    onGivenNameChange = (event, index) => {
        let newFirstName = this.state.firstNames;
        newFirstName[index] = event.target.value;
        this.setState({ firstNames: newFirstName });
    }

    onSurnameChange = (event, index) => {
        let newLastName = this.state.lastNames;
        newLastName[index] = event.target.value;
        this.setState({ lastNames: newLastName });

    }

    onEmailNameChange = (event, index) => {
        let newEmailName = this.state.emailNames;
        newEmailName[index] = event.target.value;
        let rowErr = this.state.rowError;
        rowErr[index] = !this.validateEmail(event.target.value);
        this.setState({ emailNames: newEmailName, rowError: rowErr });
        if (rowErr.every(x => x === false)) {
            this.setState({ errorMessage: null });
        }
        else {
            this.setState({ errorMessage: 'Please Enter a Valid Email Address.' });
        }
    }
    onOfficeNameChange = (selectedOption, index) => {
        let newOfficeName = this.state.officeNames;
        newOfficeName[index] = selectedOption;
        this.setState({ officeNames: newOfficeName })
    }
    onEmailDomainChange = (selectedOption, index) => {
        let newEmailDomain = this.state.emailDomainSelected;
        newEmailDomain[index] = selectedOption;
        this.setState({ emailDomainSelected: newEmailDomain })
    }

    validateEmail(email) {
        if (email === '') {
            return true;
        }
        let pattern = /^[\._A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*$/;
        return pattern.test(String(email).toLowerCase());
    }

    response = () => {
        let added = []
      
        for (let i = 0; i < this.state.accounts.length; i++) {
            if (this.state.addResponse[i] === 'Success')
                added.push(this.state.accounts[i])
        }
        this.props.bulkAdded(added)
    }

    addAccounts = async (event) =>
    {
        event.preventDefault();
        if (this.state.rowError.every(x => x === false)) {
            let accounts = [];
            let firstNameLength = this.state.firstNames.filter(function (x) { return x.trim() !== ''; }).length;
            let lastNameLength = this.state.lastNames.filter(function (x) { return x.trim() !== ''; }).length;
            let emailLength = this.state.emailNames.filter(function (x) { return x.trim() !== ''; }).length;
            let officeLength = this.state.officeNames.filter(x => (x !== undefined) && (x !== null) && (x !== '')).length;
            let emailDomainLength = this.state.emailDomainSelected.filter(x => (x !== undefined) && (x !== null) && (x !== '')).length;
            if (firstNameLength === lastNameLength && firstNameLength === emailLength && firstNameLength === officeLength && firstNameLength === emailDomainLength && firstNameLength !== 0) {
                this.setState({ disabled: true });
                for (let i = 0; i < this.state.officeNames.length; i++) {
                    if (this.state.firstNames[i] && this.state.lastNames[i] && this.state.emailNames[i] && this.state.emailDomainSelected[i] && this.state.officeNames[i]) {
                        let account = {
                            firstName: this.state.firstNames[i],
                            lastName: this.state.lastNames[i],
                            emailAddress: this.state.emailNames[i] + '@' + this.state.emailDomainSelected[i].name,
                            officeKey: this.state.officeNames[i].key,
                            city: this.state.officeNames[i].city
                        };
                        accounts.push(account);
                    }
                }
                this.setState({ accounts: accounts });

                const url = '/api/Accounts';
                await apiPost(url, accounts)
                    .then((response) => {
                        this.setState({ isReload: false });
                        this.setState({ addResponse: response.data, rowError: new Array(this.state.firstNames.length).fill(false) });
                        this.toggleModal();
                        let filledIndex = [];
                        this.state.firstNames.forEach((x, index) => {
                            if (x) {
                                filledIndex.push(index);
                            }
                        });
                        response.data.forEach((x, index) => {
                            if (x !== 'Success') {
                                let errors = this.state.rowError;
                                errors[filledIndex[index]] = true;
                                this.setState({ emailError: true, rowError: errors });
                            }
                        })
                    })
                    .catch(function (error) {
                        if (error.response.status === 401 && !this.state.isReload) {
                            this.setState({ isReload: true });
                            this.addAccounts();
                        }
                        console.log(error);
                    })
            }
            else {
                if (firstNameLength !== 0) {
                    this.setState({ errorMessage: 'Highlighted rows must be completed or cleared.' });
                    setTimeout(() => {
                        this.setState({ errorMessage: null });
                    }, 5000);
                }
            }
        }
    }

    clearSuccess = (event) => {
        event.preventDefault();
        this.toggleModal();
        this.setState({ errorMessage: 'Email Already Exist. Please enter another email.' });
        this.state.rowError.forEach((x, index) => {
            if (x === false) {
                this.clearRow(index);
            }
        });
        setTimeout(() => {
            this.setState({ disabled: false, emailError: false, errorMessage: null, rowError: new Array(this.state.rowError.length).fill(false) });
        }, 5000);
    }

    renderModal() {       
        return (
            <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}>
                    <MDBModalHeader toggle={this.toggle}>Add User Status</MDBModalHeader>
                    <MDBModalBody>
                        {this.successModal()}
                      
                    </MDBModalBody>
                    <MDBModalFooter>
                        {!this.state.emailError ?
                            <Link to='/'><button className='btn btn-primary updateButton'>Done</button></Link> :
                            <button className='btn btn-primary updateButton' onClick={this.clearSuccess}>Done</button>}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
 
    successModal()
    { 
        return (
            this.state.accounts.map((x, index) =>               
                <div key={index}>
                    {x.lastName + ', ' + x.firstName + ' (' + x.city + '): '}
                    <span className={(this.state.addResponse[index] === 'Success') ? 'greenText' : 'redText'}>
                        {(this.state.addResponse[index] === 'Success') ? 'Success!' : ('Error: ' + this.state.addResponse[index])}
                    </span>
                </div>       
                )
            );
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    clearRow = (index) => {
        let firstNameTemp = this.state.firstNames;
        let lastNameTemp = this.state.lastNames;
        let emailNameTemp = this.state.emailNames;
        let officeNameTemp = this.state.officeNames;
        let emailDomainTemp = this.state.emailDomainSelected;
        firstNameTemp[index] = '';
        lastNameTemp[index] = '';
        emailNameTemp[index] = '';
        officeNameTemp[index] = '';
        emailDomainTemp[index] = '';
        this.setState({ firstNames: firstNameTemp, lastNames: lastNameTemp, emailNames: emailNameTemp, officeNames: officeNameTemp, emailDomainSelected: emailDomainTemp });
    }

    customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: 'red'
        })
    }

    noErrorcustomStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: 'hsl(0,0%,80%)'
        })
    }

    renderRemainingAccounts()
    {
        const acc = this.state.officeNames.length !== 0 ?
            [...Array(this.state.officeNames.length - 1)].map((x, index) => {
                return (
                    <div key={index + 1}>

                        <div className="form-row">
                            <div className='form-group col-md-4'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <input
                                            defaultValue={this.state.firstNames[index + 1]}
                                            type='text'
                                            className='form-control'
                                            onChange={(event) => this.onGivenNameChange(event, index + 1)}
                                            style={((!this.state.firstNames[index + 1] && (this.state.lastNames[index + 1] || this.state.officeNames[index + 1] || this.state.emailNames[index + 1] || this.state.emailDomainSelected[index + 1])) || this.state.rowError[index + 1]) ? { border: 'thin solid red' } : null} />
                                    </div>
                                    <div className='col-md-6'>

                                        <input
                                            type='text'
                                            defaultValue={this.state.lastNames[index + 1]}
                                            className='form-control'
                                            onChange={(event) => this.onSurnameChange(event, index + 1)}
                                            style={((!this.state.lastNames[index + 1] && (this.state.firstNames[index + 1] || this.state.officeNames[index + 1] || this.state.emailNames[index + 1] || this.state.emailDomainSelected[index + 1])) || this.state.rowError[index + 1]) ? { border: 'thin solid red' } : null} />
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-3'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <Select
                                            value={this.state.officeNames[index + 1]}
                                            options={this.props.sharedReducer.officeOptions}
                                            onChange={(event) => this.onOfficeNameChange(event, index + 1)}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            styles={((!this.state.officeNames[index + 1] && (this.state.firstNames[index + 1] || this.state.lastNames[index + 1] || this.state.emailNames[index + 1] || this.state.emailDomainSelected[index + 1])) || this.state.rowError[index + 1]) ? this.customStyles : this.noErrorcustomStyles } />
                                    </div>

                                </div>
                            </div>
                            <div className='form-group col-md-5'>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <input
                                            type='text'
                                            defaultValue={this.state.emailNames[index + 1]}
                                            className='form-control'
                                            onChange={(event) => this.onEmailNameChange(event, index + 1)}
                                            style={((!this.state.emailNames[index + 1] && (this.state.firstNames[index + 1] || this.state.lastNames[index + 1] || this.state.officeNames[index + 1] || this.state.emailDomainSelected[index + 1])) || this.state.rowError[index + 1]) ? { border: 'thin solid red' } : null} />
                                    </div>
                                    <div className='col-md-1'>
                                        <div className='centerText'>@</div>
                                    </div>
                                    <div className='col-md-5'>
                                        <Select
                                            value={this.state.emailDomainSelected[index + 1]}
                                            options={this.state.emailWhitelist.filter(x => {
                                                return x.isDeleted === false
                                            })}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            onChange={(event) => this.onEmailDomainChange(event, index + 1)}
                                            styles={((!this.state.emailDomainSelected[index + 1] && (this.state.firstNames[index + 1] || this.state.lastNames[index + 1] || this.state.emailNames[index + 1] || this.state.officeNames[index + 1])) || this.state.rowError[index + 1]) ? this.customStyles : this.noErrorcustomStyles} />
                                    </div>
                                    <div className='col-md-1'>
                                        <div className='clearAllIcon'><FontAwesomeIcon icon={faTrashAlt} size='lg' title='Clear Row' onClick={() => this.clearRow(index + 1)} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }) : null
        return acc
    }


    render() {
        const remainingAcc = this.renderRemainingAccounts()
     
        return (
           <form onSubmit={this.addAccounts}>
                <div className='container formContainer'>
                    <div className='form-group'>
                        <div className='importHeading'>
                            <h4>Upload Users</h4>
                        </div>
                        <hr />
                        <div className="form-row">
                            <div className='form-group col-md-4'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='centerText'>
                                            <label>Given Name</label>
                                        </div>
                                        <input
                                            defaultValue={this.state.firstNames[0]}
                                            type='text'
                                            className='form-control'
                                            onChange={(event) => this.onGivenNameChange(event, 0)}
                                            style={((!this.state.firstNames[0] && (this.state.lastNames[0] || this.state.officeNames[0] || this.state.emailNames[0] || this.state.emailDomainSelected[0])) || this.state.rowError[0]) ? { border: 'thin solid red' } : null} />
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='centerText'><label>Surname</label></div>
                                        <input
                                            type='text'
                                            defaultValue={this.state.lastNames[0]}
                                            className='form-control'
                                            onChange={(event) => this.onSurnameChange(event, 0)}
                                            style={((!this.state.lastNames[0] && (this.state.firstNames[0] || this.state.officeNames[0] || this.state.emailNames[0] || this.state.emailDomainSelected[0])) || this.state.rowError[0]) ? { border: 'thin solid red' } : null} />
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-3'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='centerText'><label>Office</label></div>
                                        <Select
                                            value={this.state.officeNames[0]}
                                            options={this.props.sharedReducer.officeOptions}
                                            onChange={(event) => this.onOfficeNameChange(event, 0)}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            styles={((!this.state.officeNames[0] && (this.state.firstNames[0] || this.state.lastNames[0] || this.state.emailNames[0] || this.state.emailDomainSelected[0])) || this.state.rowError[0]) ? this.customStyles : this.noErrorcustomStyles} />
                                    </div>     
                                </div>
                            </div>
                            <div className='form-group col-md-5'>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div className='centerText'><label>Email Name</label></div>
                                        <input
                                            type='text'
                                            defaultValue={this.state.emailNames[0]}
                                            className='form-control'
                                            onChange={(event) => this.onEmailNameChange(event, 0)}
                                            style={((!this.state.emailNames[0] && (this.state.firstNames[0] || this.state.lastNames[0] || this.state.officeNames[0] || this.state.emailDomainSelected[0])) || this.state.rowError[0]) ? { border: 'thin solid red' } : null} />
                                    </div>
                                    <div className='col-md-1'>
                                        <div className='centerText'><label>@</label></div>
                                        <div className='centerText'>@</div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='centerText'><label>Email Domain</label></div>
                                        <Select
                                            value={this.state.emailDomainSelected[0]}
                                            options={this.state.emailWhitelist.filter(x => {
                                                return x.isDeleted === false
                                            })}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            onChange={(event) => this.onEmailDomainChange(event, 0)}
                                            styles={((!this.state.emailDomainSelected[0] && (this.state.firstNames[0] || this.state.lastNames[0] || this.state.emailNames[0] || this.state.officeNames[0])) || this.state.rowError[0]) ? this.customStyles : this.noErrorcustomStyles} />
                                    </div>
                                    <div className='col-md-1'>
                                        <br />
                                        <div className='clearAllIcon' style={{ paddingTop: '5px' }}><FontAwesomeIcon icon={faTrashAlt} size='lg' title='Clear Row' onClick={() => this.clearRow(0)} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {remainingAcc}        
                    </div>
                    <hr />
                    <div className='accountImportButtons'>
                        <Link to='/'><button className='btn btn-secondary' disabled={this.state.disabled}>Cancel</button></Link>
                        <span><button style={{ marginLeft: 10 }} className='btn btn-primary' onClick={this.addAccounts} disabled={this.state.disabled}>Submit</button></span>
                    </div>
                </div>
                <div className='errorMessage'>{this.state.errorMessage}</div>
                {this.renderModal()}
           </form>       
            )
        }
    }
const mapStateToProps = (state) => {
    return {
        sharedReducer: state.sharedReducer
    }
}
export default connect(mapStateToProps, { updateOffice, bulkAdded })(ImportEdit)