import { getToken } from './authService';

export async function graphFetch(uri, headerParam) {
    const headers = new Headers();
    if (headerParam !== null) {
        Object.assign(headers, headerParam);
    }
    headers.append("Authorization", `Bearer ${await getToken('graph')}`);
    headers.append("ConsistencyLevel", "eventual");

    return fetch(uri, { method: "GET", headers });
}
export async function fetchGraphData(resource, param, childSelector) {
  let apiUrl = `https://graph.microsoft.com/v1.0/${resource}`;
  apiUrl = param ? `${apiUrl}/${param}` : apiUrl;
  apiUrl = childSelector ? `${apiUrl}/${childSelector}` : apiUrl;

  return graphFetch(apiUrl)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
    });
}

export async function fetchAADGroupsByEmail(loginEmail) {
  return fetchGraphData("users", `?$search="mail:${loginEmail}"`).then(
    (user) => {
      if (!user.value.length > 0) {
        Object.assign(user, { error: { code: "Request_ResourceNotFound" } });
        return user;
      }

      return fetchGraphData(
        "users",
        encodeURIComponent(user.value[0].userPrincipalName),
        "memberOf/microsoft.graph.group?$count=true&$select=displayName"
      );
    }
  );
}

export async function fetchAADMembersByTeamsID(teamsId) {
  return fetchGraphData("groups", teamsId, "members?$select=displayName,mail,userType");
}

export async function fetchAADOwnersByTeamsID(teamsId) {
  return fetchGraphData("groups", teamsId, "owners?$select=displayName,mail,userType");
}
